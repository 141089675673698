import { OcrInvoices } from "./components/ocr-invoice"
import { ConvertDocumentsPdfToXslx } from "./components/convert-documents-pdf-to-xlsx.component"
import { MergeInvoices } from "./components/merge-invoices"
import { CheckSerialValidation } from "./components/checks-serial-validation.component"
import XlsxToJsonComponent from "./components/xlsxtojsoninvoices"

export const DocumentsImportSection = () => {

  return (
    <>
      <h2 className="mt-2">העברת חשבוניות מס</h2>
      <div className="box">
        <h3>Optimus חשבוניות מס PDF List to xlsx Cleaned**</h3>
        <h4>Check File after it finishes last row**</h4>
        <ConvertDocumentsPdfToXslx />
      </div>
      <div className="box">
        <h3>ייצוא נתונים מצילומי מסך של רשימות חשבוניות מס </h3>
        <h5>להעלות לכאן את כל התמונות</h5>
        <OcrInvoices />
      </div>
      <div className="box">
        <h3>בדיקת תקינות קובץ ג'ייסון חשבוניות מס</h3>
        <h5>Upload Above processed file</h5>
        <CheckSerialValidation />
      </div>
      <div className="box">
        <h3>שילוב קובץ JSON וגם אקסל סופי</h3>
      
        <MergeInvoices />

      </div>

      <div className="box">
      <h3>xlsx to json invoices </h3>
        <XlsxToJsonComponent/>
      </div>
    </>
  )
}