import './manage-coupons.css';

import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { PendingCouponRow } from './components';
import { usePendingCoupons } from './hooks';

const ManageCoupons = () => {
    const {pendingCoupons, setPendingCoupons} = usePendingCoupons();
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    // Update page and rowsPerPage for pagination
    const handleChangePage = (event, newPage): void => {
        setPage(newPage);
    };

    const removeUpdatedCoupon = (couponId: string): void => {
        const updatedCoupons = pendingCoupons.filter(coupon => coupon._id !== couponId);
        setPendingCoupons(updatedCoupons);
    }

    const handleChangeRowsPerPage = (event): void => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const filteredCoupons = useMemo(() => {
        return pendingCoupons
            .filter(coupon => {
                const name = coupon?.firstName?.toLowerCase() || '';
                const firstName = coupon?.details?.firstName?.toLowerCase() || '';
                const phoneNumber = coupon?.phoneNumber?.toLowerCase() || '';
                const searchLower = search.toLowerCase();

                return (
                    name.includes(searchLower) || firstName.includes(searchLower) || phoneNumber.includes(searchLower)
                );
            })
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    }, [pendingCoupons, search, page, rowsPerPage]);

    return (
        <Paper>
            <h1>ניהול קופונים</h1>
            <div className="table-header">
                <TextField label="Search" value={search} onChange={e => setSearch(e.target.value)} />
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>עסק</TableCell>
                            <TableCell>סניף</TableCell>
                            <TableCell>טלפון</TableCell>
                            <TableCell>סוג</TableCell>
                            <TableCell>כותרת בערבית</TableCell>
                            <TableCell>מס' פגישות</TableCell>
                            <TableCell>תקף עד בחודשים</TableCell>
                            <TableCell>שם קטגוריה פנימי</TableCell>
                            <TableCell>מעבר לקופון</TableCell>
                            <TableCell>אישור ופרסום</TableCell>
                            <TableCell>התעלם</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredCoupons.map(coupon => (
                            <PendingCouponRow removeUpdatedCoupon={removeUpdatedCoupon} coupon={coupon} key={`pending-coupon-${coupon._id}`} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={pendingCoupons.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        dir="rtl"
                    />
                </Grid>
            </Grid>

            {/* {isManageCouponDialog && <ManageCouponDialog currentCoupon={currentCoupon} onClose={onCloseManageCouponDialog} />} */}
        </Paper>
    );
};

export default ManageCoupons;
