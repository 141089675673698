export enum BasketType {
    BUY_NOW_BOOK_NOW_CC = 'BUY_NOW_BOOK_NOW_CC',
    BUY_NOW_BOOK_LATER_CC = 'BUY_NOW_BOOK_LATER_CC',
    BUY_NOW_BOOK_NOW_VENUE = 'BUY_NOW_BOOK_NOW_VENUE',
    LEADS = 'LEADS',
    CONSULT = 'CONSULT',
    PROCESSING_FEE = 'PROCESSING_FEE',

    REDEEMED_THIS_MONTH_BUY_NOW_BOOK_NOW_CC = 'REDEEMED_THIS_MONTH_BUY_NOW_BOOK_NOW_CC',
    REDEEMED_THIS_MONTH_BUY_NOW_BOOK_LATER_CC = 'REDEEMED_THIS_MONTH_BUY_NOW_BOOK_LATER_CC',

    BOOK_NOW_ONLINE_SALES_THIS_MONTH_CC = 'BOOK_NOW_ONLINE_SALES_THIS_MONTH_CC',
    BOOK_LATER_ONLINE_SALES_THIS_MONTH_CC= 'BOOK_LATER_ONLINE_SALES_THIS_MONTH_CC',
    BUY_NOW_OFFLINE_SALES_THIS_MONTH_VENUE = 'BUY_NOW_OFFLINE_SALES_THIS_MONTH_VENUE',
    LEAD_THIS_MONTH = 'LEAD_THIS_MONTH',
    CONSULT_THIS_MONTH = 'CONSULT_THIS_MONTH',
    PROCESSING_FEE_THIS_MONTH = 'PROCESSING_FEE_THIS_MONTH',

    EXPIRED_VOUCHERS_BUY_NOW_BOOK_NOW_CC = 'EXPIRED_VOUCHERS_BUY_NOW_BOOK_NOW_CC',
    EXPIRED_VOUCHERS_BUY_NOW_BOOK_LATER_CC = 'EXPIRED_VOUCHERS_BUY_NOW_BOOK_LATER_CC',

    COMMISION_THIS_MONTH = 'COMMISION_THIS_MONTH',



    /// HISHBON MAHEER BASKETS
    PARTNER = 'partner',
    PARTNER_THIS_MONTH = 'PARTNER_THIS_MONTH',
}

// GENENRAL BANK BALANCE IS A QUERY NOT BASKET - ( BUY_NOW_BOOK_NOW_CC + (REDEEMED_THIS_MONTH_CC * 1.0008) + (REDEEMED_THIS_MONTH_CC_BUY_NOW_BOOK_LATER * 1.0008) + BUY_NOW_BOOK_LATER + EXPIRED_VOUCHERS_CC )

// HOW MUCH MONEY I MADE ?
// ( PAY_AT_VENUE + ONLINE_SALES_CC ) * ( commission )