import axios from "axios";
import { environment } from "src/common/config";

export class ManageBlockedUsersService {

    async getBlockedUsers(): Promise<any[]> {
        const { data } = await axios.get(`${environment.service_panel}/user/blocked`);
        return data?.data?.users;
    }

    async blockUser(phoneNumber: string) {
        const { data } = await axios.put(`${environment.service_panel}/user/block/${phoneNumber}`);
        return data.data.blockedUser;
    }

    async unblockUser(phoneNumber: string) {
        return await axios.put(`${environment.service_panel}/user/unblock/${phoneNumber}`);
    }
}