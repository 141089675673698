import { Dialog, DialogContent, Tab, Tabs } from '@mui/material';
import { useState } from 'react';


import { UserTab } from './tabs/user-tab/user.tab';


interface IManageUserDialogProps {
    onClose: () => void;
    currentUser: any;
}

export const ManageUserDialog = (props: IManageUserDialogProps) => {
    const { onClose, currentUser } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleDialogClose = () => {
        onClose();
    };

    return (
        <Dialog open onClose={handleDialogClose} fullWidth maxWidth="md">
            <DialogContent style={{ height: 600 }}>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab label="כללי" />
                    =
                </Tabs>
                {selectedTab === 0 && <UserTab currentUser={currentUser} />}
                
                
            </DialogContent>
        </Dialog>
    );
};
