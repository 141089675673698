import { useEffect } from 'react';

function useHandleAddWebsiteResponses(response, error, setNewWebsiteDetails): void {
    useEffect(() => {
        if (error) {
            alert('נתונים שגויים ! ! ! ! !');
            console.error('Error creating business: ', error);
        }
    }, [error]);

    useEffect(() => {
        if (response?.data?._id) {
            alert('הסניף נוסף בהצלחה!');
            setNewWebsiteDetails({
                websiteName: '',
                phoneNumber: '',
            })
        }
    }, [response, setNewWebsiteDetails]);
}

export default useHandleAddWebsiteResponses;
