import './photo-container-element.component.css';

export const PendingPhotoComponent = ({ businessName, branchName, phoneNumber, imageUrl }) => {
    const openInNewWindow = () => {
        const windowFeatures = 'width=800';
        const newWindow = window.open(imageUrl, '', windowFeatures);
        if (newWindow) newWindow.opener = null;
    };

    return (
        <>
            <div className="photo-element">
                <img src={imageUrl} alt="Business Venue" className="venue-photo" />
                <div className="overlay">
                    <button className="btn approve">✔</button>
                    <button className="btn delete">✘</button>
                    <button className="btn new-tab" onClick={openInNewWindow}>
                        🔍
                    </button>
                </div>
                <div className="info">
                    <h2>{businessName}</h2>
                    <p>{branchName}</p>
                    <a href={`tel:+972${phoneNumber}`}>
                        <p>{phoneNumber}</p>
                    </a>
                </div>
            </div>
        </>
    );
};
