import { TextField } from '@mui/material';
import { useState } from 'react';
import { UpdateUserFormComponent } from './components/update-user-form/update-user-form.component';

export const UserTab = props => {
    const { currentUser } = props;

    return (
        <>
            <div className="user-tab">
                <h4 className="small-titles">פרטי משתמש</h4>
                <div className="fst-row">
                    <div className="input-text">
                        <select className="input-text-dropdown">
                            <option value="" selected={currentUser?.blocked === ''}>
                                לא נבחר ערך ! ! !
                            </option>
                            <option
                                value="INDIVIDUAL_FREE_TAX"
                                selected={currentUser?.blocked === 'INDIVIDUAL_FREE_TAX'}>
                                חסום
                            </option>

                            <option value="INDIVIDUAL" selected={currentUser?.blocked === 'INDIVIDUAL'}>
                                משתמש לא חסום
                            </option>
                        </select>
                    </div>
                    {/* <div className="input-text">
                        <TextField
                            label="שם עסק חשבונאי"
                            value={currentUser?.name || ''}
                            // Add onChange handler to update the currentUser state
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            label="שם מסחרי"
                            value={currentUser?.zenatName || ''}
                            // Add onChange handler to update the currentUser state
                        />
                    </div> */}
                </div>

                {/* <div className="input-text">
                    <TextField
                        label="ע.מ. או ח.פ."
                        value={currentUser?.phoneNumber || ''}
                        // Add onChange handler to update the currentUser state
                    />
                </div>
                <div className="input-text">
                    <TextField
                        label="טלפון"
                        value={currentUser?.contact.phoneNumber || ''}
                        // Add onChange handler to update the currentUser state
                    />
                </div>
                <div className="input-text">
                    <TextField
                        label="Logo"
                        value={currentUser?.logo || ''}
                        style={{ width: '800px' }}
                        // Add onChange handler to update the currentUser state
                    />
                </div>
                <div className="input-text">
                    <TextField
                        label="סלוגן"
                        value={currentUser?.slogan || ''}
                        style={{ width: '800px' }}
                        // Add onChange handler to update the currentUser state
                    />
                </div>
                <h4 className="small-titles">פרטי בנק</h4>
                <div className="input-text">
                    <TextField label="קוד בנק" value={currentUser?.bank?.bank || ''} />
                </div>
                <div className="input-text">
                    <TextField label="סניף" value={currentUser?.bank?.branch || ''} />
                </div>
                <div className="input-text">
                    <TextField label="מס חשבון" value={currentUser?.bank?.accountNumber || ''} />
                </div>
                <UpdateUserFormComponent /> */}
            </div>
        </>
    );
};
