import React, { useState } from 'react';
// import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import { s2ab } from '../../../common/utils/common.utils';
GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`;

export const ConvertDocumentsPdfToXslx = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(null);
  };

  const extractText = async (pdf) => {
    const textContent = [];
    let titleFound = false;
    let summaryFound = false;
  
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const pageItems = content.items;
  
      for (let index = 0; index < pageItems.length; index++) {
        const item = pageItems[index].str;
  
        if (!titleFound && item.startsWith("ע\"מ/ח\"פ")) {
          textContent.push([item]);
          titleFound = true;
        } else if (titleFound && !summaryFound && item.startsWith("סיכום הכנסות")) {
          textContent.push([item]);
          summaryFound = true;
        } else if (summaryFound && textContent.length === 2) {
          const tableHeaders = [
            "חשבונית מס",
            "תאריך",
            "סכום לפני מע''מ",
            "מע\"מ",
            "כולל מע\"מ",
            "שם לקוח",
          ];
          textContent.push(tableHeaders);
          index += 7; // Skip the next 6 items (the rest of the table headers)
        } else if (summaryFound && textContent.length > 2) {
          if (index >= pageItems.length - 7) {
            // Stop processing when there are not enough items left for a complete row
            break;
          }
  
          // Check if the current item is a new row by checking if the item starts with a number
          if (/^\d/.test(item)) {
            const rowData = [
              item,
              pageItems[index + 1].str,
              pageItems[index + 2].str,
              pageItems[index + 3].str,
              pageItems[index + 5].str, // Skip column E
              pageItems[index + 7].str, // Skip column G
            ];
            textContent.push(rowData);
            index += 6; // Skip the next 6 items (the rest of the rowData)
          }
        }
      }
    }
    return textContent;
  };

  const convertToXlsx = async () => {
    setLoading(true);
    const reader = new FileReader();
    reader.onload = async (event: any) => {
      try {
        const pdfData = new Uint8Array(event.target.result);
        const pdf = await getDocument(pdfData).promise;
        const data = await extractText(pdf);
        const dataWithoutFirstTwoRows = data.slice(2); // Remove the first two rows
        const dataWithoutLastFourRows = dataWithoutFirstTwoRows.slice(0, -4); // Remove the last four rows
  
        const ws = XLSX.utils.aoa_to_sheet(dataWithoutLastFourRows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        saveAs(blob, 'output.xlsx');
      } catch (e) {
        console.error(e);
        setError('Error converting PDF to XLSX.');
      } finally {
        setLoading(false);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <input type="file" accept="application/pdf" onChange={handleFileChange} />
      {file && (
        <div>
          {/* <Document file={file}>
            sdsdsd
            Remove the Page components to prevent rendering the PDF pages preview
          </Document> */}
          {loading ? (
            <p>Converting...</p>
          ) : (
            <button onClick={convertToXlsx}>Convert PDF to XLSX</button>
          )}
          {error && <p>{error}</p>}
        </div>
      )}
    </div>
  );
};