import { CreditCardSolek, EmvType } from 'src/common/enums';

interface IUpdateTerminalKeysFormInput {
    key: string;
    label: string;
    disabled: boolean;
    note?: string;

    type?: 'select' | 'text';
    options?: string[];

    shouldCheckEmvType?: string;
    emvTypes?: EmvType[];

    shouldCheckCreditCardSolek?: string;
    creditCardSoleks?: CreditCardSolek[];
}

export const UPDATE_MACHINE_FORM_INPUTS: IUpdateTerminalKeysFormInput[] = [
    {
        key: 'emvTerminal.emvType',
        type: 'select',
        options: [EmvType.NONE, EmvType.PELECARD, EmvType.VERIFONE],
        label: 'Emv Type',
        disabled: false,
    },
    {
        key: 'emvTerminal.pelecard.terminalNumber',
        label: 'Pelecard Terminal Number',
        disabled: false,
        shouldCheckEmvType: 'emvTerminal.emvType',
        emvTypes: [EmvType.PELECARD],
    },
    {
        key: 'emvTerminal.pelecard.user',
        label: 'Pelecard Terminal User',
        disabled: false,
        shouldCheckEmvType: 'emvTerminal.emvType',

        emvTypes: [EmvType.PELECARD],
    },
    {
        key: 'emvTerminal.pelecard.password',
        label: 'Pelecard Terminal Password',
        disabled: false,
        shouldCheckEmvType: 'emvTerminal.emvType',

        emvTypes: [EmvType.PELECARD],
    },
    {
        key: 'onlineTerminal.creditCardSolek',
        label: 'Credit Card Solek Online',
        type: 'select',
        options: [CreditCardSolek.CARDCOM, CreditCardSolek.MESHULAM, CreditCardSolek.PELECARD],
        disabled: false,
    },
    {
        key: 'onlineTerminal.cardcom.terminalNumber',
        label: 'Cardcom Terminal Number',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.CARDCOM],
    },
    {
        key: 'onlineTerminal.cardcom.user',
        label: 'Cardcom User',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.CARDCOM],
    },
    {
        key: 'onlineTerminal.cardcom.password',
        label: 'Cardcom Password',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.CARDCOM],
    },
    {
        key: 'onlineTerminal.pelecard.terminalNumber',
        label: 'Pelecard Terminal Number',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.PELECARD],
    },
    {
        key: 'onlineTerminal.pelecard.user',
        label: 'Pelecard User',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.PELECARD],
    },
    {
        key: 'onlineTerminal.pelecard.password',
        label: 'Pelecard Password',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.PELECARD],
    },
    {
        key: 'onlineTerminal.meshulam.userId',
        label: 'Meshulam User id',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.MESHULAM],
    },

    {
        key: 'onlineRetainerTerminal.creditCardSolek',
        label: 'Credit Card Solek Online Retainer',
        type: 'select',
        options: [CreditCardSolek.CARDCOM, CreditCardSolek.MESHULAM, CreditCardSolek.PELECARD],
        disabled: false,
    },
    {
        key: 'onlineRetainerTerminal.cardcom.terminalNumber',
        label: 'Cardcom Terminal Number',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineRetainerTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.CARDCOM],
    },
    {
        key: 'onlineRetainerTerminal.cardcom.user',
        label: 'Cardcom User',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineRetainerTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.CARDCOM],
    },
    {
        key: 'onlineRetainerTerminal.cardcom.password',
        label: 'Cardcom Password',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineRetainerTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.CARDCOM],
    },
    {
        key: 'onlineRetainerTerminal.pelecard.terminalNumber',
        label: 'Pelecard Terminal Number',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineRetainerTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.PELECARD],
    },
    {
        key: 'onlineRetainerTerminal.pelecard.user',
        label: 'Pelecard User',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineRetainerTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.PELECARD],
    },
    {
        key: 'onlineRetainerTerminal.pelecard.password',
        label: 'Pelecard Password',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineRetainerTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.PELECARD],
    },
    {
        key: 'onlineRetainerTerminal.meshulam.userId',
        label: 'Meshulam User id',
        disabled: false,
        shouldCheckCreditCardSolek: 'onlineRetainerTerminal.creditCardSolek',
        creditCardSoleks: [CreditCardSolek.MESHULAM],
    },
];
