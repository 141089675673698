import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import get from 'lodash-es/get';
import { useEffect, useState } from 'react';
import { environment } from 'src/common/config';
import useApi from 'src/common/hooks/use-api.hook';
import { UpdateTerminalKeyFormInputRow } from './input-row.component';
import { UPDATE_MACHINE_FORM_INPUTS } from './update-terminal-keys-form.config';

export const UpdateTerminalKeysFormComponent = props => {
    const { terminalKeys, setTerminalKeys, businessId, websiteId } = props;
    const [hasUpdatedFields, setHasUpdatedFields] = useState(false);

    const {
        response: updateTerminalKeysResponse,
        error: updateTerminalKeysError,
        loading: updateTerminalKeysLoading,
        execute: updateTerminalKeysExecute,
    } = useApi({
        url: `${environment.service_panel}/terminalKey`,
        method: 'POST',
    });

    useEffect(() => {
        if (updateTerminalKeysResponse) {
            setHasUpdatedFields(false);
        }
    }, [updateTerminalKeysResponse]);

    // useHandleUpdateMachineResponses(
    //     updateMachineResponse,
    //     updateMachineError,
    //     setSelectedMachine,
    //     setSelectedMachineId,
    // );

    const onChangeMachineDetail = type => event => {
        setHasUpdatedFields(true);

        const updatedTerminalKeys = {
            ...terminalKeys,
        };

        if (type.includes('.')) {
            const keys = type.split('.');
            let current = updatedTerminalKeys;

            for (let i = 0; i < keys.length - 1; i++) {
                if (!current[keys[i]]) {
                    current[keys[i]] = {};
                }
                current = current[keys[i]];
            }

            current[keys[keys.length - 1]] = event.target.value;
        } else {
            updatedTerminalKeys[type] = event.target.value;
        }

        setTerminalKeys(updatedTerminalKeys);
        console.log('updatedTerminalKeys', updatedTerminalKeys);
    };

    const updateTerminalKeysDetails = () => {
        updateTerminalKeysExecute({
            data: {
                businessId,
                websiteId,
                terminalKeys,
            },
        });
    };

    return (
        <div className="selected-machine">
            <div className="machine-details-editor">
                <div className="input-text mt-3">
                    {/* <h4>שם קופה משוייכת למחשב זה: {selectedMachine?.cashier?.name}</h4> */}
                </div>
                {UPDATE_MACHINE_FORM_INPUTS.map(formInput =>
                    formInput.type === 'select' ? (
                        <div className="mt-2">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{formInput.label}</InputLabel>
                                <Select
                                    key={`formInput-${formInput.key}-${get(terminalKeys, formInput.key)}`}
                                    onChange={onChangeMachineDetail(formInput.key)}
                                    value={get(terminalKeys, formInput.key)}>
                                    <MenuItem placeholder="Please select" selected disabled>
                                        Please select
                                    </MenuItem>
                                    {formInput?.options.map(option => (
                                        <MenuItem value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    ) : (
                        ((formInput.shouldCheckEmvType &&
                            formInput.emvTypes.includes(get(terminalKeys, formInput.shouldCheckEmvType))) ||
                            (formInput.shouldCheckCreditCardSolek &&
                                formInput.creditCardSoleks.includes(
                                    get(terminalKeys, formInput.shouldCheckCreditCardSolek),
                                ))) && (
                            <UpdateTerminalKeyFormInputRow
                                key={`formInput-${formInput.key}`}
                                onChange={onChangeMachineDetail(formInput.key)}
                                disabled={formInput.disabled}
                                label={formInput.label}
                                note={formInput.note}
                                value={get(terminalKeys, formInput.key)?.toString()}
                            />
                        )
                    ),
                )}
            </div>
            {/* <div className="error-text">{get(updateMachineError, 'response.data.msg')}</div> */}
            <Button disabled={!hasUpdatedFields} onClick={updateTerminalKeysDetails} variant="contained">
                עדכן נתונים
            </Button>
        </div>
    );
};
