import React, { useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { Loader } from '../../components/loader/loader.component';
import { CustomersExcelsCompare } from './components/customers-excels-compare.component';
import { CustomersExcelCleaner } from './components/customers-excel-cleaner';

export const CustomersImportSection = () => {
  const [splitFile, setSplitFile] = useState(null);
  const splitFileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const splitXLSX = () => {
    if (!splitFile || isLoading) {
      return;
    }
    setIsLoading(true);

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const json = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      let splitIndex = 0;
      const splitSize = 500;
      const delay = 500; // Adjust the delay time (in milliseconds) if needed
      const splitFiles = [];

      const downloadSlicedFile = () => {
        if (splitIndex * splitSize >= json.length) {
          const zip = new JSZip();
          splitFiles.forEach((splitFile) => {
            zip.file(splitFile.name, splitFile.data);
          });
          zip.generateAsync({ type: "blob" }).then((zipFile) => {
            saveAs(zipFile, "split_contacts.zip");
            setIsLoading(false);
          });
          return;
        }

        const header = json.slice(0, 1);
        const sliced: any = header.concat(json.slice(splitIndex * splitSize + 1, (splitIndex + 1) * splitSize + 1));
        const newWb = XLSX.utils.book_new();
        const newWs = XLSX.utils.aoa_to_sheet(sliced);
        XLSX.utils.book_append_sheet(newWb, newWs, 'Sheet1');
        const wbout = XLSX.write(newWb, { bookType: 'xlsx', type: 'binary' });

        const buffer = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buffer);
        for (let j = 0; j < wbout.length; j++) {
          view[j] = wbout.charCodeAt(j) & 0xff;
        }

        splitFiles.push({
          name: `split_contacts_${splitIndex + 1}.xlsx`,
          data: new Blob([buffer], { type: 'application/octet-stream' })
        });

        splitIndex++;
        setTimeout(downloadSlicedFile, delay);
      };

      downloadSlicedFile();
    };

    reader.readAsBinaryString(splitFile);
  };

  const handleSplitFileChange = (e) => {
    const file = e.target.files[0];
    setSplitFile(file);
  };

  return (
    <div >
      <h2 className='mt-2'>העברת מאגר לקוחות</h2>
      <div className="box">
        <span>1.נא לגשת בתוכנה ל-דוחות וSMS ואז ללחוץ על חפש דו"ח </span><br/>
        <span>2. בחלון שמופיע לבחור לקוחות ואז כל הלקוחות, לשמור את הקובץ בתיקיית העסק בשם לקוחות אופטימוס_שם עסק</span>
        <h3>Clean Optimus Exported Contacts List to ZENAT format Cleaned**</h3>
        <CustomersExcelCleaner />
      </div>
      <div className="box">
        <h2>Split Contacts to 500 ZIP ALL for upload ZENAT</h2>
        <div>The task is to split a large JSON file containing contacts into smaller files, with each file containing up to 500 contacts. The smaller files will be saved as ZIP archives for easier transfer and storage. This will make the upload process more efficient and manageable.</div>
        <div className='description'><strong>The result JSON files can be uploaded to ZENAT import customers page</strong></div>
        <input
          type="file"
          ref={splitFileInputRef}
          accept=".xlsx"
          onChange={handleSplitFileChange}
        />
        <br />
        <button onClick={splitXLSX}>Split XLSX</button>
        {isLoading && (
          <Loader />
        )}
      </div>
      <div className="box">
        <h3>Filter New Customers need 2 Modified Until Files Cleaned**</h3>
        <div className='description'>The task is to compare two Excel files of contact information and extract only the rows that are different. These rows will be combined into a new file for further use, ensuring that the most up-to-date contact information is being used.</div>
        <CustomersExcelsCompare />
      </div>
    </div>
  );
};
