import { useState } from 'react';
import { useMapEvents, MapContainer, TileLayer, Circle, Marker, Popup } from 'react-leaflet';

import L from 'leaflet';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export const MapRadiusScreen = () => {
    const [radius, setRadius] = useState<number>(500);
    const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: 32.517127, lng: 35.148529 });
    const [newPoint, setPoints] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0});

    const onChangeRadius = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setRadius(Number(value));
    };

    const LocationFinderDummy = () => {
        const map = useMapEvents({
            click(e) {
                setCenter(e.latlng);
                console.log(e.latlng);
            },
        });
        return null;
    };

    const onChangeNewPoint = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const [lat, lng] = value.split(',');
        setPoints({ lat: Number(lat), lng: Number(lng) });
    };

    return (
        <>
            <div>רדיוס במטרים</div>
            <input
                dir="ltr"
                onChange={onChangeRadius}
                value={radius}
                className="width-200"
                placeholder="נא להזין רדיוס במטרים"
            />
            <div className="mb-2">
                Lat: <strong>{center.lat}</strong>, Lng: <strong>{center.lng}</strong>
            </div>
            <div style={{ textAlign: 'right' }}>נקודה במפה להוסיף</div>
            <input
                className="width-200"
                dir="ltr"
                value={`${newPoint?.lat},${newPoint?.lng}`}
                onChange={onChangeNewPoint}
                placeholder="LatLng"
            />

            <MapContainer center={center} zoom={13} style={{ height: '700px', width: '100%' }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Circle center={center} radius={radius} />
                <LocationFinderDummy />
                {newPoint?.lat && newPoint?.lng && (
                    <Marker position={newPoint}>
                        <Popup>
                            Lat: {newPoint.lat}, Lng: {newPoint.lng}
                        </Popup>
                    </Marker>
                )}
                <Marker position={center}>
                    <Popup>
                        Lat: {center.lat}, Lng: {center.lng}
                    </Popup>
                </Marker>
            </MapContainer>
        </>
    );
};
