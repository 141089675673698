import { EmvType } from 'src/common/enums';

interface IUpdateMachineFormInput {
    key: string;
    label: string;
    disabled: boolean;
    note?: string;
    emvTypes: EmvType[];
}

export const UPDATE_MACHINE_FORM_INPUTS: IUpdateMachineFormInput[] = [
    {
        key: 'id',
        label: 'MachineId',
        disabled: false,
        emvTypes: [EmvType.VERIFONE, EmvType.PELECARD, EmvType.NONE],
    },
    {
        key: 'cashier._id',
        label: 'CashierId',
        disabled: true,
        emvTypes: [EmvType.VERIFONE, EmvType.PELECARD, EmvType.NONE],
    },
    {
        key: 'pelecardShopNumber',
        label: 'Pelecard Shop Number',
        disabled: false,
        emvTypes: [EmvType.PELECARD],
    },
    {
        key: 'ChainId',
        label: 'ChainId',
        disabled: false,
        emvTypes: [EmvType.VERIFONE],
    },
    {
        key: 'LaneId',
        label: 'LaneId',
        disabled: false,
        emvTypes: [EmvType.VERIFONE],
    },
    {
        key: 'StoreId',
        label: 'StoreId',
        disabled: false,
        emvTypes: [EmvType.VERIFONE],
    },
    {
        key: 'IpAddress',
        label: 'IpAddress',
        disabled: false,
        emvTypes: [EmvType.VERIFONE],
    },
    {
        key: 'shvaTermId',
        label: 'shvaTermId',
        disabled: false,
        note: 'This is important for the shva system',
        emvTypes: [EmvType.VERIFONE],
    },
];
