import {
    Grid,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Button,
    TableRow,
    Card,
    CardContent,
    TextField,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import './manage-balances.screen.css';
import { useBusinesses } from 'src/common/hooks/use-businesses.hook';
import { useWebsites } from 'src/common/hooks/use-websites.hook';
import { useBaskets } from './hooks/use-baskets-hook';
import { BasketActionRow } from './components/basket-action-row.component';
import moment from 'moment';
import { BasketActionType, BasketType } from 'src/common/enums';
import { ManageBasketActionRowDialog } from './dialogs';
import { LoaderDialog } from 'src/dialogs';
import { ManageBalancesService } from './manage-balances.service';

export const ManageBalances = () => {
    const { businesses } = useBusinesses();

    const [search, setSearch] = useState('');
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const [selectedMonthAndYear, setSelectedMonthAndYear] = useState(moment().format('MM/YYYY'));
    const [selectedWebsite, setSelectedWebsite] = useState(null);
    const [selectedBasket, setSelectedBasket] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [selectedBasketAction, setSelectedBasketAction] = useState(null);

    const { websites, getWebsitesLoading } = useWebsites(selectedBusiness);
    const { baskets, getBasketsLoading, getBasketsExecute } = useBaskets(
        selectedMonthAndYear,
        selectedWebsite,
        selectedBusiness,
        selectedBasket,
    );

    const onCloseManageBasketActionRow = (confirmed: boolean): void => {
        if (confirmed) {
            getBasketsExecute();
        }
        setSelectedBasketAction(null);
    };

    useEffect(() => {
        if (websites.length === 1 && !selectedWebsite) {
            setSelectedWebsite(websites[0]._id);
        }
    }, [websites]);

    const selectedBasketData = useMemo(() => {
        return baskets.find(e => e._id === selectedBasket);
    }, [selectedBasket, baskets]);

    const basketActions = useMemo(() => {
        if (!selectedBasket) {
            return [];
        }
        let filteredBasketActions = baskets.find(e => e._id === selectedBasket)?.actions || [];

        // if( selectedBasketData.type === BasketType.COMMISION_THIS_MONTH && selectedBasketData.type !== BasketType.REDEEMED_THIS_MONTH_BUY_NOW_BOOK_LATER_CC ) {
        filteredBasketActions = filteredBasketActions?.filter(e => e.type === BasketActionType.GENERAL);
        // }
        return filteredBasketActions;
    }, [selectedBasket, selectedBasketData]);

    const basket_BOOK_NOW_ONLINE_SALES_THIS_MONTH_CC = useMemo(() => {
        return baskets.find(e => e.type === BasketType.BOOK_NOW_ONLINE_SALES_THIS_MONTH_CC);
    }, [baskets]);

    const basket_BUY_NOW_BOOK_NOW_VENUE = useMemo(() => {
        return baskets.find(e => e.type === BasketType.BUY_NOW_BOOK_NOW_VENUE);
    }, [baskets]);

    const basket_BOOK_LATER_ONLINE_SALES_THIS_MONTH_CC = useMemo(() => {
        return baskets.find(e => e.type === BasketType.BOOK_LATER_ONLINE_SALES_THIS_MONTH_CC);
    }, [baskets]);

    const basket_COMMISION_THIS_MONTH = useMemo(() => {
        return baskets.find(e => e.type === BasketType.COMMISION_THIS_MONTH) || { amount: 0 };
    }, [baskets]);

    const comission = useMemo(() => {
        return basket_COMMISION_THIS_MONTH?.amount;
    }, [basket_COMMISION_THIS_MONTH]);

    const basket_BUY_NOW_OFFLINE_SALES_THIS_MONTH_VENUE = useMemo(() => {
        return baskets.find(e => e.type === BasketType.BUY_NOW_OFFLINE_SALES_THIS_MONTH_VENUE);
    }, [baskets]);

    const basket_CONSULT_THIS_MONTH = useMemo(() => {
        return baskets.find(e => e.type === BasketType.CONSULT_THIS_MONTH);
    }, [baskets]);

    const basket_LEADS_THIS_MONTH = useMemo(() => {
        return baskets.find(e => e.type === BasketType.LEAD_THIS_MONTH);
    }, [baskets]);

    const PROCESSING_FEE_THIS_MONTH = useMemo(() => {
        return baskets.find(e => e.type === BasketType.PROCESSING_FEE_THIS_MONTH);
    }, [baskets]);

    const totalSalesSelectedMonthMonth = useMemo(() => {
        return (
            (basket_BOOK_NOW_ONLINE_SALES_THIS_MONTH_CC?.amount || 0) +
            (basket_BOOK_LATER_ONLINE_SALES_THIS_MONTH_CC?.amount || 0) +
            (basket_BUY_NOW_OFFLINE_SALES_THIS_MONTH_VENUE?.amount || 0) +
            (basket_CONSULT_THIS_MONTH?.amount || 0) +
            (basket_LEADS_THIS_MONTH?.amount || 0)
        );
    }, [
        basket_BUY_NOW_BOOK_NOW_VENUE,
        basket_BOOK_NOW_ONLINE_SALES_THIS_MONTH_CC,
        basket_BOOK_LATER_ONLINE_SALES_THIS_MONTH_CC,
    ]);

    const basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_NOW_CC = useMemo(() => {
        return baskets.find(e => e.type === BasketType.REDEEMED_THIS_MONTH_BUY_NOW_BOOK_NOW_CC);
    }, [baskets]);

    const basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_LATER_CC = useMemo(() => {
        return baskets.find(e => e.type === BasketType.REDEEMED_THIS_MONTH_BUY_NOW_BOOK_LATER_CC);
    }, [baskets]);

    const remainMoney = useMemo(() => {
        return Number(
            (
                (basket_BOOK_LATER_ONLINE_SALES_THIS_MONTH_CC?.amount || 0) +
                (basket_LEADS_THIS_MONTH?.amount || 0) +
                (basket_BOOK_NOW_ONLINE_SALES_THIS_MONTH_CC?.amount || 0) -
                ((basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_NOW_CC?.amount || 0) +
                    (basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_LATER_CC?.amount || 0))
            ).toFixed(3),
        );
    }, [baskets]);

    const ashraiFee = useMemo(() => {
        return Number(
            (
                ((basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_NOW_CC?.amount || 0) +
                    (basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_LATER_CC?.amount || 0)) *
                0.009
            ).toFixed(3),
        );
    }, [basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_NOW_CC, basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_LATER_CC]);

    const amountMadeWithZENAT = useMemo(() => {
        return Number(
            (totalSalesSelectedMonthMonth - (comission + ashraiFee) * 1.17 + PROCESSING_FEE_THIS_MONTH?.amount).toFixed(
                3,
            ),
        );
    }, [
        totalSalesSelectedMonthMonth,
        basket_COMMISION_THIS_MONTH,
        basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_LATER_CC,
        basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_NOW_CC,
        PROCESSING_FEE_THIS_MONTH,
    ]);

    // Update page and rowsPerPage for pagination
    const handleChangePage = (event, newPage): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event): void => {
        setRowsPerPage(parseInt(event.target.value, 25));
        setPage(0);
    };

    const handleChangeBusiness = event => {
        setSelectedBusiness(event.target.value);
        setSelectedWebsite(null);
    };

    const handleChangeMonthANdYear = event => {
        setSelectedMonthAndYear(event.target.value);
    };

    const handleChangeWebsite = event => {
        setSelectedWebsite(event.target.value);
    };

    const handleChangeBasket = event => {
        setSelectedBasket(event.target.value);
    };

    const resetBasketAmount = async () => {
        try {
            await ManageBalancesService.resetBasketAmount(selectedBasketData?._id, selectedWebsite, selectedBusiness);
        } catch (error) {
            console.log('Error in resetBasketAmount', error);
        }
    }

    const zenatName = useMemo(() => {
        return businesses.find(e => e._id === selectedBusiness)?.zenatName || '';
    }, [selectedBusiness]);

    const websiteName = useMemo(() => {
        return websites.find(e => e._id === selectedWebsite)?.websiteName || '';
    }, [selectedWebsite]);

    const finalAmount =
        amountMadeWithZENAT -
        PROCESSING_FEE_THIS_MONTH?.amount -
        remainMoney -
        (basket_BUY_NOW_OFFLINE_SALES_THIS_MONTH_VENUE?.amount || 0);

    const refreshButton = () => {
        getBasketsExecute();
    };

    return (
        <Paper className="manage-balances-container">
            <h1>מעקב וניהול יתרות קופות</h1>
            <Button onClick={refreshButton} variant="contained">
                REFRESH
            </Button>
            <div className="manage-filters-wrapper">
                <div className="filtering-balances">
                    <div>חודש ושנה</div>
                    <Select className="generic-select" value={selectedMonthAndYear} onChange={handleChangeMonthANdYear}>
                        {Array(24)
                            .fill(0)
                            .map((_, i) => (
                                <MenuItem
                                    key={`month-${i}}`}
                                    value={moment().subtract(12, 'month').add(i, 'month').format('MM/YYYY')}>
                                    {moment().subtract(12, 'month').add(i, 'month').format('MM/YYYY')}
                                </MenuItem>
                            ))}
                    </Select>
                    {!!selectedMonthAndYear && (
                        <>
                            <div>עסק</div>
                            <Select className="generic-select" value={selectedBusiness} onChange={handleChangeBusiness}>
                                {businesses.map(business => (
                                    <MenuItem key={`business-${business._id}`} value={business._id}>
                                        {business.zenatName} - {business.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                    )}
                    {!!selectedBusiness && (
                        <>
                            <div className="mt-2">סניף</div>
                            <Select className="generic-select" value={selectedWebsite} onChange={handleChangeWebsite}>
                                {getWebsitesLoading
                                    ? null
                                    : websites.map(website => (
                                          <MenuItem key={`website-${website._id}`} value={website._id}>
                                              {website.websiteName}
                                          </MenuItem>
                                      ))}
                            </Select>
                        </>
                    )}
                    {!!selectedWebsite && (
                        <>
                            <div className="mt-2">קופה</div>
                            <Select className="generic-select" value={selectedBasket} onChange={handleChangeBasket}>
                                {getBasketsLoading
                                    ? null
                                    : baskets.map(basket => (
                                          <MenuItem key={`basket-${basket._id}`} value={basket._id}>
                                              {basket.type}{basket.partner ? ` - ${basket.partner?.name} - ${basket?.partner?.phoneNumber}` : ''} 
                                          </MenuItem>
                                      ))}
                            </Select>
                        </>
                    )}
                </div>
                <div className="mt-3">חפש</div>
                <TextField
                    className="search-input"
                    label="הקלד מס' שובר / טלפון לקוח"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </div>
            {selectedBasketData && selectedWebsite && (
                <Card className="width-400 pr-2 mt-3" variant="outlined">
                    <div className="mt-2">פרטי קופה</div>
                    <div className='mt-2'>
                        {!!selectedBasketData.partner && (
                            <strong>
                                {selectedBasketData.partner?.name} - {selectedBasketData.partner?.phoneNumber}
                            </strong>
                        )}
                    </div>
                    <div className="mb-2">
                        <strong>יתרת קופה: {selectedBasketData?.amount} ש״ח</strong>
                    </div>
                    <Button onClick={resetBasketAmount} variant="contained">
                        Reset basket amount to 0
                    </Button>
                </Card>
            )}
            {!!selectedWebsite && (
                <Card className="width-500 pr-2 mt-3" variant="outlined">
                    <div className="flex mt-2">
                        <span className="width-350">( ONLINE SALES )</span>
                        <strong dir="ltr">
                            {(basket_BOOK_LATER_ONLINE_SALES_THIS_MONTH_CC?.amount || 0) +
                                (basket_BOOK_NOW_ONLINE_SALES_THIS_MONTH_CC?.amount || 0)}{' '}
                            ₪
                        </strong>
                    </div>
                    <div className="flex mt-2">
                        <span className="width-350">ALL PPA AMOUNTS ( ALL REDEEMED CC )</span>
                        <strong dir="ltr">
                            {(basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_LATER_CC?.amount || 0) +
                                (basket_REDEEMED_THIS_MONTH_BUY_NOW_BOOK_NOW_CC?.amount || 0)}{' '}
                            ₪
                        </strong>
                    </div>

                    <hr style={{ marginRight: -10 }} />
                    <div className="flex mt-2 red">
                        <span className="width-350">סך כל מכירות לעסק חודש נבחר:</span>
                        <strong dir="ltr">{totalSalesSelectedMonthMonth} ₪</strong>
                    </div>
                    <div className="flex mt-2">
                        <span className="width-350">סך כל עמלות לא כולל מע״מ:</span>
                        <strong dir="ltr">{basket_COMMISION_THIS_MONTH?.amount?.toFixed(3)} ₪</strong>
                    </div>
                    <div className="flex mt-2 grey">
                        <span className="width-350">סך כל עמלות כולל מע״מ:</span>
                        <strong dir="ltr">{(comission * 1.17).toFixed(3)} ₪</strong>
                    </div>
                    <div className="flex mt-2">
                        <span className="width-350">עמלות אשראי לא כולל מע״מ</span>
                        <strong dir="ltr">{ashraiFee.toFixed(3)} ₪</strong>
                    </div>
                    <div className="flex mt-2 grey">
                        <span className="width-350">עמלות אשראי כולל מע״מ</span>
                        <strong dir="ltr">{(ashraiFee * 1.17).toFixed(3)} ₪</strong>
                    </div>
                    <div className="flex mt-2 primary-bg">
                        <span className="width-350">סה"כ צברת עם ZENAT:</span>
                        <strong dir="ltr">{amountMadeWithZENAT} ₪</strong>
                    </div>
                    <div className="flex mt-2">
                        <span className="width-350">Offline Sales ( pay at venue )</span>
                        <strong dir="ltr">{basket_BUY_NOW_OFFLINE_SALES_THIS_MONTH_VENUE?.amount || 0} ₪</strong>
                    </div>
                    <div className="flex mt-2">
                        <span className="width-350">כסף שיוגבה על ידי העסק מהלקוחות ( Remain Money ):</span>
                        <strong dir="ltr">{remainMoney} ₪</strong>
                    </div>
                    <div className="flex mt-2 primary-bg">
                        <span className="width-350">
                            יתרת חשבון סניף לחודש נבחר: {finalAmount > 0 ? 'אנחנו חייבים לך' : 'אתה חייב לנו'}
                        </span>
                        <strong dir="ltr">{finalAmount?.toFixed(3)} ₪ </strong>
                    </div>
                </Card>
            )}
            <TableContainer className="mt-2">
                <Table>
                    <TableHead>
                        <TableRow className="manage-balances-row">
                            <TableCell>שם לקוח</TableCell>
                            <TableCell>טלפון</TableCell>
                            <TableCell>ת.רכישה</TableCell>
                            <TableCell>מס' שובר</TableCell>
                            <TableCell>מס' עסקה</TableCell>
                            <TableCell>שם שובר</TableCell>
                            <TableCell>מחיר</TableCell>
                            <TableCell>עסק</TableCell>
                            <TableCell>סניף</TableCell>
                            <TableCell>פעולה</TableCell>
                            {/* <TableCell>סוג תנועה</TableCell> */}
                            <TableCell>שולם באפליקציה PPA</TableCell>
                            <TableCell>לתשלום בעסק</TableCell>
                            {/* <TableCell>עמלה </TableCell> */}
                            <TableCell>עמלה</TableCell>
                            <TableCell>עמלה כולל מע"מ</TableCell>
                            <TableCell>פעולות</TableCell>
                            {/* <TableCell>פעולות</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {basketActions.map(basketAction => (
                            <BasketActionRow
                                setSelectedBasketAction={setSelectedBasketAction}
                                zenatName={zenatName}
                                websiteName={websiteName}
                                key={`basketAction-${basketAction._id}`}
                                basketAction={basketAction}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    {/* <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={filteredCoupons.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        dir="rtl"
                    /> */}
                </Grid>
            </Grid>
            <LoaderDialog isLoaderDialog={getBasketsLoading} />
            <ManageBasketActionRowDialog
                basket={selectedBasketData}
                basketAction={selectedBasketAction}
                onClose={onCloseManageBasketActionRow}
                isVisible={!!selectedBasketAction}
            />
        </Paper>
    );
};
