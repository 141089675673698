import './tools.css';
import { CustomersImportSection } from '../../sections/customers/customers-import.section';
import { Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { DocumentsImportSection } from '../../sections/documents/documents-import.section';
import { KabalotImportSection } from '../../sections/kabalot/kabalot-import.section';
import { BookingsImportSection } from '../../sections/bookings/bookings-import.section';
import { ChecksImportSection } from '../../sections/checks/checks-import.section';
import { ProcessActiveSeriesesSection } from '../../sections/serieses/serieses-import.section';
import { AnydeskProDownload } from 'src/sections/anydeskPro/anydeskpro-download.section';

export const Tools = () => {

  const CustomersImportTab = (
    <Tab eventKey="העברת לקוחות" title="העברת לקוחות">
      <CustomersImportSection />
    </Tab>
  );

  const TaxDocumentsImportTab = (
    <Tab eventKey="העברת חשבוניות מס" title="העברת חשבוניות מס">
      <DocumentsImportSection />
    </Tab>
  );

  const KabalotImportTab = (
    <Tab eventKey="העברת קבלות" title="העברת קבלות">
      <KabalotImportSection />
    </Tab>
  );

  const BookingsImportTab = (
    <Tab eventKey="העברת יומנים" title="העברת יומנים">
      <BookingsImportSection />
    </Tab>
  );

  const ChecksImportTab = (
    <Tab eventKey="העברת צ'קים" title="העברת צ'קים">
      <ChecksImportSection />
    </Tab>
  );
  const ProcessActiveSeriesesTab = (
    <Tab eventKey="עיבוד קובץ מנויים בפעילות" title="עיבוד קובץ מנויים בפעילות">
      <ProcessActiveSeriesesSection />
    </Tab>
  );
  const AnydeskExplainationNdownload = (
    <Tab eventKey="הסבר והורדה" title="הסבר והורדה">
      <AnydeskProDownload />
    </Tab>
  );

  return (
    <div className="Tools">
      <div className="container">
        <h1>Optimus כלים להעברת נתונים מעסק קיים</h1>
        <Tabs defaultActiveKey="העברה מתוכנת אופטימוס" id="tools-tabs" className="tools-tabs">
          <Tab eventKey="העברה מתוכנת אופטימוס" title="העברה מתוכנת אופטימוס">
            <Tabs defaultActiveKey="העברת לקוחות" id="tools-tabs" className="tools-tabs">
              {CustomersImportTab}
              {TaxDocumentsImportTab}
              {KabalotImportTab}
              {BookingsImportTab}
              {ChecksImportTab}
              {ProcessActiveSeriesesTab}
            </Tabs>
          </Tab>
          <Tab eventKey="anydeskPro" title="AnyDesk Unlimited">
            <Tabs defaultActiveKey="הסבר והורדה" id="tools-tabs" className="tools-tabs">
              {AnydeskExplainationNdownload}
            </Tabs>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};