import { Dialog, DialogContent, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

import { BranchesTab } from './tabs/branches-tab/branches.tab';
import { BusinessTab } from './tabs/business-tab/business.tab';
import { MachinesTab } from './tabs/machines-tab/machines.tab';
import { TerminalKeysTab } from './tabs/terminal-keys-tab/terminal-keys.tab';

interface IManageBusinessDialogProps {
    onClose: () => void;
    currentBusiness: any;
}

export const ManageBusinessDialog = (props: IManageBusinessDialogProps) => {
    const { onClose, currentBusiness } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleDialogClose = () => {
        onClose();
    };

    return (
        <Dialog open onClose={handleDialogClose} fullWidth maxWidth="md">
            <DialogContent style={{ height: 600 }}>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab label="כללי" />
                    <Tab label="סניפים" />
                    <Tab label="מחשבים ומכשירי סליקה" />
                    <Tab label="TERMINAL KEYS" />
                </Tabs>
                {selectedTab === 0 && <BusinessTab currentBusiness={currentBusiness} />}
                {selectedTab === 1 && <BranchesTab currentBusiness={currentBusiness} />}
                {selectedTab === 2 && <MachinesTab currentBusiness={currentBusiness} />}
                {selectedTab === 3 && <TerminalKeysTab currentBusiness={currentBusiness} />}
            </DialogContent>
        </Dialog>
    );
};
