import { Button, Dialog, DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';

interface IAreyouSureDialogProps {
    onClose: (confirmed: boolean) => void;
    text: string;
    okText: string;
    cancelText: string;
    isVisible: boolean;
}

export const AreYouSureDialog = (props: IAreyouSureDialogProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        setIsVisible(props.isVisible);
    }, [props.isVisible])

    const handleDialogClose = (confirmed: boolean): void => {
        props.onClose(confirmed);
        setIsVisible(false);
    };

    const onCancel = (): void => {
        handleDialogClose(false);
    };

    const onConfirm = (): void => {
        handleDialogClose(true);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <Dialog open onClose={onCancel} fullWidth maxWidth="sm">
            <DialogContent>
                <div>{props.text}</div>
                <div className='mt-2'>
                    <Button onClick={onConfirm} variant="contained">
                        {props.okText}
                    </Button>
                    <Button className='mr-2' onClick={onCancel} variant="outlined">
                        {props.cancelText}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};
