import { useState } from "react";

export const CheckSerialValidation = props => {
  const [jsonData, setJsonData] = useState([]);
  const [isSerial, setIsSerial] = useState(null);
  
  function handleFileUpload(event) {
    const file = event.target.files[0];
  
    if (!file) {
      return;
    }
  
    const reader = new FileReader();
  
    reader.onload = (event: any) => {
      try {
        const jsonObjects = JSON.parse(event.target.result);
        const itemsWithRows = jsonObjects.map((obj, index) => ({ ...obj, row: index + 1 }));
        setJsonData(itemsWithRows);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };
  
    reader.readAsText(file);
  }

  function checkSerialNumbers() {
    if (!jsonData.length) {
      return { isSerial: false };
    }
  
    const items = jsonData
      .map((item) => ({ ...item, id: Number(item.id), row: item.row }))
      .sort((a, b) => a.id - b.id);
    
    const missingNumbers = [];
  
    let prevId = items[0].id;
    for (let i = 1; i < items.length; i++) {
      const currId = items[i].id;
      if (currId - prevId !== 1) {
        missingNumbers.push(items[i]);
      }
      prevId = currId;
    }
  
    if (missingNumbers.length) {
      return { isSerial: false, missingNumbers };
    } else {
      return { isSerial: true };
    }
  }
  
  
  
  function handleCheckSerial() {
    const result: any = checkSerialNumbers();

    if (result.isSerial) {
      setIsSerial(true);
    } else {
      setIsSerial(false);
      console.log(result, 'result')
    }
  }

  return (
    <div>
      <input type="file" accept=".json" onChange={handleFileUpload} />
      <button onClick={handleCheckSerial}>Check Serial Numbers</button>
      {isSerial === true && <p>Serial numbers are sequential</p>}
      {isSerial === false && <p>Serial numbers are not sequential</p>}
    </div>
  );
}

