import { useState } from "react";
import * as XLSX from "xlsx";
import JSZip from "jszip";

export const ProcessBookingsXlsx = () => {
  const [files, setFiles] = useState([]);

  const onFileChange = (e) => {
    const uploadedFiles = e.target.files;
    setFiles(uploadedFiles);
  };

  const toTimestamp = (dateString, timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day, hours, minutes);
    return date.getTime();
  };

  const processFile = async (file): Promise<any> => {
    return new Promise((resolve) => {


      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });

        // First function processing
        const phoneRegex = /\b(04|05)\d{7,9}\b/;
        const timeRangeRegex = /\b\d{1,2}:\d{2}-\d{1,2}:\d{2}\b/;
        const secondFunctiontimeRangeRegex = /\b(\d{1,2}:\d{2})-(\d{1,2}:\d{2})\b/;

        const processedData: any = jsonData.map((row) => {
          let cellValue = row[0];
          if (cellValue) {
            const phoneMatch = cellValue.match(phoneRegex);
            const timeRangeMatch = cellValue.match(timeRangeRegex);

            if (phoneMatch && phoneMatch[0] && timeRangeMatch && timeRangeMatch[0]) {
              const phone = phoneMatch[0];
              row[1] = phone;
              cellValue = cellValue.replace(phone, "").trim();
            }

            if (timeRangeMatch && timeRangeMatch[0]) {
              const timeRange = timeRangeMatch[0];
              row[2] = timeRange;
              cellValue = cellValue.replace(timeRange, "").trim();
            }

            row[0] = cellValue;
          }
          return row;
        });

        // Create a new worksheet with the processed data
        const processedWorksheet = XLSX.utils.aoa_to_sheet(processedData);
        workbook.Sheets[firstSheetName] = processedWorksheet;

        // Second function processing
        const dateRegex = /תאריך\s+(\d{2}\/\d{2}\/\d{4})/;

        let date = null;
        let title = null;
        // Iterate over the first row of the worksheet to extract the date and title values
        for (let i = 0; i < processedData[0].length; i++) {
          const cellValue = processedData[0][i];
          if (cellValue) {
            const dateMatch = cellValue.match(dateRegex);
            if (dateMatch && dateMatch[1]) {
              date = dateMatch[1];
            } else {
              title = cellValue;
            }
          }
        }
        let updatedData = processedData.reduce((acc, row, index) => {
          const name = row[0];
          const phone = row[1];
          const timeRange = row[2];

          // Check if the current row has a date value and update the date variable
          const currentDateMatch = name.match(dateRegex);
          if (currentDateMatch && currentDateMatch[1]) {
            date = currentDateMatch[1];
            return acc; // Skip this row, as it contains the date value only
          }

          if (timeRange) {
            const phoneMatch = phone ? phone.match(phoneRegex) : null;
            const timeRangeMatch = timeRange.match(secondFunctiontimeRangeRegex);

            if (timeRangeMatch && timeRangeMatch[0]) {
              const start = timeRangeMatch[1];
              const end = timeRangeMatch[2];

              const startTime = toTimestamp(date, start);
              const endingTime = toTimestamp(date, end);

              const updatedRow: any = {
                name: name ? name : null,
                phoneNumber: phoneMatch && phoneMatch[0] ? phoneMatch[0] : null,
                start: start,
                end: end,
                date,
                startTime: startTime,
                endingTime: endingTime,
                duration: endingTime - startTime,
                deleted: false,
                c: false,
                booked: true,
                pending: false,
                freeTime: !(phoneMatch && phoneMatch[0])
              };

              let items = [];

              // Iterate over the subsequent rows until we find a row with a value in column C (timeRange)
              for (let j = index + 1; j < processedData.length; j++) {
                const nextRow = processedData[j];
                // const nextPhone = nextRow[1];
                const nextTimeRange = nextRow[2];

                if (!nextTimeRange) {
                  // Check if the cell value does not match the dateRegex before adding it to the items array
                  if (!nextRow[0].match(dateRegex)) {
                    items.push(nextRow[0]);
                  }
                } else {
                  break;
                }
              }

              if (items.length > 0) {
                updatedRow.items = items.join(', ');
              }

              acc.push(updatedRow);
            }
          }
          return acc;
        }, []);

        // console.log(updatedData, 'dsds')
        // downloadJsonFile(updatedData, `${title}.json`);

        // const headerRow = Object.keys(updatedData[0]).map(camelToUnderscore);

        // const dataRows = updatedData.map((row) => {
        //   return Object.values(row);
        // });

        // updatedData = [headerRow, ...dataRows];

        // const arrayOfArrays = updatedData.map(obj => Object.values(obj));

        // const updatedSheet = XLSX.utils.aoa_to_sheet(arrayOfArrays);
        // workbook.Sheets[firstSheetName] = updatedSheet;

        // downloadWorkbook(workbook, title);
        // (Move the contents of the processAndDownloadFile function here)
        // Replace the downloadJsonFile and downloadWorkbook calls with the following line:
        resolve({ title, jsonData: updatedData });
      }
    });
  };

  const processAndDownloadFile = async () => {
    if (!files.length) return;

    const zip = new JSZip();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { title, jsonData } = await processFile(file);
      const fileContent = JSON.stringify(jsonData, null, 2);
      zip.file(`${title}.json`, fileContent);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = "json_files.zip";
      link.click();
    });
  };

  return (
    <div>
      <input type="file" accept=".xlsx" onChange={onFileChange} multiple />
      <button onClick={processAndDownloadFile}>Process and Download</button>
    </div>
  );
};
