import axios from 'axios';
import { environment } from 'src/common/config';
import { IPartner } from './interfaces';

export class PartnersService {
    async getMarketingPartners(): Promise<IPartner[]> {
        const { data } = await axios.get(`${environment.service_panel}/partner/marketing`);
        return data?.data?.partners;
    }

    async getPartners(): Promise<IPartner[]> {
        const { data } = await axios.get(`${environment.service_panel}/partner/all`);
        return data?.data?.partners;
    }
}
