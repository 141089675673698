import { TextField } from '@mui/material';
import { ChangeEventHandler } from 'react';

interface IUpdateTerminalKeyFormInputRow {
    label: string;
    value: string;
    note: string;
    disabled: boolean;
    onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

export const UpdateTerminalKeyFormInputRow = (props: IUpdateTerminalKeyFormInputRow) => {
    const { label, value, disabled, onChange, note } = props;

    return (
        <div dir="ltr" className="input-text">
            <TextField value={value} disabled={disabled} onChange={onChange} label={label} style={{ width: '689px' }} />
            {!!note && <div className="mt-1">Note: {note}</div>}
        </div>
    );
};
