import { useEffect, useState } from 'react';
import { PartnersService } from './partners.service';
import { TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Table } from 'react-bootstrap';
import { IPartner } from './interfaces';
import './partners.screen.css';

export const PartnersScreen = () => {
    const [partnersService] = useState(() => new PartnersService());
    const [partners, setPartners] = useState<IPartner[]>([]);
    const [marketingPartners, setMarketingPartners] = useState<IPartner[]>([]);

    useEffect(() => {
        const initMarketingPartners = async () => {
            try {
                const currentMarketingPartners = await partnersService.getMarketingPartners();
                setMarketingPartners(currentMarketingPartners);
            } catch (error) {
                console.log('Error in initMarketingPartners', error);
            }
        };

        const initPartners = async () => {
            try {
                const currentPartners = await partnersService.getPartners();
                setPartners(currentPartners);
            } catch (error) {
                console.log('Error in initPartners', error);
            }
        };
        initMarketingPartners();
        initPartners();
    }, [partnersService]);

    return (
        <div dir="rtl">
            <TableContainer className="mt-2">
                <Table>
                    <TableHead>
                        <TableRow className="partners-header partners-row">
                            <TableCell>שם</TableCell>
                            <TableCell>טלפון</TableCell>
                            <TableCell>קוד הפניה</TableCell>
                            <TableCell>אימייל</TableCell>
                            <TableCell>Marketing Partner</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="partners-row">
                        {partners.map(partner => (
                            <TableRow key={`partner-${partner._id}`}>
                                <TableCell>{partner.name}</TableCell>
                                <TableCell>{partner.phoneNumber}</TableCell>
                                <TableCell>{partner.refCode}</TableCell>
                                <TableCell>{partner.email}</TableCell>
                                <TableCell>{partner?.marketingPartner?.name || '-'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
