import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';
import axios from 'axios';

const Home = () => {
    const [zenatBizToDo, setZenatBizToDo] = useState('');
    const [electronVersion, setElectronVersion] = useState('2.5.2');
    const [isLoadingElectronVersion, setIsLoadingElectronVersion] = useState(true);
    const navigate = useNavigate();
    // const electronVersion = '2.5.2';
    const zenatBizSheet =
        'https://docs.google.com/spreadsheets/d/1CTjenEmRr-foLWDkMIXIQ7I62rjD1M77xVcRR4St-cs/edit#gid=0';
    // search in Slack: -- in:#servers-deployments successfully deployed bz-ms-ui-svc on electron

    const downloadlink =
        'https://booklee.s3.eu-central-1.amazonaws.com/updates/ZENAT+Setup+' + electronVersion + '.exe';

    const downloadFile = () => {
        window.location.href = downloadlink;
    };

    function extractVersion(str) {
        const versionMatch = str.match(/version:\s*([0-9]+\.[0-9]+\.[0-9]+)/);
        return versionMatch ? versionMatch[1] : null;
    }

    const getLatestElectronVersion = async () => {
        try {
            const response = await fetch('https://booklee.s3.eu-central-1.amazonaws.com/updates/latest.yml')
            const responseText = await response.text();

            setElectronVersion(extractVersion(responseText));
            setIsLoadingElectronVersion(false);
        } catch(error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getLatestElectronVersion();
    }, [])

    const manageBusinesses = () => {
        navigate('/manage-businesses');
    };
    const manageQuicklyInvoiceBusinesses = () => {
        navigate('/manage-businesses-quickly-invoice');
    };

    const manageCoupons = () => {
        navigate('/manage-coupons');
    };

    const zenatBusinessSheet = () => {
        window.open(zenatBizSheet, '_blank');
    };

    return (
            <div className="grid-boxes-container">
            <h1>Overview</h1>
                <div className="section">
                    <div className="section-header">משימות לביצוע</div>
                    <div className="boxes">
                        {!isLoadingElectronVersion && <div className="grid-box" onClick={downloadFile}>
                            <img
                                className="grid-box-image"
                                src="https://booklee.s3.eu-central-1.amazonaws.com/mix/download.svg"
                            />
                            <h1 className="grid-box-title">Windows Electron</h1>
                            <h3 className="grid-box-title2">הורדת גירסה {electronVersion}</h3>
                        </div>}
                        <div className="grid-box" onClick={manageCoupons}>
                            <img
                                className="grid-box-image"
                                src="https://booklee.s3.eu-central-1.amazonaws.com/mix/coupon.svg"
                            />
                            <h1 className="grid-box-title">78</h1>
                            <h3 className="grid-box-title2">קופונים ממתינים לאישור</h3>
                        </div>
                        <div className="grid-box" onClick={zenatBusinessSheet}>
                            <img
                                className="grid-box-image"
                                src="https://booklee.s3.eu-central-1.amazonaws.com/mix/code-square-svgrepo-com.svg"
                            />
                            <h1 className="grid-box-title">{zenatBizToDo}77</h1>
                            <h3 className="grid-box-title2">משימות ממתינות ZENAT עסקים</h3>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="section-header">Kpis Measurement</div>
                    <div className="boxes">
                        <div className="grid-box" onClick={manageBusinesses}>
                            <img
                                className="grid-box-image"
                                src="https://booklee.s3.eu-central-1.amazonaws.com/mix/activity.svg"
                            />
                            <h1 className="grid-box-title">219</h1>
                            <h3 className="grid-box-title2">עסקים פעילים</h3>
                        </div>
                        <div className="grid-box" onClick={manageBusinesses}>
                            <img
                                className="grid-box-image"
                                src="https://booklee.s3.eu-central-1.amazonaws.com/mix/download.svg"
                            />
                            <h1 className="grid-box-title">7,870</h1>
                            <h3 className="grid-box-title2">הורדות אפליקציה החודש</h3>
                        </div>
                        <div className="grid-box" onClick={manageBusinesses}>
                            <img
                                className="grid-box-image"
                                src="https://booklee.s3.eu-central-1.amazonaws.com/mix/credit-card.svg"
                            />
                            <h1 className="grid-box-title">32,578</h1>
                            <h3 className="grid-box-title2">מס' טרנזקציות באשראי החודש</h3>
                        </div>
                        <div className="grid-box" onClick={manageBusinesses}>
                            <img
                                className="grid-box-image"
                                src="https://booklee.s3.eu-central-1.amazonaws.com/mix/cash.svg"
                            />
                            <h1 className="grid-box-title">87,484</h1>
                            <h3 className="grid-box-title2">מס' טרנזקציות במזומן החודש</h3>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default Home;
