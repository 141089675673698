import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const ChecksFromXlsx = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedJsonFile, setUploadedJsonFile] = useState(null);
  const [uploadedPhoneJsonFile, setUploadedPhoneJsonFile] = useState(null);

  const handleJsonFileUpload = (e) => {
    setUploadedJsonFile(e.target.files[0]);
  };

  const handleFileUpload = (e) => {
    setUploadedFile(e.target.files[0]);
  };

  const handlePhoneJsonFileUpload = (e) => {
    setUploadedPhoneJsonFile(e.target.files[0]);
  };

  const processFile = async () => {
    if (!uploadedFile) {
      alert('Please upload an XLSX file first.');
      return;
    }

    if (!uploadedJsonFile) {
      alert('Please upload a JSON file first.');
      return;
    }

    if (!uploadedPhoneJsonFile) {
      alert('Please upload a phone JSON file first.');
      return;
    }

    return new Promise(async (resolve) => {
      // Add this before the existing 'jsonFileData' declaration
      const phoneJsonFileData: any = await new Promise((phoneJsonResolve) => {
        const phoneJsonReader = new FileReader();

        phoneJsonReader.onload = (phoneJsonEvent: any) => {
          const phoneJsonData = JSON.parse(phoneJsonEvent.target.result);
          phoneJsonResolve(phoneJsonData);
        };

        phoneJsonReader.readAsText(uploadedPhoneJsonFile);
      });

      // Read and parse the JSON file
      const jsonFileData = await new Promise((jsonResolve) => {
        const jsonReader = new FileReader();

        jsonReader.onload = (jsonEvent: any) => {
          const jsonData = JSON.parse(jsonEvent.target.result);
          jsonResolve(jsonData);
        };

        jsonReader.readAsText(uploadedJsonFile);
      });


      // Read and process the XLSX file
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const organizedData = organizeData(rows);
        const organizedDataWithJsonAndPhone = organizedData.map((row, index) => {
          if (index === 0) {
            return [...row, "קבלה", "מס' טלפון"];
          }

          const currentId = jsonFileData[index - 1] && jsonFileData[index - 1].id;
          const matchingPhoneObj = phoneJsonFileData.find((obj) => obj.id === currentId);

          if (currentId && matchingPhoneObj) {
            return [...row, currentId, matchingPhoneObj.phoneNumber];
          } else if (currentId) {
            return [...row, currentId, ""];
          }

          return row;
        });

        resolve(organizedDataWithJsonAndPhone);
      };

      reader.readAsArrayBuffer(uploadedFile);
    });
  };

  const organizeData = (data) => {
    const unwantedText1 = "כמות צ'קים";
    const unwantedText2 = "מציג צקים עם תאריך פרעון מ";
    const unwantedText3 = "שם";

    const headerRow = [
      "שם",
      "מס' עסקה",
      "חשבון",
      "מס' צ'ק",
      "בנק",
      "סניף",
      "ת.פרעון",
      "סכום",
      "סטטוס",
      "חשבונית מס'"
    ];

    const regex = /^([\u0590-\u05FF\sA-Za-z'’]+)\s(\d+)\s(\d{2}\/\d{2}\/\d{4})\s(\d+)\s(\d+)\s(\d+)\s(\d+)\s(\d+)\s(?:([\u0590-\u05FF\s]+)\s)?(\d+)?$/;
    const caseARegex = /^([\u0590-\u05FF\sA-Za-z'’]+)\s(\d+)\s(\d{2}\/\d{2}\/\d{4})\s(\d+)\s(\d+)\s(\d+)\s(\d+)\s(\d+)$/;

    const organizedData = data
      .filter((row, index) => {
        const cellValue = row[0];
        return (
          (index === 0) ||
          (
            !cellValue.startsWith(unwantedText1) &&
            !cellValue.startsWith(unwantedText2) &&
            !cellValue.startsWith(unwantedText3)
          )
        );
      })
      .map((row, index) => {
        if (index === 0) {
          return headerRow;
        }

        const match = row[0].match(regex);
        const caseAMatch = row[0].match(caseARegex);

        if (match) {
          return [
            match[1], // שם
            match[8], // מס' עסקה
            match[7], // חשבון
            match[6], // מס' צ'ק
            match[5], // בנק
            match[4], // סניף
            match[3], // ת.פרעון
            match[2], // סכום
            match[9] || "", // סטטוס
            match[10] // חשבונית מס'
          ];
        } else if (caseAMatch) {
          return [
            caseAMatch[1], // שם
            caseAMatch[8], // מס' עסקה
            caseAMatch[7], // חשבון
            caseAMatch[6], // מס' צ'ק
            caseAMatch[5], // בנק
            caseAMatch[4], // סניף
            caseAMatch[3], // ת.פרעון
            caseAMatch[2], // סכום
            "", // סטטוס
            "" // חשבונית מס'
          ];
        } else {
          return row;
        }
      });

    return organizedData;
  };

  const downloadOrganizedData = (organizedData) => {
    const dataToExport = organizedData;
    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(newWorkbook, { type: 'array', bookType: 'xlsx' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'organized-data.xlsx');
  };





  const processAndDownload = async () => {
    const organizedData = await processFile();
    if (organizedData) {
      downloadOrganizedData(organizedData);
    }
  };



  return (
    <div >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          paddingLeft: '10px',
        }}
      >
        <h6 style={{ marginRight: '10px' }}>העתק הדבק XLSX</h6>
        <input type="file" accept=".xlsx" onChange={handleFileUpload} />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          paddingLeft: '10px',
        }}
      >
        <h6 style={{ marginRight: '10px' }}>צ'קים json</h6>
        <input type="file" accept=".json" onChange={handleJsonFileUpload} />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          paddingLeft: '10px',
        }}
      >
        <h6 style={{ marginRight: '10px' }}>קבלות json </h6>
        <input type="file" accept=".json" onChange={handlePhoneJsonFileUpload} />
      </div>


      <button onClick={processAndDownload}>Process and Download</button>
    </div>
  );
}
