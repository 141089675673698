export const getCustomerOrWorkerName = (customer) => {
    return (
        `${customer?.details?.firstName || ''} ${customer?.details?.lastName || ''}`?.trim() ||
        customer?.displayName ||
        'שם לא קיים במערכת'
    );
};

export const getCustomerFullPhoneNumber = (customer) => {
    let revisedPhoneNumber = customer?.phoneNumber;
    revisedPhoneNumber = `+9720${revisedPhoneNumber?.slice(
        revisedPhoneNumber?.length - 9,
        revisedPhoneNumber?.length
    )}`;
    return revisedPhoneNumber;
};

export const humanizePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return '';
    }
    const normalPhoneNumber = '0' + phoneNumber.slice(phoneNumber.length - 9, phoneNumber.length);
    return normalPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const humanizeBizPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return '';
    }
    const normalPhoneNumber = phoneNumber.slice(phoneNumber.length - 9, phoneNumber.length);
    return normalPhoneNumber.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
};
