import { PendingPhotoComponent } from '../../components/photo-container-element';

export const WebsitePhotosScreen = () => {
    return (
        <div>
            <h2>ניהול תמונות עסקים מבפנים ובחוץ</h2>

            <PendingPhotoComponent
                businessName="Zenat Beauty"
                branchName="Tel Aviv Branch"
                phoneNumber="0546948958"
                imageUrl="https://booklee.s3.eu-central-1.amazonaws.com/businesses/64e1d8f4f6edb9a34c7ef850/64e1edc5f6edb9a34c7ef857/b05eef90-4586-11ee-b5d7-ad15f15d5bfd/website-photo-9bc6fdbe-b034-405f-96d0-2e09f673982e-"
            />
        </div>
    );
};
