import axios from 'axios';
import { useState } from 'react';
import { environment } from 'src/common/config';

export const KabalotOcr = () => {
  const [images, setImages] = useState([] as any);
  const [progress, setProgress] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState(null);

  const handleChange = (e) => {
    const imageFiles = Array.from(e.target.files).map((file: any) =>
      URL.createObjectURL(file)
    );
    setImages(imageFiles);
  };

  const preprocessImage = async (imageSrc) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0, img.width, img.height);

        // Convert the image to grayscale
        const imageData = ctx.getImageData(0, 0, img.width, img.height);

        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
          data[i] = data[i + 1] = data[i + 2] = avg;
        }

        // // Apply the median filter for noise reduction
        // const newData = applyMedianFilter(imageData);
        // for (let i = 0; i < newData.length; i++) {
        //   data[i] = newData[i];
        // }
      
        ctx.putImageData(imageData, 0, 0);

        // document.body.appendChild(canvas)
        // Resolve the preprocessed image as a Data URL
        resolve(canvas.toDataURL());
      };
    });
  };

  const extractData = async () => {
    if (images.length === 0) {
      console.error("No images selected");
      return;
    }
  
    let arrayOfArraysOfObjects = [];
    let completedImages = 0;
    
    const preprocessedImages = [];

    for (const [index, image] of images.entries()) {
      try {
        // Preprocess the image before sending it to the server
        const preprocessedImage = await preprocessImage(image);
        preprocessedImages.push(preprocessedImage);
        
        // const rows = text.trim().split("\n");
        // const tableData = rows.map((row) => row.split(/\s{2,}/));
        
  
        completedImages++;
  
        const overallProgress = (completedImages / images.length) * 100;
        setProgress(overallProgress);
  
     


      } catch (error) {
        console.error("Error processing the image:", error);
      }
    }

      // Start the timer after all files have been sent to the server
      setElapsedTime(0);
      const startTime: any = new Date();

      if (completedImages === images.length) {
        // Calculate the estimated time based on the number of files, assuming each file takes 1 second
        const totalEstimatedTime = images.length * 0.5;
        setEstimatedTime(totalEstimatedTime);
    
        // Start the countdown
        const countdownInterval = setInterval(() => {
          setEstimatedTime((prevTime) => {
            if (prevTime > 0) {
              return prevTime - 1;
            } else {
              clearInterval(countdownInterval);
              return 0;
            }
          });
        }, 1000);
      }

    const jsonResponse = await axios.post(`${environment.service_panel}/ocr`, {
      images: preprocessedImages
    });

    arrayOfArraysOfObjects = jsonResponse.data; // Get the text data from the JSON response
    // const structuredData = arrayOfObjects(text);
    // arrayOfArraysOfObjects = arrayOfArraysOfObjects.concat(structuredData);

    console.log(arrayOfArraysOfObjects, 'ds')
    const endTime: any = new Date();
    const duration = (endTime - startTime) / 1000;
    setElapsedTime(duration);
    setProgress(0); // Reset progress after
    downloadJSON(arrayOfArraysOfObjects, "ocr-Kabaloot-results.json");

  

  };
  
  const downloadJSON = (data, filename) => {
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);
  
    return [
      hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : null,
      minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : null,
      seconds > 0 ? `${seconds} second${seconds > 1 ? 's' : ''}` : null,
    ]
      .filter((part) => part)
      .join(', ');
  };  

  return (
    <div>
      <input
        type="file"
        accept="image/png"
        onChange={handleChange}
        multiple
      />
      <button onClick={extractData}>עיבוד תמונות</button>
      {progress > 0 && <span>{progress.toFixed(2)}% Uploading to server</span>}
      {progress > 0 && !!estimatedTime && (
        <p>
          Estimated time remaining: <strong>{formatDuration(estimatedTime)}</strong>
        </p>
      )}
      {!!elapsedTime && (
        <p>
          Time taken: <strong>{formatDuration(elapsedTime)}</strong>
        </p>
      )}
    </div>
  );
};
