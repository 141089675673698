import { Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './manage-photos.css';
import { WebsitePhotosScreen } from './pages/website-photos';


const ManagePhotos = () => {


    return (
        <div>
            <h1>ניהול תמונות מעסקים, אישור דחיה</h1>
            <Tabs defaultActiveKey="תמונות עסקים" id="tools-tabs" className="tools-tabs">
                <Tab eventKey="תמונות עסקים" title="תמונות עסקים">
                <WebsitePhotosScreen />
                   
                </Tab>
                <Tab eventKey="תמונות פוסטים " title="תמונות פוסטים ">
                 
                </Tab>
            </Tabs>
        </div>
    );
};

export default ManagePhotos;
