import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { useState } from 'react';
import { environment } from 'src/common/config';
import useApi from 'src/common/hooks/use-api.hook';
import './add-business.component.css';

import useHandleAddBusinessResponses from './hooks/handle-add-business-responses.hook';

const AddBusinessComp = () => {
    // State for adding new business
    const [isOpen, setIsOpen] = useState(true); // For dialog's open/close state
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [zenatName, setZenatName] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [businessId, setBusinessId] = useState('');
    const [businessSignature, setBusinessSignature] = useState('');
    const [businessLogo, setBusinessLogo] = useState('');
    const [businessSlogan, setBusinessSlogan] = useState('');
    const [billingEmail, setBillingEmail] = useState('');
    // const [businessVisibility, setBusinessVisibility] = useState('');
    // const [businessLanguage, setBusinessLanguage] = useState('');

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const {
        response: addBusinessResponse,
        error: addBusinessError,
        execute: addBusinessExecute,
    } = useApi({
        url: `${environment.service_panel}/business`,
        method: 'POST',
    });

    useHandleAddBusinessResponses(addBusinessResponse, addBusinessError, setOpenAddDialog);

    const [address, setAddress] = useState({
        city: '',
        street: '',
        zipcode: '',
        cityEnglish: '',
        streetEnglish: '',
    });
    const [contact, setContact] = useState({
        phoneNumber: '',
        mobileNumber: '',
        email: '',
        websiteUrl: '',
    });

    const [bankDetails, setBankDetails] = useState({
        bank: '',
        branch: '',
        accountNumber: '',
    });

    const handleChange = event => {
        setBusinessType(event.target.value);
    };
    // const handleVisibilityChange = event => {
    //     setBusinessVisibility(event.target.value);
    // };
    // const handleLanguageChange = event => {
    //     setBusinessLanguage(event.target.value);
    // };

    const handleBankDetailsChange = field => e => {
        setBankDetails({
            ...bankDetails,
            [field]: e.target.value,
        });
    };

    const handleAddressChange = field => e => {
        setAddress({
            ...address,
            [field]: e.target.value,
        });
    };
    const handleContactChange = field => e => {
        setContact({
            ...contact,
            [field]: e.target.value,
        });
    };

    // Function to handle open of add dialog
    const handleAddDialogOpen = () => {
        setOpenAddDialog(true);
        clearFormData();
        setErrors({}); // Reset the errors
    };

    // Function to handle close of add dialog
    const handleAddDialogClose = () => {
        if (window.confirm('האם אתה בטוח?')) {
            setOpenAddDialog(false);
        } else {
            // Logic if user selects 'no'
        }
    };

    const addBusiness = () => {
        console.log('addBusiness')
        const newBusiness: any = {
            name: businessName,
            zenatName: zenatName,
            businessType: businessType,
            // businessVisibility: businessVisibility,
            // businessLanguage: businessLanguage,
            businessId: businessId,
            logo: businessLogo,
            slogan: businessSlogan,
            signature: businessSignature,
            billingEmail: billingEmail,
            contact: {
                phoneNumber: contact.phoneNumber,
                mobileNumber: contact.mobileNumber,
                email: contact.email,
                websiteUrl: contact.websiteUrl,
            },
            bank: {
                bank: bankDetails.bank,
                branch: bankDetails.branch,
                accountNumber: bankDetails.accountNumber,
            },
            address: {
                city: address.city,
                street: address.street,
                zipcode: address.zipcode,
                cityEnglish: address.cityEnglish,
                streetEnglish: address.streetEnglish,
            },
        };
        addBusinessExecute({
            data: newBusiness,
        });
    };

    const clearFormData = () => {
        // Clear basic details
        setBusinessName('');
        setZenatName('');
        setBusinessType('');
        // setBusinessVisibility('');
        // setBusinessLanguage('');
        setBusinessId('');
        setBusinessLogo('');
        setBusinessSlogan('');
        setBusinessSignature('');
        setBillingEmail('');

        // Clear contact details
        setContact({
            phoneNumber: '',
            mobileNumber: '',
            email: '',
            websiteUrl: '',
        });

        // Clear bank details
        setBankDetails({
            bank: '',
            branch: '',
            accountNumber: '',
        });

        // Clear address details
        setAddress({
            city: '',
            street: '',
            zipcode: '',
            cityEnglish: '',
            streetEnglish: '',
        });
    };

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};
        if (!businessType) {
            newErrors.businessType = 'חובה לבחור סוג עסק';
        }
        // if (!businessVisibility) {
        //     newErrors.businessVisibility = 'חובה לבחור סטטוס';
        // }
        // if (!businessLanguage) {
        //     newErrors.businessLanguage = 'חובה לבחור שפה';
        // }
        if (!bankDetails.branch /* condition to check if empty or invalid */) {
            newErrors.branch = 'חובה למלא מס סניף ';
        }
        if (!bankDetails.bank) {
            newErrors.bank = 'שדה הבנק לא יכול להישאר ריק';
        }
        if (!bankDetails.accountNumber) {
            newErrors.accountNumber = 'שדה מספר החשבון לא יכול להישאר ריק';
        }
        // Address Details
        if (!address.city) {
            newErrors.city = 'שדה העיר לא יכול להישאר ריק';
        }
        if (!address.street) {
            newErrors.street = 'שדה הרחוב לא יכול להישאר ריק';
        }
        if (!address.zipcode) {
            newErrors.zipcode = 'שדה המיקוד לא יכול להישאר ריק';
        }
        if (!address.cityEnglish) {
            newErrors.cityEnglish = 'שדה העיר (באנגלית) לא יכול להישאר ריק';
        }
        if (!address.streetEnglish) {
            newErrors.streetEnglish = 'שדה הרחוב (באנגלית) לא יכול להישאר ריק';
        }
        // Contact Details
        if (!contact.phoneNumber) {
            newErrors.phoneNumber = 'שדה מספר הטלפון לא יכול להישאר ריק';
        }
        if (!contact.mobileNumber) {
            newErrors.mobileNumber = 'שדה מספר הנייד לא יכול להישאר ריק';
        }
        if (!contact.email) {
            newErrors.email = 'שדה הדוא"ל לא יכול להישאר ריק';
        }
        // Business Details
        if (!businessName) {
            newErrors.businessName = 'שדה שם עסק הינו חובה';
        }
        if (!zenatName) {
            newErrors.zenatName = 'שם מסחרי הינו חובה';
        }
        if (!businessId) {
            newErrors.businessId = 'שדה זה הינו חובה';
        }
        if (!businessLogo) {
            newErrors.businessLogo = 'שדה הלוגו לא יכול להישאר ריק';
        }

        if (!businessSignature) {
            newErrors.businessSignature = 'שדה החתימה לא יכול להישאר ריק';
        }
        if (!billingEmail) {
            newErrors.billingEmail = 'שדה הדוא"ל לחשבוניות לא יכול להישאר ריק';
        }
        setErrors(newErrors);
        return newErrors; // return the newErrors object
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleAddDialogOpen}>
                הוספת עסק חדש +
            </Button>

            <Dialog open={openAddDialog} fullWidth maxWidth="md">
                <DialogContent>
                    <div className="form-header">
                        <div className="form-header-right">
                            <h1>טופס הצטרפות</h1>
                        </div>
                        <div className='form-header-left'>
                            <div className='exit-no-select'
                             onClick={() => {
                                handleAddDialogClose();
                                clearFormData();
                            }}
                            >
                                X
                            </div>
                        </div>
                    </div>
                    <h3 className="h3-title">אנא מלא את כל הפרטים הנדרשים</h3>
                    <h4 className="small-titles">פרטי עסק</h4>
                    <div className="fst-row">
                        <div className="input-text">
                            <select
                                className={`dropdown ${errors.businessType ? 'error-class' : ''}`}
                                value={businessType}
                                onChange={handleChange}
                                required>
                                <option value="">בחר מהרשימה</option>
                                <option value={'INDIVIDUAL_FREE_TAX'}>עוסק פטור</option>
                                <option value={'INDIVIDUAL'}>עוסק מורשה / חברה בע"מ</option>
                            </select>
                            {errors.businessType && <div className="error-text">{errors.businessType}</div>}
                        </div>
                        {/* <div className="input-text">
                            <select
                                className={`dropdown ${errors.businessVisibility ? 'error-class' : ''}`}
                                value={businessVisibility}
                                onChange={handleVisibilityChange}
                                required>
                                <option value="">בחר מהרשימה</option>
                                <option value={'COMING_SOON'}>בקרוב</option>
                                <option value={'ACTIVE'}>מופיע באפליקציה !</option>
                                <option value={'DISABLED'}>לא מופיע</option>
                            </select>
                            {errors.businessVisibility && <div className="error-text">{errors.businessVisibility}</div>}
                        </div> */}
                        {/* <div className="input-text">
                            <select
                                className={`dropdown ${errors.businessLanguage ? 'error-class' : ''}`}
                                value={businessLanguage}
                                onChange={handleLanguageChange}
                                required>
                                <option value="">בחר מהרשימה</option>
                                <option value={'ARABIC'}>العربية</option>
                                <option value={'HEBREW'}>עברית</option>
                                <option value={'ENGLISH'}>English</option>
                            </select>
                            {errors.businessLanguage && <div className="error-text">{errors.businessLanguage}</div>}
                        </div> */}
                        <div className="input-text">
                            <TextField
                                className="textField1"
                                label="שם עסק"
                                onChange={(e: any) => setBusinessName(e.target.value)}
                                required
                                error={!!errors.businessName}
                                helperText={errors.businessName}
                            />
                        </div>
                        <div className="input-text">
                            <TextField
                                className="textField1"
                                label="שם עסק מסחרי"
                                onChange={(e: any) => setZenatName(e.target.value)}
                                required
                                error={!!errors.zenatName}
                                helperText={errors.zenatName}
                            />
                        </div>
                        <div className="input-text">
                            <TextField
                                className="textField1"
                                label="ח.פ. /  ת.ז."
                                onChange={(e: any) => setBusinessId(e.target.value)}
                                required
                                error={!!errors.businessId}
                                helperText={errors.businessId}
                            />
                        </div>
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="חתימה URL"
                            onChange={(e: any) => setBusinessSignature(e.target.value)}
                            style={{ width: '500px' }}
                            required
                            error={!!errors.businessSignature}
                            helperText={errors.businessSignature}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="LOGO URL"
                            onChange={(e: any) => setBusinessLogo(e.target.value)}
                            style={{ width: '500px' }}
                            required
                            error={!!errors.businessLogo}
                            helperText={errors.businessLogo}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="Slogan"
                            onChange={(e: any) => setBusinessSlogan(e.target.value)}
                            style={{ width: '800px' }}
                        />
                    </div>
                    <h4 className="small-titles">פרטי יצירת קשר</h4>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="אתר אינטרנט"
                            onChange={handleContactChange('websiteUrl')}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            style={{ width: '280px' }}
                            label="דוא''ל לקבלת חשבוניות"
                            onChange={(e: any) => setBillingEmail(e.target.value)}
                            required
                            error={!!errors.billingEmail}
                            helperText={errors.billingEmail}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            style={{ width: '280px' }}
                            label="דוא''ל יצירת קשר"
                            onChange={handleContactChange('email')}
                            required
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label=" טלפון אישי להתראות מערכת"
                            onChange={handleContactChange('mobileNumber')}
                            required
                            error={!!errors.mobileNumber}
                            helperText={errors.mobileNumber}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="טלפון עסקי"
                            onChange={handleContactChange('phoneNumber')}
                            required
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber}
                        />
                    </div>
                    <h4 className="small-titles">כתובת</h4>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="עיר"
                            onChange={handleAddressChange('city')}
                            required
                            error={!!errors.city}
                            helperText={errors.city}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="רחוב"
                            onChange={handleAddressChange('street')}
                            required
                            error={!!errors.street}
                            helperText={errors.street}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="מיקוד"
                            onChange={handleAddressChange('zipcode')}
                            required
                            error={!!errors.zipcode}
                            helperText={errors.zipcode}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="עיר באנגלית"
                            onChange={handleAddressChange('cityEnglish')}
                            required
                            error={!!errors.cityEnglish}
                            helperText={errors.cityEnglish}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="רחוב באנגלית"
                            onChange={handleAddressChange('streetEnglish')}
                            required
                            error={!!errors.streetEnglish}
                            helperText={errors.streetEnglish}
                        />
                    </div>
                    <h4 className="small-titles">פרטי בנק</h4>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="קוד בנק"
                            onChange={handleBankDetailsChange('bank')}
                            required
                            error={!!errors.bank}
                            helperText={errors.bank}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="מס סניף"
                            onChange={handleBankDetailsChange('branch')}
                            required
                            error={!!errors.branch}
                            helperText={errors.branch}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            className="textField1"
                            label="מס חשבון"
                            onChange={handleBankDetailsChange('accountNumber')}
                            required
                            error={!!errors.accountNumber}
                            helperText={errors.accountNumber}
                        />
                    </div>

                    {/*   + bus lang + boolean deleted + bank details */}
                    {/* Add more form fields as needed */}
                    <div className="action-buttons">
                        <Button
                            onClick={() => {
                                handleAddDialogClose();
                                clearFormData();
                            }}
                            color="error">
                            בטל
                        </Button>
                        <Button
                            onClick={() => {
                                const validationErrors = validateForm(); // capture the return value
                                console.log('validationErrors', validationErrors)
                                // check the size of validationErrors rather than state
                                if (Object.keys(validationErrors).length === 0) {
                                    addBusiness();
                                }
                            }}
                            color="success"
                            variant="contained">
                            הוספה
                        </Button>
                    </div>
                </DialogContent>
                <div className="dialog-bottom">** נא לבדוק היטב את כל הנתונים לפני לחיצה על הוספה</div>
            </Dialog>
        </div>
    );
};

export default AddBusinessComp;
