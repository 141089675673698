import { NavLink, useNavigate } from 'react-router-dom';
import './sidebar.component.css';
import packageJson from '../../../package.json';
import get from 'lodash-es/get';

export const Sidebar = ({ visible }) => {
    const navigate = useNavigate();

    const logOut = () => {
        localStorage.removeItem('jwt');
        navigate('/login');
    };

    return (
        <div className={`sidebar${visible ? ' visible' : ''}`}>
            <nav>
                <ul>
                    <li>
                        <NavLink to="/">מבט על</NavLink>
                    </li>
                    <li>
                        <NavLink to="/tools">כלים להעברת נתונים</NavLink>
                    </li>
                    <li>
                        <NavLink to="/emv-tools">מכשיר סליקה</NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage-businesses">ניהול עסקים</NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage-businesses-quickly-invoice"> עסקים חשבון מהיר</NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage-photos">ניהול תמונות</NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage-coupons">ניהול קופונים</NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage-bookings">ניהול תורים</NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage-motzarim-categories">ניהול קטגוריות מוצרים</NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage-services-categories">ניהול קטגוריות שוברים</NavLink>
                    </li>

                    <li>
                        <NavLink to="/manage-hashavshevet">ניהול חשבשבת</NavLink>
                    </li>
                    <li>
                        <NavLink to="/masav-generator">הפקת קבצי מס"ב</NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage-balances">ניהול יתרות</NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage-blocked-users">חסימות משתמשים</NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage-phone-versions">גרסאות אפליקציה</NavLink>
                    </li>
                    <li>
                        <NavLink to="/map-radius">מפה עם רדיוס</NavLink>
                    </li>
                    <li>
                        <NavLink to="/partners">Partners</NavLink>
                    </li>
                </ul>
            </nav>
            <div className="version-text">
                גירסה {packageJson.version} {get(packageJson, 'versionName')}
            </div>
            <button onClick={logOut}>יציאה</button>
        </div>
    );
};
