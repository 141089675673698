//import PdfToXlsxV2 from "./components/pdf2xlsxChecks"
import { ChecksFromXlsx } from "./components/checks-xlsx-organize"
import { ChecksOcr } from "./components/checks-ocr"
import XlsxToJsonConverter from "../documents/components/xlsxtojson-cheks"

export const ChecksImportSection = () => {

    return (
        <>
            <h2 className="mt-2">העברת צ'קים</h2>
            <div className="box">
                <h3>עיבוד צילומי מסך צ'קים</h3>
                <h5>חייב להיות אותו טווח תאריכים של קובץ האקסל שנעשה מ פי.די.אף</h5>
                <h5>will be exported as checks Json to use below </h5>
                <h5>Upload All images </h5>
                <ChecksOcr />
            </div>
            <div className="box">
                <h3>XLSXעיבוד צ'קים</h3>
                <h5>יש להוריד קובץ פי.די.אף שמכיל רשימת צ'קים מתוכנת אופטימוס,
                    לעשות העתק הדבק לכל התוכן בקובץ אקסל בעמוד א ואז להעלות
                    אותו לכאן ואחרי הפעלת פייתון באותו טווח תאריכים להלעות לכאן את ה ג'ייסון</h5>
                <ChecksFromXlsx />
            </div>
            <div className="box">
                <h3>המרת קובץ אחרון לJSON</h3>
                <XlsxToJsonConverter/>
            </div>
            {/* <div className="box">
                <h3>Optimus  צ'קים PDF List to xlsx Cleaned**NOT WORKING WITH CHECK HAS STATUS **</h3>
                <PdfToXlsxV2 />
            </div> */}
        </>
    )
}