import React, { useState } from 'react';

import { ActiveSeriesesProccessor } from './components/proccess-serieses.component';
import { processSeriesesTwoFiles } from './components/proccess-two-files-serieses.component';


export const ProcessActiveSeriesesSection = () => {
  const [file1, setFile1] = useState<File | null>(null);
  const [file2, setFile2] = useState<File | null>(null);
  const [message, setMessage] = useState('');

  

  const { handleFileInputChange, downloadCleanedFile } = ActiveSeriesesProccessor();

  const handleFile1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) setFile1(e.target.files[0]);
  };

  const handleFile2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) setFile2(e.target.files[0]);
  };

  const handleProcessData = async () => {
    if (!file1 || !file2) {
      setMessage('Please upload both files.');
      return;
    }

    try {
      await processSeriesesTwoFiles(file1, file2);
      setMessage('עיבוד הקבצים הסתיים בהצלחה.');
    } catch (err) {
      setMessage('בעיה בעיבוד קבצים, נא לנסות שוב.');
      console.error(err);
    }
  };

  return (
    <>
      <h2 className="mt-2">עיבוד קובץ מנויים בפעילות</h2>
      <div className="box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'  }}>
        <h4>נא להעלות לכאן את קובץ ה XLSX שהופק מתוכנת אופטימוס</h4>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={(e) => handleFileInputChange(e)}
        />
        <button onClick={downloadCleanedFile}>Download Cleaned File</button>
      </div>
      <div className="box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'  }}>
        <h4>נא להעלות לכאן את שני הקבצים הנדרשים</h4>
        <label htmlFor="file1">קובץ מנויים בפעילות מעובד מלמעלה:</label>
        <input type="file" id="file1" accept=".xlsx, .xls" onChange={handleFile1Change} />
        <label htmlFor="file2">קובץ ניצולים מנויים מאופטימוס:</label>
        <input type="file" id="file2" accept=".xlsx, .xls" onChange={handleFile2Change} />
        <button onClick={handleProcessData}>Process Data</button>
        {message && <p>{message}</p>}
      </div>
    </>
  );
};
