import './emv-tools.css';

const EmvTools = () => {
    const MadrechHakama1 =
        'https://booklee.s3.eu-central-1.amazonaws.com/mix/%D7%94%D7%A7%D7%9E%D7%94+%D7%9C+P400_V_1.9+.pdf';
    const MadrechHakama2 =
        'https://booklee.s3.eu-central-1.amazonaws.com/mix/%D7%94%D7%93%D7%A8%D7%9B%D7%AA+%D7%AA%D7%A4%D7%A8%D7%99%D7%98+%D7%98%D7%9B%D7%A0%D7%90%D7%99+P400+V1.1.pdf';
    const MadrechHakamapng1 =
        'https://booklee.s3.eu-central-1.amazonaws.com/mix/%D7%9E%D7%93%D7%A8%D7%99%D7%9A+%D7%94%D7%A7%D7%9E%D7%94.png';
    const MadrechHakamapng2 =
        'https://booklee.s3.eu-central-1.amazonaws.com/mix/%D7%9E%D7%93%D7%A8%D7%99%D7%9A+%D7%94%D7%A7%D7%9E%D7%942.png';
    const firewallImg = 'https://booklee.s3.eu-central-1.amazonaws.com/mix/FIREWALL.jpg';

    const p400steps = 'https://booklee.s3.eu-central-1.amazonaws.com/mix/scrnsht.png';

    const p400Word = 'https://docs.google.com/document/d/1iFYbRVNSy8c9qMf52Tp2HCnsT_dMDgArl0hICLErog0/edit';

    const copyFirewallIMG = async () => {
        const image = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        image.crossOrigin = 'anonymous'; // This won't work if the server doesn't allow CORS
        image.src = firewallImg;

        image.onload = async () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);

            canvas.toBlob(async blob => {
                try {
                    await navigator.clipboard.write([
                        new ClipboardItem({
                            [blob.type]: blob,
                        }),
                    ]);
                    alert('תמונה הועתקה ל-  CLIPBOARD בהצלחה ! ! ');
                } catch (err) {
                    console.error(err);
                    alert('Failed to copy image');
                }
            }, 'image/png');
        };
    };

    const downloadMadrechHakama1 = () => {
        window.open(MadrechHakama1, '_blank');
    };
    const downloadMadrechHakama2 = () => {
        window.open(MadrechHakama2, '_blank');
    };

    const openP400Steps = () => {
        window.open(p400Word, '_blank');
    };

    return (
        <div>
            <h1>P400+</h1>
            <div className="guides">
                <div className="header">
                    <h1>מדריכים</h1>
                </div>
                <div className="covers-all">
                    <div className="photo-cover" onClick={downloadMadrechHakama1} no-select>
                        <img className="pdf-cover" src={MadrechHakamapng1}></img>
                    </div>
                    <div className="photo-cover" onClick={downloadMadrechHakama2} no-select>
                        <img className="pdf-cover" src={MadrechHakamapng2}></img>
                    </div>
                </div>
            </div>
            <div className="firewall-sec">
                <div className="header">
                    <h1>הגדרות FIREWALL</h1>
                </div>
                <div className="firewall" onClick={copyFirewallIMG}>
                    <img src={firewallImg}></img>
                </div>
            </div>
            <div className="steps" >
                <div className="steps-cover" onClick={openP400Steps}>
                    <h1>התחברות עם המסופון</h1>
                    <img src={p400steps}></img>
                </div>
            </div>
        </div>
    );
};

export default EmvTools;
