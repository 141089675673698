import axios from 'axios';
import { environment } from '../config/environment.config';

const API_BASE_URL = `${environment.service_panel}/subcategorie`;

const SubCategoryServices = {
  getAll: () => axios.get(`${API_BASE_URL}`),
  getByCategoryId: (categoryId) => axios.get(`${API_BASE_URL}/byCategoryId`, { params: { categoryId } }),
  getById: (id) => axios.get(`${API_BASE_URL}/${id}`),
  create: (data) => axios.post(`${API_BASE_URL}`, data),
  update: (id, data) => axios.post(`${API_BASE_URL}/${id}`, data),
  delete: (id) => axios.delete(`${API_BASE_URL}/${id}`),

};

export default SubCategoryServices;
