import { useEffect } from 'react';

function useHandleAddBusinessResponses(response, error, setOpenAddDialog): void {
    useEffect(() => {
        if (error) {
            alert('נתונים שגויים ! ! ! ! !');
            console.error('Error creating business: ', error);
        }
    }, [error]);

    useEffect(() => {
        if (response?.data?.data?.business?._id) {
            alert('העסק נוסף בהצלחה!');
            setOpenAddDialog(false); // Close the dialog
        }
    }, [response, setOpenAddDialog]);
}

export default useHandleAddBusinessResponses;
