import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const CustomersExcelCleaner = () => {

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const processFile = () => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const json: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // 1. Remove the first 4 rows
      json.splice(0, 3);

      // 2. Remove column A
      json.forEach((row: any) => row.splice(0, 1));

      // 3. Remove specific columns after column A has been removed
      json.forEach((row: any) => {
        row.splice(17, 1); // Remove column R
        row.splice(16, 1); // Remove column Q
        row.splice(12, 1); // Remove column M
        row.splice(11, 1); // Remove column L
        row.splice(9, 1);  // Remove column J
        row.splice(8, 1);  // Remove column I
        row.splice(7, 1);  // Remove column H
        row.splice(6, 1);  // Remove column G
        row.splice(5, 1);  // Remove column F
        row.splice(4, 1);  // Remove column E
        row.splice(2, 1);  // Remove column C
      });

      const nameColumnIndex = json[0].findIndex(cell => cell === 'שם ושם משפחה');

      json[0].splice(nameColumnIndex, 1, 'שם פרטי', 'שם משפחה');

      for (let i = 1; i < json.length; i++) {
        const fullName = json[i][nameColumnIndex];

        if (typeof fullName === 'string') {
          const nameParts = fullName.split(' ');
          const firstName = nameParts.shift() || '';
          const lastName = nameParts.join(' ');
          json[i].splice(nameColumnIndex, 1, firstName, lastName);
        } else {
          json[i].splice(nameColumnIndex, 1, '', '');
        }
      }


      // Find the index of the column with the first cell containing 'טלפון נייד'
      const targetColumnIndex = json[0].findIndex(cell => cell === 'טלפון נייד');

      // Initialize a Set to store unique values found in the 'טלפון נייד' column
      const uniquePhoneNumbers = new Set();

      // Filter the rows to only include rows where the cell in that column is not empty, has exactly 10 numbers, and is not a duplicate
      const filteredJson = json.filter(row => {
        const cellValue = row[targetColumnIndex];
        // const validCell = cellValue !== undefined && cellValue !== null && cellValue !== '' && cellValue.toString().length === 10;
        const validCell = cellValue !== undefined && cellValue !== null && cellValue !== '';

        // If the cell is valid and not already in the set, add it to the set and keep the row
        if (validCell && !uniquePhoneNumbers.has(cellValue)) {
          uniquePhoneNumbers.add(cellValue);
          return true;
        }

        // Otherwise, discard the row
        return false;
      });

      // Save the modified data as a new Excel file
      const modifiedSheet = XLSX.utils.aoa_to_sheet(filteredJson);
      const modifiedWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(modifiedWorkbook, modifiedSheet, 'Sheet1');
      const modifiedBuffer = XLSX.write(modifiedWorkbook, { type: 'array', bookType: 'xlsx' });
      saveAs(new Blob([modifiedBuffer], { type: 'application/octet-stream' }), 'Customer_modified_data_Until_01021970.xlsx');
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div className="clean-optimus-file">
      <h3>Save the file in business Folder Drive with the word Until TODAY DATE </h3>
      <input type="file" accept=".xlsx" onChange={handleFileChange} />
      <br />
      <button onClick={processFile}>Process Excel File</button>
    </div>
  );
}
