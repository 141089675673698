import  { useState } from 'react';
import * as XLSX from 'xlsx';

export const ActiveSeriesesProccessor = () => {
    const [fileData, setFileData] = useState(null);
    

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        Array.from(files).forEach((file: any) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
    
            // Process the workbook and update the file data state
            processWorkbook(workbook);
          };
    
          reader.readAsBinaryString(file);
        });
      };
      const processWorkbook = (workbook) => {
        const originalWorksheetName = workbook.SheetNames[0];
        const originalWorksheet = workbook.Sheets[originalWorksheetName];
      
        // Calculate the range of the original worksheet
        const originalRange = XLSX.utils.decode_range(originalWorksheet['!ref']);
      
        // Create a new worksheet
        const newWorksheet = {};
      
        // Iterate through the original worksheet
        for (let R = 4; R <= originalRange.e.r; ++R) {
          for (let C = 1; C <= 4; ++C) { // Change the loop condition to include columns B, C, D, and E
            const originalAddress = XLSX.utils.encode_cell({ c: C, r: R });
            const newAddress = XLSX.utils.encode_cell({ c: C - 1, r: R - 4 });
      
            if (originalWorksheet[originalAddress]) {
              newWorksheet[newAddress] = originalWorksheet[originalAddress];
            }
          }
        }
      
        // Set the new worksheet's range
        newWorksheet['!ref'] = XLSX.utils.encode_range({
          s: { c: 0, r: 0 },
          e: { c: 3, r: originalRange.e.r - 4 }, // Change the end column to 3
        });
      
        // Remove the header word "טלפון" from the output file
        delete newWorksheet['D1'];
      
        // Update the workbook with the new worksheet
        workbook.SheetNames[0] = 'Cleaned Data';
        workbook.Sheets['Cleaned Data'] = newWorksheet;
        delete workbook.Sheets[originalWorksheetName];
      
        // Update file data state
        setFileData(workbook);

         // Call the splitAndDuplicateRows function
        splitAndDuplicateRows(workbook);

         // Update file data state
        setFileData(workbook);
      
      };

      const splitAndDuplicateRows = (workbook) => {
        const cleanedWorksheetName = 'Cleaned Data';
        const cleanedWorksheet = workbook.Sheets[cleanedWorksheetName];
        const cleanedRange = XLSX.utils.decode_range(cleanedWorksheet['!ref']);
    
        const newWorksheet = {};
        let newRow = 0;
    
        for (let R = 0; R <= cleanedRange.e.r; ++R) {
          const cellA = XLSX.utils.encode_cell({ c: 0, r: R });
          const cellB = XLSX.utils.encode_cell({ c: 1, r: R });
          const cellC = XLSX.utils.encode_cell({ c: 2, r: R });
    
          const cellBValue = cleanedWorksheet[cellB]?.v;
    
          if (cellBValue && cellBValue.includes(',')) {
            const splitValues = cellBValue.split(',');
    
            for (const value of splitValues) {
              const newRowA = XLSX.utils.encode_cell({ c: 0, r: newRow });
              const newRowB = XLSX.utils.encode_cell({ c: 1, r: newRow });
              const newRowC = XLSX.utils.encode_cell({ c: 2, r: newRow });
    
              newWorksheet[newRowA] = cleanedWorksheet[cellA];
              newWorksheet[newRowB] = { ...cleanedWorksheet[cellB], v: value };
              newWorksheet[newRowC] = cleanedWorksheet[cellC];
    
              newRow++;
            }
          } else {
            const newRowA = XLSX.utils.encode_cell({ c: 0, r: newRow });
            const newRowB = XLSX.utils.encode_cell({ c: 1, r: newRow });
            const newRowC = XLSX.utils.encode_cell({ c: 2, r: newRow });
    
            newWorksheet[newRowA] = cleanedWorksheet[cellA];
            newWorksheet[newRowB] = cleanedWorksheet[cellB];
            newWorksheet[newRowC] = cleanedWorksheet[cellC];
    
            newRow++;
          }
        }
    
        newWorksheet['!ref'] = XLSX.utils.encode_range({
          s: { c: 0, r: 0 },
          e: { c: 2, r: newRow - 1 },
        });
    
        workbook.Sheets[cleanedWorksheetName] = newWorksheet;
    
        setFileData(workbook);
      };
    
      const downloadCleanedFile = () => {
        if (!fileData) return;
    
        const newFileName = 'Active_Serieses_cleaned-file.xlsx';
        XLSX.writeFile(fileData, newFileName);
      };
    
      return { handleFileInputChange, downloadCleanedFile };
    };


