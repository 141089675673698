interface IUpdateMachineFormInput {
    key: string;
    label: string;
    disabled: boolean;
    note?: string;
};

export const UPDATE_MACHINE_FORM_INPUTS: IUpdateMachineFormInput[] = [
    {
        key: 'id',
        label: 'MachineId',
        disabled: false,
    },
    {
        key: 'cashier._id',
        label: 'CashierId',
        disabled: true,
    },
    {
        key: 'ChainId',
        label: 'ChainId',
        disabled: false,
    },
    {
        key: 'LaneId',
        label: 'LaneId',
        disabled: false,
    },
    {
        key: 'StoreId',
        label: 'StoreId',
        disabled: false,
    },
    {
        key: 'IpAddress',
        label: 'IpAddress',
        disabled: false,
    },
    {
        key: 'mxLogPath',
        label: 'mxLogPath',
        disabled: false,
        note: 'For example: C:\\\\Users\\\\USER\\\\AppData',
    },
    {
        key: 'secDBPath',
        label: 'secDBPath',
        disabled: false,
        note: 'For example: C:\\\\Users\\\\USER\\\\AppData\\\\Sec.DB',
    },
];