import MotzaremCategoriesService from "../../common/utils/MotzaremCategoriesService";
import { useState, useEffect } from "react";

const MotzaremCategoriesPanel = () => {
  const [categories, setCategories] = useState([]);

  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState(null);

  const [newCategoryType, setNewCategoryType] = useState("");
  const [newParentCategory, setNewParentCategory] = useState(null);

  const [arabicName, setArabicName] = useState('');
  const [hebrewName, setHebrewName] = useState('');
  const [englishName, setEnglishName] = useState('');

  const fetchCategories = async () => {
    const allCategories = await MotzaremCategoriesService.getAllCategories();
    setCategories(allCategories);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedSubSubCategory) {
      setArabicName(selectedSubSubCategory.name.ar);
      setHebrewName(selectedSubSubCategory.name.he);
      setEnglishName(selectedSubSubCategory.name.en);
    } else if (selectedSubCategory) {
      setArabicName(selectedSubCategory.name.ar);
      setHebrewName(selectedSubCategory.name.he);
      setEnglishName(selectedSubCategory.name.en);
    } else if (selectedCategory) {
      setArabicName(selectedCategory.name.ar);
      setHebrewName(selectedCategory.name.he);
      setEnglishName(selectedCategory.name.en);
    } else {
      setArabicName('');
      setHebrewName('');
      setEnglishName('');
    }
  }, [selectedCategory, selectedSubCategory, selectedSubSubCategory]);


  const handleCategoryChange = async (e) => {
    const categoryId = e.target.value;
    const selectedCategory = categories.find(
      (category) => category._id === categoryId
    );

    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
      setSelectedSubCategory(null);
      setSelectedSubSubCategory(null);
      setArabicName('');
      setHebrewName('');
      setEnglishName('');

      // Fetch updated sub-categories for the selected main category
      try {
        const fetchedSubCategories = await MotzaremCategoriesService.getSubCategories(categoryId);
        setSubCategories(fetchedSubCategories);
      } catch (error) {
        console.error("Failed to fetch sub-categories:", error);
        setSubCategories([]);
      }
    } else {
      // Handle the case when the "Select a category" option is selected
      setSelectedCategory(null);
      setSelectedSubCategory(null);
      setSelectedSubSubCategory(null);
      setArabicName('');
      setHebrewName('');
      setEnglishName('');
      setSubCategories([]);
    }
  };


  const handleSubCategoryChange = async (e) => {
    const subCategoryId = e.target.value;
    const newSubCategory = subCategories.find(
      (subCategory) => subCategory._id === subCategoryId
    );

    if (newSubCategory) {
      setSelectedSubCategory(newSubCategory);
      setSelectedSubSubCategory(null);
      setArabicName(newSubCategory.name.ar);
      setHebrewName(newSubCategory.name.he);
      setEnglishName(newSubCategory.name.en);

      // Fetch updated sub-sub-categories for the selected sub-category
      try {
        const fetchedSubSubCategories = await MotzaremCategoriesService.getSubSubCategories(subCategoryId);
        setSubSubCategories(fetchedSubSubCategories);
      } catch (error) {
        console.error("Failed to fetch sub-sub-categories:", error);
        setSubSubCategories([]);
      }
    } else {
      // Handle the case when the "Select a sub-category" option is selected
      setSelectedSubCategory(null);
      setSelectedSubSubCategory(null);
      setArabicName('');
      setHebrewName('');
      setEnglishName('');
    }
  };

  const handleSubSubCategoryChange = (e) => {
    const subSubCategoryId = e.target.value;
    setSelectedSubSubCategory(
      subSubCategories.find(
        (subSubCategory) => subSubCategory._id === subSubCategoryId
      )
    );
  };

  useEffect(() => {
    if (selectedCategory) {
      setSubCategories(
        categories.filter(
          (e) => e && e.parentCategory === selectedCategory._id
        )
      );
    } else {
      setSubCategories([]);
    }
  }, [selectedCategory, categories]);

  useEffect(() => {
    if (selectedSubCategory) {
      setSubSubCategories(
        categories.filter(
          (e) => e && e.parentCategory === selectedSubCategory._id
        )
      );
    } else {
      setSubSubCategories([]);
    }
  }, [selectedSubCategory, categories]);

  const updateCategoryNames = async () => {
    const confirmation = window.confirm("האם אתה בטוח שברצונך לעדכן את הקטגוריה הזו?");

    if (!confirmation) {
      return;
    }
    let selectedType;
    let selectedId;

    if (selectedSubSubCategory) {
      selectedType = 'subSubCategory';
      selectedId = selectedSubSubCategory._id;
    } else if (selectedSubCategory) {
      selectedType = 'subCategory';
      selectedId = selectedSubCategory._id;
    } else {
      selectedType = 'category';
      selectedId = selectedCategory._id;
    }

    const updatedCategory = {
      [selectedType]: {
        ...selectedCategory,
        ...selectedSubCategory,
        ...selectedSubSubCategory,
        name: {
          ar: arabicName,
          he: hebrewName,
          en: englishName,
        },
      },
    };

    try {
      await MotzaremCategoriesService.updateCategory(selectedId, updatedCategory[selectedType]);
      console.log('Updated category:', updatedCategory[selectedType]);
      // Update the categories state with the updated category
      setCategories(categories.map(cat => cat._id === updatedCategory[selectedType]._id ? updatedCategory[selectedType] : cat));
      if (selectedType === 'category') {
        setSelectedCategory(updatedCategory[selectedType]);
      } else if (selectedType === 'subCategory') {
        setSelectedSubCategory(updatedCategory[selectedType]);
      } else {
        setSelectedSubSubCategory(updatedCategory[selectedType]);
      }
    } catch (error) {
      console.error('Failed to update category:', error);
    }
  };

  const addCategory = async () => {
    if (!newCategoryType || (newCategoryType !== "CATEGORY" && !newParentCategory)) {
      window.alert("נא לבחור סוג קטגוריה וקטגוריית על.");
      return;
    }
    const newCategory = {
      name: {
        ar: arabicName,
        he: hebrewName,
        en: englishName,
      },
      type: newCategoryType,
    };
    if (newCategoryType !== "CATEGORY") {
      newCategory.parentCategory = newParentCategory;
    }
    console.log("Adding new category:", newCategory);

    try {
      const response = await MotzaremCategoriesService.addCategory(newCategory);
      console.log("Response from API:", response); // Add this line
      const addedCategory = response; // Change this line
      console.log("Added new category:", addedCategory);
      setCategories([...categories, addedCategory]);

      // Update the subCategories or subSubCategories state after adding the new category
      if (newCategoryType === 'SUB_CATEGORY') {
        setSubCategories([...subCategories, addedCategory]);
      } else if (newCategoryType === 'SUB_SUB_CATEGORY') {
        setSubSubCategories([...subSubCategories, addedCategory]);
      }
      // Alert the user and clear input elements and dropdowns
      window.alert("הקטגוריה נוספה בהצלחה.");
      setArabicName("");
      setHebrewName("");
      setEnglishName("");
      setNewCategoryType("");
      setNewParentCategory("");
      setSelectedCategory(null);
      setSelectedSubCategory(null);
      setSelectedSubSubCategory(null);
    } catch (error) {
      console.error("Failed to add category:", error);
    }
  };

  const handleNewCategoryTypeChange = (e) => {
    setNewCategoryType(e.target.value);
    setNewParentCategory("");
  };

  const handleNewParentCategoryChange = (event) => {
    const selectedParentId = event.target.value;
    if (selectedParentId) {
      setNewParentCategory(selectedParentId);
    } else {
      setNewParentCategory(null);
    }
  };

  const hasChildCategories = (parentId) => {
    return categories.some((category) => category.parentCategory && category.parentCategory === parentId);
  };

  const deleteCategory = async () => {
    const confirmation = window.confirm("האם אתה בטוח שברצונך למחוק את הקטגוריה הזו?");

    if (!confirmation) {
      return;
    }
    let categoryIdToDelete;

    if (selectedSubSubCategory) {
      categoryIdToDelete = selectedSubSubCategory._id;
    } else if (selectedSubCategory) {
      categoryIdToDelete = selectedSubCategory._id;
    } else if (selectedCategory) {
      categoryIdToDelete = selectedCategory._id;
    }

    if (hasChildCategories(categoryIdToDelete)) {
      window.alert("לא ניתן למחוק את הקטגוריה מכיוון שיש לה קטגוריות משנה ! ! ! ! ! !");
      return;
    }

    if (selectedSubSubCategory) {
      try {
        await MotzaremCategoriesService.deleteCategory(
          selectedSubSubCategory._id
        );
        console.log("Deleted sub-sub-category:", selectedSubSubCategory);
        setSubSubCategories(
          subSubCategories.filter((cat) => cat._id !== selectedSubSubCategory._id)
        );
        setSelectedSubSubCategory(null);
      } catch (error) {
        console.error("Failed to delete sub-sub-category:", error);
      }
    } else if (selectedSubCategory) {
      try {
        await MotzaremCategoriesService.deleteCategory(selectedSubCategory._id);
        console.log("Deleted sub-category:", selectedSubCategory);
        setSubCategories(
          subCategories.filter((cat) => cat._id !== selectedSubCategory._id)
        );
        setSelectedSubCategory(null);
        setSubSubCategories([]);
      } catch (error) {
        console.error("Failed to delete sub-category:", error);
      }
    } else if (selectedCategory) {
      try {
        await MotzaremCategoriesService.deleteCategory(selectedCategory._id);
        console.log("Deleted category:", selectedCategory);
        setCategories(
          categories.filter((cat) => cat._id !== selectedCategory._id)
        );
        setSelectedCategory(null);
        setSubCategories([]);
        setSubSubCategories([]);
      } catch (error) {
        console.error("Failed to delete category:", error);
      }
    }
    try {
      const fetchedCategories = await MotzaremCategoriesService.getAllCategories();
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Failed to fetch categories after deletion:", error);
    }
  };

  return (
    <div>
      <h1>ניהול קטגוריות מוצרים</h1>

      <label htmlFor="category">קטגוריה:</label>
      <select
        id="category"
        value={selectedCategory?._id || ""}
        onChange={handleCategoryChange}
      >
        <option value="">בחר מהרשימה</option>
        {categories
          .filter((category) => category && category.type === "CATEGORY")
          .map((category) => (
            <option key={category._id} value={category._id}>
              {category.name.he}
            </option>
          ))}
      </select>
      <br />

      <label htmlFor="sub-category">תת-קטגוריה:</label>
      <select
        id="sub-category"
        value={selectedSubCategory?._id || ""}
        onChange={handleSubCategoryChange}
        disabled={!selectedCategory}
      >
        <option value="">בחר מהרשימה</option>
        {subCategories.map((subCategory) => (
          <option key={subCategory._id} value={subCategory._id}>
            {subCategory.name.he}
          </option>
        ))}
      </select>
      <br />

      <label htmlFor="sub-sub-category">תת-תת-קטגוריה:</label>
      <select
        id="sub-sub-category"
        value={selectedSubSubCategory?._id || ""}
        onChange={handleSubSubCategoryChange}
        disabled={!selectedSubCategory}
      >
        <option value="">בחר מהרשימה</option>
        {subSubCategories.map((subSubCategory) => (
          <option key={subSubCategory._id} value={subSubCategory._id}>
            {subSubCategory.name.he}
          </option>
        ))}
      </select>
      <br />
      <h2>פרטי קטגוריה נבחרה</h2>
      {selectedCategory || selectedSubCategory || selectedSubSubCategory ? (
        <div>
          <p>
            <strong>الاسم (Arabic):</strong>
            <input
              type="text"
              value={arabicName}
              onChange={(e) => setArabicName(e.target.value)}
              style={{width: '350px'}}
            />
          </p>
          <p>
            <strong>שם (Hebrew):</strong>
            <input
              type="text"
              value={hebrewName}
              onChange={(e) => setHebrewName(e.target.value)}
              style={{width: '350px'}}
            />
          </p>
          <p>
            <strong>שם (English):</strong>
            <input
              type="text"
              value={englishName}
              onChange={(e) => setEnglishName(e.target.value)}
              style={{width: '350px'}}
            />
          </p>
          <p>
            <strong>סוג קטגוריה נבחרה:</strong>
            {selectedSubSubCategory
              ? 'תת-תת-קטגוריה'
              : selectedSubCategory
                ? 'תת-קטגוריה'
                : 'קטגוריה'}
          </p>


          <div>סוג:</div>
          <select
            value={newCategoryType}
            onChange={handleNewCategoryTypeChange}
          >
            <option value="">בחר מהרשימה</option>
            <option value="CATEGORY">קטגוריה</option>
            <option value="SUB_CATEGORY">תת-קטגוריה</option>
            <option value="SUB_SUB_CATEGORY">תת-תת-קטגוריה</option>
          </select>
          <br />
          <div>קטגוריית על:</div>
          {newCategoryType !== "CATEGORY" && (
            <select
              value={newParentCategory || ""}
              onChange={handleNewParentCategoryChange}
            >
              <option key="" value="">
                בחר מהרשימה
              </option>
              {categories
                .filter(
                  (category) =>
                    category &&
                    category.type === (newCategoryType === "SUB_CATEGORY" ? "CATEGORY" : "SUB_CATEGORY")
                )
                .map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name.he}
                  </option>
                ))}
            </select>
          )}
          <br />

          <button onClick={updateCategoryNames}>Update עדכן🍌</button>
          <button className="buttonA" onClick={addCategory}>הוסף קטגוריה 🍍</button>
          <button className="buttonD" onClick={deleteCategory}>מחק קטגוריה 🍉</button>

        </div>
      ) : (
        <p>לא נבחרה קטגוריה.</p>
      )}
    </div>
  );
};

export default MotzaremCategoriesPanel;


