import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import './manage-bookings.screen.css';
import { IgnoredBookingRow } from './components';

export const ManageBookingsScreen = () => {
    // const {ignoredBookings, setIgnoredBookings} = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    // Update page and rowsPerPage for pagination
    const handleChangePage = (event, newPage): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event): void => {
        setRowsPerPage(parseInt(event.target.value, 25));
        setPage(0);
    };

    // const filteredBookings = useMemo(() => {
    //     return ignoredBookings
    //         .filter(booking => {
    //             const name = booking?.firstName?.toLowerCase() || '';
    //             const firstName = booking?.details?.firstName?.toLowerCase() || '';
    //             const phoneNumber = booking?.phoneNumber?.toLowerCase() || '';
    //             const searchLower = search.toLowerCase();

    //             return (
    //                 name.includes(searchLower) || firstName.includes(searchLower) || phoneNumber.includes(searchLower)
    //             );
    //         })
    //         .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
    //         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    // }, [ignoredBookings, search]);

    return (
        <Paper>
            <h1>מעקב וניהול תורים לא בוצעו</h1>
            <h3>
                ניהול תורים סומנו כ” לא הופיע” + תורים הפכו להיות בעבר ולא נלקחה עבורם שום פעולה ( הגיע / לא הופיע ) /
                להראות שם עסק / אם עוסק פטור או מורשה להציג שם עוסק + טרנסקריבת לשיחה להתחיל כאילו אני XX רציתי לדעת איך
                היה השירות שלך ביום כוכו’ - כפתורים : ( אישור מצב - ביטול תור / להציע שוב ללקוח או להזמין תור או לשלוח ל
                LEAD עם הודעה להזמין כו כו ? / אפשרות לסמן כהגיע או לשנות מלא הגיע להגיע){' '}
            </h3>

            <div className="table-header">
               
                חפש
                <TextField
                    label="הקלד ח.פ. עסק / טלפון לקוח"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>שם לקוח</TableCell>
                            <TableCell>טלפון</TableCell>
                            <TableCell>ת.רכישה</TableCell>
                            <TableCell>מס' שובר</TableCell>
                            <TableCell>שם שובר</TableCell>
                            <TableCell>מחיר</TableCell>
                            <TableCell>שם עסק מסחרי</TableCell>
                            <TableCell>שם עסק חשבונאי</TableCell>
                            <TableCell>סניף</TableCell>
                            <TableCell>סטטוס </TableCell>
                            <TableCell>ת.תור </TableCell>
                            <TableCell>סומן בתאריך ושעה </TableCell>
                            <TableCell>סמן כהגיע </TableCell>
                            <TableCell>אישור מצב </TableCell>
                            <TableCell>שליחת ליד לעסק </TableCell>
                            <TableCell>לתשלום בעסק</TableCell>
                            <TableCell>שולם באפליקציה PPA</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {filteredBookings.map(booking => (
                         <IgnoredBookingRow   booking={booking} key={`ignored-booking-${booking._id}`} />
                     ))} */}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    {/* <TablePagination
                     rowsPerPageOptions={[25, 50, 100]}
                     component="div"
                     count={filteredCoupons.length}
                     rowsPerPage={rowsPerPage}
                     page={page}
                     onPageChange={handleChangePage}
                     onRowsPerPageChange={handleChangeRowsPerPage}
                     dir="rtl"
                 /> */}
                </Grid>
            </Grid>
        </Paper>
    );
};
