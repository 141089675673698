import { useState } from "react";

export const CheckSerialValidation = props => {
  const [jsonData, setJsonData] = useState([]);
  const [isSerial, setIsSerial] = useState(null);

  function handleFileUpload(event) {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (event: any) => {
      try {
        const jsonObjects = JSON.parse(event.target.result);
        const itemsWithRows = jsonObjects.map((obj, index) => ({ ...obj, row: index + 1 }));
        setJsonData(itemsWithRows);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };

    reader.readAsText(file);
  }

  function handleCheckSerial() {
    const worker = new Worker("./serialCheckerWorker.js");
  
    worker.onmessage = (event) => {
      console.log(event.data, 'sd')
      const result = event.data;
    
      if (result.isSerial) {
        setIsSerial(true);
      } else {
        setIsSerial(false);
        // console.log(
        //   `Serial numbers are not sequential. Missing serial numbers: ${result.missingNumbers.join(", ")}.`
        // );
      }
    };
  
    worker.postMessage(jsonData);
  }

  return (
    <div>
      <input type="file" accept=".json" onChange={handleFileUpload} />
      <button onClick={handleCheckSerial}>Check Serial Numbers</button>
      {isSerial === true && <p>Serial numbers are sequential</p>}
      {isSerial === false && <p>Serial numbers are not sequential</p>}
    </div>
  );
};
