import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { environment } from 'src/common/config';
import useApi from 'src/common/hooks/use-api.hook';
import { Loader } from 'src/components/loader/loader.component';
import useHandleAddWebsiteResponses from 'src/pages/manage-businesses/hooks/handle-add-website-responses.hook';

export const BranchesTab = props => {
    const { currentBusiness } = props;
    const [newWebsiteDetails, setNewWebsiteDetails] = useState({
        websiteName: '',
        phoneNumber: '',
    });

    const {
        response: addWebsiteResponse,
        error: addWebsiteError,
        loading: addWebsiteLoading,
        execute: addWebsiteExecute,
    } = useApi({
        url: `${environment.service_panel}/website`,
        method: 'POST',
    });
    useHandleAddWebsiteResponses(addWebsiteResponse, addWebsiteError, setNewWebsiteDetails);

    const {
        response: getWebsitesResponse,
        error: getWebsitesError,
        loading: getWebsitesLoading,
        execute: getWebsitesExecute,
    } = useApi({
        url: `${environment.service_panel}/website/business/${currentBusiness?._id}`,
        method: 'GET',
    });

    const onChangeWebsiteDetail = type => event => {
        setNewWebsiteDetails(state => ({
            ...state,
            [type]: event.target.value,
        }));
    };

    useEffect(() => {
        getWebsitesExecute();
    }, []);

    const handleAddWebsite = () => {
        if (!currentBusiness?._id || !newWebsiteDetails.phoneNumber || !newWebsiteDetails.websiteName) {
            return console.log('ERROR, request data is wrong');
        }
        addWebsiteExecute({
            data: {
                ...newWebsiteDetails,
                businessId: currentBusiness?._id,
            },
        });
    };

    return (
        <>
            <div>
                <h2>שם עסק {currentBusiness.name}</h2>
            </div>
            <div className="flex mt-2">
                <Button onClick={handleAddWebsite} className="mt-3 mb-3" variant="contained" color="primary">
                    הוסף סניף +
                </Button>
                <div className="flex">
                    <TextField
                        value={newWebsiteDetails.websiteName}
                        onChange={onChangeWebsiteDetail('websiteName')}
                        className="mr-2 ml-1"
                        label="שם סניף"
                    />
                    <TextField
                        value={newWebsiteDetails.phoneNumber}
                        onChange={onChangeWebsiteDetail('phoneNumber')}
                        label="טלפון"
                    />
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>טלפון</TableCell>
                            <TableCell>שם סניף</TableCell>
                            <TableCell>כתובת</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getWebsitesLoading ? (
                            <Loader />
                        ) : (
                            getWebsitesResponse?.data?.map(row => (
                                <TableRow key={row._id}>
                                    <TableCell>{row.phoneNumber}</TableCell>
                                    <TableCell>{row.websiteName}</TableCell>
                                    <TableCell>{row.addressDetails?.addressText?.ar}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
