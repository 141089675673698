import { useState, useEffect } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';

interface Config {
  url: string;
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  headers?: Record<string, string>;
}

interface ExecuteConfig {
  data?: any;
}

interface UseApiReturn {
  response: AxiosResponse<any> | null;
  error: AxiosError | null;
  loading: boolean;
  execute: (config?: ExecuteConfig) => void;
}

/**
 * useApi - A hook to facilitate API calls.
 * @param {Config} config - The configuration for the API call.
 * @returns {UseApiReturn} - The API call state and the function to trigger the API call.
 */
function useApi(config: Config): UseApiReturn {
  const [response, setResponse] = useState<AxiosResponse<any> | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldExecute, setShouldExecute] = useState<boolean>(false);
  const [requestData, setRequestData] = useState<any | null>(null);

  const execute = (executeConfig: ExecuteConfig = {}) => {
    setRequestData(executeConfig.data || null);
    setShouldExecute(true);
  };

  useEffect(() => {
    if (!shouldExecute) return;

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios({ ...config, data: requestData });
        setResponse(response);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
        setShouldExecute(false);
      }
    };

    fetchData();
  }, [shouldExecute]);

  return { response, error, loading, execute };
}

export default useApi;
