import { Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './masav-generator.screen.css';
import { MasavCharge } from './pages/charge-bank/charge-bank.screen';

export const MasavGenerator = () => {
    return (
        <>
            <h1>Masav Generator Screen</h1>
            <h1>Two tabs are requied חיובים + זיכויים</h1>

            <Tabs defaultActiveKey="הפקת קובץ חיובים" id="tools-tabs" className="tools-tabs">
                <Tab eventKey="הפקת קובץ חיובים" title="הפקת קובץ חיובים">
                    <MasavCharge />
                </Tab>
                <Tab eventKey="הפקת קובץ זיכויים" title="הפקת קובץ זיכויים">
                    
                </Tab>
            </Tabs>
        </>
    );
};
