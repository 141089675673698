import { useEffect, useState } from "react";
import { environment } from "../config";
import axios from "axios";

export const useBusinesses = () => {
    const [businesses, setBusinesses] = useState([]);

    const fetchData = async () => {
        try {
            const { data } = await axios.get(`${environment.service_panel}/business`);
            setBusinesses(data?.data?.businesses || []);
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
     
        fetchData();
    }, []);
    
    return { businesses, setBusinesses, fetchData };
}