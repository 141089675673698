import { useEffect, useState } from 'react';
import './login.screen.css';
import { LoginService } from './login.service';
import { LoginSteps } from './login.config';
import { get } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/loader/loader.component';
import { toast } from 'react-toastify';

export const LoginScreen = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [step, setStep] = useState(LoginSteps.SEND_AUTH_CODE);
  const [loading, setLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const loginService = new LoginService();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if( phoneNumber?.length !== 10 ) {
        return toast.error("מספר טלפון שגוי");
      }
      await loginService.sendAuthCode(phoneNumber, false);
      setStep(LoginSteps.VERIFY_AUTH_CODE);
    } catch (error: any) {
      if( error?.response?.data?.code === 'NO_ACCESS' ) {
          toast.error("מספר הטלפון לא שייך לעסק");
      }
      console.log(error);
    }
  };

  const checkAuth = async () => {
    try {
      if( !localStorage.getItem('jwt') ) {
        return;
      }
      const data = await loginService.checkAuth();
      if( get(data, 'user') ) {
        navigate('/');
      }
    } catch(error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      if( loginLoading ) {
        return;
      }
      if( authCode?.length !== 6 ) {
        return toast.error( "קוד אימות שגוי");
      }
      setLoginLoading(true);
      const data = await loginService.verifyAuthCode(phoneNumber, authCode)
      localStorage.setItem('jwt', get(data, 'data.accessToken'));
      navigate('/');
    } catch (error: any) {
      if( error?.response?.data?.code === 'NO_ACCESS' ) {
        toast.error("מספר הטלפון לא שייך לעסק");
      }
      console.log(error);
    } finally {
      setLoginLoading(false);
    }
  };

  const onChangePhone = (e) => setPhoneNumber(e.target.value);
  const onChangeAuthCode = (e) => setAuthCode(e.target.value);

  const content = {
    [LoginSteps.SEND_AUTH_CODE]: (
      <form onSubmit={handleSubmit}>
          <label>
            <input
              className="phone-input"
              type="password"
              value={phoneNumber}
              onChange={onChangePhone}
              placeholder="מספר טלפון"
              required={true}
            />
          </label>
          <button className="login-button" type="submit">שלח קוד אימות</button>
        </form>
    ),
    [LoginSteps.VERIFY_AUTH_CODE]: (
      <form onSubmit={handleLogin}>
        <label>
          <input
            className="phone-input"
            value={authCode}
            onChange={onChangeAuthCode}
            placeholder="קוד אימות"
            required={true}
            type="password"
          />
        </label>
        <button className="login-button" type="submit">כניסה</button>
      </form>
    )
  }

  if( loading ) {
    return (
      <div className='loader-container'>
        <Loader />
      </div>
    )
  }

  return (
    <div className="login-page">
      <div className="login-form-container">
        <h1 className="login-title">זינאת אונליין בע"מ</h1>
        <h2 className="login-subtitle">כניסה לממשק ניהול</h2>
        {content[step]}
      </div>
    </div>
  );
}