import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import useApi from 'src/common/hooks/use-api.hook';
import { environment } from 'src/common/config';
import useHandleUpdateBusinessResponses from './components/update-business-form/update-business-form.hooks';

export const BusinessTab = props => {
    const [canBeSaved, setCanBeSaved] = useState<boolean>(false);
    const {
        response: updateBusinessResponse,
        error: updateBusinessError,
        loading: updateBusinessLoading,
        execute: updateBusinessExecute,
    } = useApi({
        url: `${environment.service_panel}/business/${props.currentBusiness?._id}`,
        method: 'PUT',
    });
    useHandleUpdateBusinessResponses(updateBusinessResponse, updateBusinessError, setCanBeSaved)
    
    const [currentBusiness, setCurrentBusiness] = useState({
        businessType: props.currentBusiness?.businessType,
        // businessVisibility: props.currentBusiness?.businessVisibility,
        // businessLanguage: props.currentBusiness?.businessLanguage,
        name: props.currentBusiness?.name,
        zenatName: props.currentBusiness?.zenatName,
        businessId: props.currentBusiness?.businessId,
        contact: {
            phoneNumber: props.currentBusiness?.contact?.phoneNumber,
        },
        logo: props.currentBusiness?.logo,
        slogan: props.currentBusiness?.slogan,
        bank: {
            bank: props.currentBusiness?.bank?.bank,
            branch: props.currentBusiness?.bank?.branch,
            accountNumber: props.currentBusiness?.bank?.accountNumber,
        },
    });

    const onChangeBusinessDetail = path => event => {
        setCurrentBusiness(state => {
            const newState = { ...state };
            let nestedObject = newState;

            const keys = path.split('.');
            for (let i = 0; i < keys.length - 1; i++) {
                const key = keys[i];
                if (!nestedObject[key]) {
                    nestedObject[key] = {};
                }
                nestedObject = nestedObject[key];
            }

            nestedObject[keys[keys.length - 1]] = event.target.value;

            return newState;
        });
        setCanBeSaved(true);
    };

    const onUpdateBusinessDetails = () => {
        updateBusinessExecute({
            data: currentBusiness,
        });
    }

    return (
        <>
            <div className="business-tab">
                <h4 className="small-titles">פרטי עסק</h4>
                <div className="fst-row">
                    <div className="input-text">
                        <select className="input-text-dropdown">
                            <option value="" selected={currentBusiness?.businessType === ''}>
                                לא נבחר ערך ! ! !
                            </option>
                            <option
                                value="INDIVIDUAL_FREE_TAX"
                                selected={currentBusiness?.businessType === 'INDIVIDUAL_FREE_TAX'}>
                                עוסק פטור
                            </option>

                            <option value="INDIVIDUAL" selected={currentBusiness?.businessType === 'INDIVIDUAL'}>
                                עוסק מורשה / חברה
                            </option>
                        </select>
                    </div>
                    <div className="input-text">
                        <TextField
                            label="שם עסק חשבונאי"
                            value={currentBusiness?.name || ''}
                            onChange={onChangeBusinessDetail('name')}
                        />
                    </div>
                    <div className="input-text">
                        <TextField
                            label="שם מסחרי"
                            value={currentBusiness?.zenatName || ''}
                            onChange={onChangeBusinessDetail('zenatName')}
                        />
                    </div>
                </div>

                <div className="input-text">
                    <TextField
                        label="ע.מ. או ח.פ."
                        value={currentBusiness?.businessId || ''}
                        onChange={onChangeBusinessDetail('businessId')}
                    />
                </div>
                <div className="input-text">
                    <TextField
                        label="טלפון"
                        value={currentBusiness?.contact.phoneNumber || ''}
                        onChange={onChangeBusinessDetail('contact.phoneNumber')}
                    />
                </div>
                <div className="input-text">
                    <TextField
                        label="Logo"
                        value={currentBusiness?.logo || ''}
                        style={{ width: '800px' }}
                        onChange={onChangeBusinessDetail('logo')}
                    />
                </div>
                <div className="input-text">
                    <TextField
                        label="סלוגן"
                        value={currentBusiness?.slogan || ''}
                        style={{ width: '800px' }}
                        onChange={onChangeBusinessDetail('slogan')}
                    />
                </div>
                <h4 className="small-titles">פרטי בנק</h4>
                <div className="input-text">
                    <TextField
                        label="קוד בנק"
                        value={currentBusiness?.bank?.bank || ''}
                        onChange={onChangeBusinessDetail('bank.bank')}
                    />
                </div>
                <div className="input-text">
                    <TextField
                        label="סניף"
                        value={currentBusiness?.bank?.branch || ''}
                        onChange={onChangeBusinessDetail('bank.branch')}
                    />
                </div>
                <div className="input-text">
                    <TextField
                        label="מס חשבון"
                        value={currentBusiness?.bank?.accountNumber || ''}
                        onChange={onChangeBusinessDetail('bank.accountNumber')}
                    />
                </div>
                <Button onClick={onUpdateBusinessDetails} disabled={!canBeSaved} variant='contained'>עדכן נתוני עסק</Button>
                {/* <UpdateBusinessFormComponent /> */}
            </div>
        </>
    );
};
