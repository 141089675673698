import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const CustomersExcelsCompare = () => {
  const [oldFile, setOldFile] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [uniqueContactsFile, setUniqueContactsFile] = useState(null);

  const handleOldFileChange = (e) => {
    setOldFile(e.target.files[0]);
  };

  const handleNewFileChange = (e) => {
    setNewFile(e.target.files[0]);
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleCompare = () => {
    if (oldFile && newFile) {
      const readOldFile = new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          resolve(workbook);
        };
        reader.readAsArrayBuffer(oldFile);
      });

      const readNewFile = new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          resolve(workbook);
        };
        reader.readAsArrayBuffer(newFile);
      });


      Promise.all([readOldFile, readNewFile]).then(([oldWorkbook, newWorkbook]) => {
        const oldWorkbookWithType: any = oldWorkbook;
        const newWorkbookWithType: any = newWorkbook;
        
        const oldSheetName = oldWorkbookWithType.SheetNames[0];
        const newSheetName = newWorkbookWithType.SheetNames[0];

        const oldSheet = oldWorkbookWithType.Sheets[oldSheetName];
        const newSheet = newWorkbookWithType.Sheets[newSheetName];

        const oldContacts = XLSX.utils.sheet_to_json(oldSheet, { header: 1, raw: true });
        const newContacts = XLSX.utils.sheet_to_json(newSheet, { header: 1, raw: true });

        // Get the headers from the original sheet
        const headers: any = newContacts[0];

        const newUniqueContacts = newContacts.filter(newContact => {
          return !oldContacts.some(oldContact => {
            return oldContact[0] === newContact[0] && oldContact[2] === newContact[2];
          });
        });

        const dateColumnIndex = headers.findIndex(header => header === "תאריך לידה");

        newUniqueContacts.forEach(contact => {
          const excelDate = contact[dateColumnIndex];
          if (typeof excelDate === 'number') {
            const parsedDate = XLSX.SSF.parse_date_code(excelDate, { date1904: false });
            const date = new Date(parsedDate.y, parsedDate.m - 1, parsedDate.d);
            contact[dateColumnIndex] = formatDate(date);
          }
        });

        // Create a new sheet with the correct headers and unique contacts
        const uniqueContactsSheet = XLSX.utils.aoa_to_sheet([headers, ...newUniqueContacts]);

        const uniqueContactsWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(uniqueContactsWorkbook, uniqueContactsSheet, 'Sheet1');

        // const fileName = `unique_contacts_${Date.now()}.xlsx`;
        const wbout = XLSX.write(uniqueContactsWorkbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([wbout], { type: "application/octet-stream" });
        setUniqueContactsFile(blob);
      });
    }
  };

  const handleDownload = () => {
    if (uniqueContactsFile) {
      const fileName = `unique_contacts_${Date.now()}.xlsx`;
      saveAs(uniqueContactsFile, fileName);
    }
  };

  return (
    <div>
      <h2>Excel Compare</h2>
      <div>
        <h4>Old Excel Sheet:</h4>
        <input type="file" accept=".xlsx" onChange={handleOldFileChange} />
      </div>
      <div>
        <h4>New Excel Sheet:</h4>
        <input type="file" accept=".xlsx" onChange={handleNewFileChange} />
      </div>
      <br />
      <button onClick={handleCompare}>Compare Sheets</button>
      <br />
      <br />
      {uniqueContactsFile && (
        <div>
          <h4>Download Unique Contacts:</h4>
          <button onClick={handleDownload}>Download</button>
        </div>
      )}
    </div>
  );
}
