import { CheckSerialValidation } from "./components/checks-serial-validation.component"
import { KabalotOcr } from "./components/kabalot-ocr"

export const KabalotImportSection = () => {

    return (
        <>
            <h2 className="mt-2">העברת קבלות</h2>
            <div className="box">
                <h3>ייצוא נתונים מצילומי מסך של רשימות קבלות </h3>
                <h5>להעלות לכאן את כל התמונות</h5>
                <KabalotOcr />
            </div>
            <div className='box'>
                <h3>בדיקה</h3>
                <CheckSerialValidation />
            </div>
        </>
    )
}