import { useEffect, useState } from 'react';
import { environment } from 'src/common/config';
import useApi from 'src/common/hooks/use-api.hook';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import { UpdateTerminalKeysFormComponent } from './components/update-machine-form/update-terminal-keys-form.component';
import { Loader } from 'src/components/loader/loader.component';

export const TerminalKeysTab = props => {
    const { currentBusiness } = props;
    const [selectedWebsite, setSelectedWebsite] = useState(null);
    const [terminalKeys, setTerminalKeys] = useState({});

    const {
        response: getWebsitesResponse,
        error: getWebsitesError,
        loading: getWebsitesLoading,
        execute: getWebsitesExecute,
    } = useApi({
        url: `${environment.service_panel}/website/business/${currentBusiness?._id}`,
        method: 'GET',
    });

    const {
        response: getWebsiteTerminalKeysResponse,
        error: getWebsiteTerminalKeysError,
        loading: getWebsiteTerminalKeysLoading,
        execute: getWebsiteTerminalKeys,
    } = useApi({
        url: `${environment.service_panel}/terminalkey/${currentBusiness?._id}/${selectedWebsite?._id}`,
        method: 'GET',
    });

    useEffect(() => {
        getWebsitesExecute();
    }, []);

    useEffect(() => {
        setTerminalKeys(getWebsiteTerminalKeysResponse?.data?.data?.terminalKeys || {});
    }, [getWebsiteTerminalKeysResponse]);

    const handleWebsiteChange = event => {
        const selectedWebsiteObject = event.target.value;
        setSelectedWebsite(selectedWebsiteObject);
    };

    useEffect(() => {
        if (!selectedWebsite) {
            return;
        }
        getWebsiteTerminalKeys();
    }, [selectedWebsite]);

    // Convert the Map to a JavaScript array
    // const machineArray = [...machines.values()];
    // console.log('machineArray', machineArray);

    return (
        <div className="machines-tab">
            <h2>שם עסק {currentBusiness.name}</h2>
            <div className="machine-details-editor-header">
                {getWebsitesLoading ? (
                    <Loader />
                ) : (
                    <div className="website-select-sec">
                        <h3>סניף</h3>
                        <FormControl className="mt-2">
                            <InputLabel id="website-select-label">אנא בחר סניף מהרשימה</InputLabel>
                            <Select
                                labelId="website-select-label"
                                id="website"
                                style={{ minWidth: 200 }}
                                value={selectedWebsite}
                                onChange={handleWebsiteChange}>
                                {getWebsitesResponse?.data.map(website => (
                                    <MenuItem value={website} key={website._id}>
                                        {website.websiteName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {selectedWebsite ? (
                    getWebsiteTerminalKeysLoading ? (
                        <Loader />
                    ) : (
                        <div className="machine-select">
                            <h3>רשימת מחשבים</h3>
                            {/* <FormControl className="mt-2"> */}
                            <InputLabel id="machine-select-label">אנא בחר מחשב מהרשימה</InputLabel>

                            {/* </FormControl> */}
                        </div>
                    )
                ) : (
                    <div>לא נבחר סניף</div>
                )}
            </div>
            {!!selectedWebsite ? (
                <div className="mt-3">
                    <UpdateTerminalKeysFormComponent
                        businessId={currentBusiness?._id}
                        websiteId={selectedWebsite?._id}
                        terminalKeys={terminalKeys}
                        setTerminalKeys={setTerminalKeys}
                    />
                </div>
            ) : (
                <div className="empty-state">לא נבחר מחשב מהרשימה</div>
            )}
        </div>
    );
};
