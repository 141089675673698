import axios from 'axios';
import { environment } from 'src/common/config';
import { ZenatPlatform } from 'src/common/enums';

export class ManagePhoneVersionsService {
    async getMinVersions(): Promise<Array<{ platform: 'ios' | 'android', zenatPlatform: ZenatPlatform, minimuimVersion: string }>> {
        const { data } = await axios.get(`${environment.service_panel}/version`);

        return data.data.minVersions;
    }

    async updateMinVersions(iosBuildNumber: string, androidBuildNumber: string, zenatPlatform: ZenatPlatform): Promise<boolean> {
        const { data } = await axios.post(`${environment.service_panel}/version`, {
            android: androidBuildNumber,
            ios: iosBuildNumber,
            zenatPlatform,
        });

        return data.data.success;
    }
}
