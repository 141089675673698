import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';

export const readJsonFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.onload = () => {
      try {
        const data = JSON.parse(reader.result);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => {
      reader.abort();
      reject(new Error('Error reading JSON file'));
    };
    reader.readAsText(file);
  });
}

export const readXlsxFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.onload = () => {
      try {
        const data = new Uint8Array(reader.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        resolve({ sheetName, sheetData });
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => {
      reader.abort();
      reject(new Error('Error reading XLSX file'));
    };
    reader.readAsArrayBuffer(file);
  });
}

export function addPhoneNumbersToJson(jsonData, xlsxData) {
  const idToPhoneNumberMap = jsonData.reduce((acc, obj) => {
    acc[obj.id] = obj.phoneNumber;
    return acc;
  }, {});

  xlsxData.sheetData.forEach((row) => {
    const id = row['חשבונית מס'];
    row['טלפון'] = idToPhoneNumberMap[id] || '';
  });

  return xlsxData;
}

export function saveXlsxFile(sheetName, sheetData) {
  const newWorkbook = XLSX.utils.book_new();
  const newWorksheet = XLSX.utils.json_to_sheet(sheetData);
  XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, sheetName);
  const xlsxOutput = XLSX.write(newWorkbook, { type: 'array', bookType: 'xlsx' });
  const blob = new Blob([xlsxOutput], { type: 'application/octet-stream' });
  saveAs(blob, 'output.xlsx');
}

export const MergeInvoices = () => {
  const [jsonFile, setJsonFile] = useState(null);
  const [xlsxFile, setXlsxFile] = useState(null);

  const handleProcessFiles = async () => {
    try {
      const jsonData = await readJsonFile(jsonFile);
      const xlsxData = await readXlsxFile(xlsxFile);
      const updatedXlsxData = addPhoneNumbersToJson(jsonData, xlsxData);
      saveXlsxFile(updatedXlsxData.sheetName, updatedXlsxData.sheetData);
    } catch (error) {
      console.error('Error processing files:', error);
    }
  };

  return (
    <div>

      <div>
        <label htmlFor="jsonFile">JSON generated and checked from above File:</label>
        <input
          type="file"
          id="jsonFile"
          accept=".json"
          onChange={(e) => setJsonFile(e.target.files[0])}
        />
      </div>
      <div>
        <label htmlFor="xlsxFile">XLSX Generated from above File:</label>
        <input
          type="file"
          id="xlsxFile"
          accept=".xlsx"
          onChange={(e) => setXlsxFile(e.target.files[0])}
        />
      </div>
      <button onClick={handleProcessFiles} disabled={!jsonFile || !xlsxFile}>
        Process Files
      </button>
    </div>
  );
}
