import { Button, TableCell, TableRow } from "@mui/material";
import { getCustomerOrWorkerName, humanizePhoneNumber } from "src/common/utils";
import moment from 'moment';

export const BasketActionRow = props => {
    const { basketAction, zenatName, websiteName, setSelectedBasketAction } = props;

    const onSelectBasketAction = (): void => {
        setSelectedBasketAction(basketAction);
    }

    return (
        <TableRow>
            <TableCell>
                {getCustomerOrWorkerName(basketAction?.customer)}
            </TableCell>
            <TableCell>
                {humanizePhoneNumber(basketAction?.customer.phoneNumber)}
            </TableCell>
            <TableCell>
                {moment(basketAction?.createdAt).format('DD/MM/YY HH:mm')}
            </TableCell>
            <TableCell>{basketAction.boughtCoupon.boughtCouponNumber}</TableCell>
            <TableCell>{basketAction.boughtCoupon?.deal?.dealNumber}</TableCell>
            <TableCell>{basketAction.boughtCoupon?.boughtCoupon?.title.he}</TableCell>
            <TableCell>{(basketAction.boughtCoupon?.boughtCoupon?.priceWithTax || '-')}</TableCell>
            <TableCell>{zenatName}</TableCell>
            <TableCell>{websiteName}</TableCell>
            <TableCell>נמצא בקופה זאת</TableCell>
            <TableCell>{basketAction.ppaAmount}</TableCell>
            <TableCell>{basketAction.payAtVenueAmount}</TableCell>
            <TableCell>{`${basketAction.commissionPercentage}% + ${basketAction.commissionStaticAmount} ₪`}</TableCell>
            <TableCell>{((((basketAction.boughtCoupon?.boughtCoupon?.priceWithTax || 0) * basketAction.commissionPercentage / 100) + basketAction.commissionStaticAmount) * 1.17).toFixed(3)}</TableCell>
            <TableCell><Button onClick={onSelectBasketAction}>פעולות</Button></TableCell>
        </TableRow>
    )
}