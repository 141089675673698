import { useEffect, useState } from 'react';
import { environment } from 'src/common/config';
import useApi from 'src/common/hooks/use-api.hook';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import { UpdateMachineFormComponent } from './components/update-machine-form/update-machine-form.component';
import { Loader } from 'src/components/loader/loader.component';
// import { Map } from 'immutable';

export const MachinesTab = props => {
    const { currentBusiness } = props;
    const [machines, setMachines] = useState(new Map<string, any>());
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [selectedMachineId, setSelectedMachineId] = useState(null);
    const [selectedWebsite, setSelectedWebsite] = useState(null);

    const {
        response: getWebsitesResponse,
        error: getWebsitesError,
        loading: getWebsitesLoading,
        execute: getWebsitesExecute,
    } = useApi({
        url: `${environment.service_panel}/website/business/${currentBusiness?._id}`,
        method: 'GET',
    });

    const {
        response: getMachinesResponse,
        error: getMachinesError,
        loading: getMachinesLoading,
        execute: getMachinesExecute,
    } = useApi({
        url: `${environment.service_panel}/machine/website/${selectedWebsite?.websiteId}`,
        method: 'GET',
    });

    useEffect(() => {
        if (getMachinesResponse?.data?.data?.machines) {
            // Create a new Map by mapping over the machines and setting each one
            const newMachines = new Map<string, any>();
            getMachinesResponse.data.data.machines.forEach(e => {
                newMachines.set(e._id, e);
            });

            // Update the state with the new Map
            setMachines(newMachines);
        }
    }, [getMachinesResponse]);

    useEffect(() => {
        getWebsitesExecute();
    }, []);

    const handleWebsiteChange = event => {
        const selectedWebsiteObject = event.target.value;
        setSelectedWebsite(selectedWebsiteObject);
        setSelectedMachine(null);
        setSelectedMachineId(null);
    };

    const handleMachineChange = event => {
        const selectedMachineObject = event.target.value;
        setSelectedMachineId(selectedMachineObject);
        setSelectedMachine(machines.get(selectedMachineObject));
    };

    useEffect(() => {
        if (!selectedWebsite) {
            return;
        }
        getMachinesExecute();
    }, [selectedWebsite]);

    // Convert the Map to a JavaScript array
    const machineArray = [...machines.values()];
    console.log('machineArray', machineArray);

    return (
        <div className="machines-tab">
            <h2>שם עסק {currentBusiness.name}</h2>
            <div className="machine-details-editor-header">
                {getWebsitesLoading ? (
                    <Loader />
                ) : (
                    <div className="website-select-sec">
                        <h3>סניף</h3>
                        <FormControl className="mt-2">
                            <InputLabel id="website-select-label">אנא בחר סניף מהרשימה</InputLabel>
                            <Select
                                labelId="website-select-label"
                                id="website"
                                style={{ minWidth: 200 }}
                                value={selectedWebsite}
                                onChange={handleWebsiteChange}>
                                {getWebsitesResponse?.data.map(website => (
                                    <MenuItem value={website} key={website._id}>
                                        {website.websiteName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {selectedWebsite ? (
                    getMachinesLoading ? (
                        <Loader />
                    ) : (
                        <div className="machine-select">
                            <h3>רשימת מחשבים</h3>
                            {/* <FormControl className="mt-2"> */}
                                <InputLabel id="machine-select-label">אנא בחר מחשב מהרשימה</InputLabel>
                                <Select
                                    style={{ minWidth: 200 }}
                                    labelId="machine-select-label"
                                    id="website"
                                    value={selectedMachineId}
                                    onChange={handleMachineChange}>
                                    {machineArray.map((machine) => (
                                        <MenuItem value={machine._id} key={`machine-${machine._od}-${selectedWebsite._id}`}>
                                            {machine.cashier?.name}
                                            {machine.cashier._i}
                                        </MenuItem>
                                    ))}
                                </Select>
                            {/* </FormControl> */}
                        </div>
                    )
                ) : (
                    <div>לא נבחר סניף</div>
                )}
            </div>
            {!!selectedWebsite && !!selectedMachine ? (
                <div className="mt-3">
                    <UpdateMachineFormComponent
                        selectedMachine={selectedMachine}
                        setSelectedMachine={setSelectedMachine}
                        setSelectedMachineId={setSelectedMachineId}
                    />
                </div>
            ) : (
                <div className="empty-state">לא נבחר מחשב מהרשימה</div>
            )}
        </div>
    );
};
