interface BaseConfig {
    google_api_key: string;
}

interface ServiceUrls {
    service_backendV2: string;
    service_coupon: string;
    service_customer: string;
    service_panel: string;
}

interface EnvironmentConfig extends BaseConfig, ServiceUrls {
    base_url: string;
}

const baseConfig = {
    google_api_key: 'AIzaSyD2iXSJLdHciDLxMS2WWD5AOj5IRZDPFKI',
};

const environments: Record<string, EnvironmentConfig> = {
    localhost: {
        ...baseConfig,
        base_url: 'http://localhost',
    } as EnvironmentConfig,
    localIP: {
        ...baseConfig,
        base_url: 'http://192.168.253.34',
    } as EnvironmentConfig,
    prod: {
        ...baseConfig,
        base_url: 'https://api.zenat.app',
    } as EnvironmentConfig,
};

Object.keys(environments).forEach(env => {
    if (env === 'prod') {
        environments[env].service_backendV2 = `${environments[env].base_url}/v2`;
        environments[env].service_coupon = `${environments[env].base_url}/coupon`;
        environments[env].service_customer = `${environments[env].base_url}/customer`;
        environments[env].service_panel = `${environments[env].base_url}/panel`;
    } else {
        environments[env].service_backendV2 = `${environments.prod.base_url}/v2`;
        environments[env].service_coupon = `${environments[env].base_url}:3089/coupon`;
        environments[env].service_customer = `${environments[env].base_url}:3088/customer`;
        environments[env].service_panel = `${environments[env].base_url}:3087/panel`;
    }
});

let activeEnvironment = environments.prod;
if (process.env.NODE_ENV === 'development') {
    // activeEnvironment = environments.localhost;
    activeEnvironment = environments.localhost;
}

console.log('Environment: ', activeEnvironment);

export const environment = activeEnvironment;
