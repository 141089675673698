import axios from "axios";
import { environment } from "src/common/config";

class manageBalancesService {
    private static _instance: manageBalancesService;

    public static getInstance(): manageBalancesService {
        if (!this._instance) {
            this._instance = new manageBalancesService();
        }
        return this._instance;
    }

    async resetBasketAmount(basketId: string, websiteId: string, businessId: string) {
        const { data } = await axios.post(`${environment.service_panel}/basket/${websiteId}/${businessId}/${basketId}/reset`);

        return data?.data?.success;
    }

    async cancelCommisionFromBasketAction(boughtCouponId: string, customerId: string, websiteId: string, businessId: string) {
        const { data } = await axios.post(`${environment.service_panel}/basket/${websiteId}/${businessId}/cancel/commission`, {
            boughtCouponId,
            customerId,
        });

        return data?.data?.success;
    }

    async cancelDealAndCommission(dealId: string, boughtCouponId: string, customerId: string, websiteId: string, businessId: string) {
        const { data } = await axios.post(`${environment.service_panel}/basket/${websiteId}/${businessId}/cancel/deal`, {
            boughtCouponId,
            customerId,
            dealId,
        });

        return data?.data?.success;
    }
}

export const ManageBalancesService = manageBalancesService.getInstance();
