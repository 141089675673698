import { useEffect } from 'react';

function useHandleUpdateBusinessResponses(response, error, setCanBeSaved): void {
    useEffect(() => {
        if (error) {
            alert('error updating business');
            console.error('Error updating business: ', error);
        }
    }, [error]);

    useEffect(() => {
        if (response?.data?.data?.business?._id) {
            alert('business updated successfully');
            setCanBeSaved(false)
        }
    }, [response, setCanBeSaved]);
}

export default useHandleUpdateBusinessResponses;
