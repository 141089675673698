import { IconButton, TableCell, TableRow } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import BlockSharpIcon from '@mui/icons-material/BlockSharp';
import { CouponType } from 'src/common/enums';
import { PendingCouponRowService } from '../../services';
import { AreYouSureDialog, LoaderDialog } from 'src/dialogs';
import { useState } from 'react';

interface IPendingCouponRow {
    coupon: any;
    removeUpdatedCoupon: (couponId: string) => void;
}

export const PendingCouponRow = (props: IPendingCouponRow) => {
    const { coupon, removeUpdatedCoupon } = props;
    const [isUpdatingPendingApprovalCoupon, setIsUpdatingPendingApprovalCoupon] = useState<boolean>(false);
    const [isApprovingCouponInitiated, setIsApprovingCouponInitiated] = useState<boolean>(false);
    const [isDenyingCouponInitiated, setIsDenyingCouponInitiated] = useState<boolean>(false);

    const openManageCouponDialog = (): void => {
        const urlPrefix = process.env['NODE_ENV'] === 'development' ? 'http://localhost:3000/#/booklee' : 'http://web.zenat.co.il/#/booklee';
        const urlSuffix = `?businessId=${coupon.business._id}&websiteId=${coupon.websites[0]}`;
        let url = '';

        switch (coupon.type) {
            case CouponType.SERVICE: {
                url = `services/${coupon.options[0].services[0]._id}`;
                break;
            }
            case CouponType.CONSULTING: {
                url = `consult-services/${coupon.options[0].services[0]._id}`;
                break;
            }
            case CouponType.SERIE: {
                url = `series/${coupon.options[0].series[0]._id}`;
                break;
            }
            case CouponType.SALE: {
                url = `sales/${coupon._id}`;
                break;
            }
            default: {
                return;
            }
        }

        window.open(`${urlPrefix}/${url}${urlSuffix}`);
    };

    const approveAndPublishCoupon = async (confirmed: boolean) => {
        if( !confirmed ) {
            setIsApprovingCouponInitiated(false);
            return;
        }
        try {
            setIsApprovingCouponInitiated(false);
            setIsUpdatingPendingApprovalCoupon(true);
            const hasApprovedPendingCoupon = await PendingCouponRowService.approvePendingApprovalCoupon(coupon._id);
            if (hasApprovedPendingCoupon) {
                removeUpdatedCoupon(coupon._id);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsUpdatingPendingApprovalCoupon(false);
            setIsApprovingCouponInitiated(false);
        }
    };

    const ignoreCoupon = async (confirmed: boolean) => {
        if( !confirmed ) {
            setIsDenyingCouponInitiated(false);
            return;
        }
        try {
            setIsUpdatingPendingApprovalCoupon(true);
            const hasDeniedPendingCoupon = await PendingCouponRowService.denyPendingApprovalCoupon(coupon._id);
            if (hasDeniedPendingCoupon) {
                removeUpdatedCoupon(coupon._id);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsUpdatingPendingApprovalCoupon(false);
            setIsDenyingCouponInitiated(false);
        }
    };

    const initiateApproveCoupon = (): void => {
        setIsApprovingCouponInitiated(true);
    }

    const initiateDenyingCoupon = (): void => {
        setIsDenyingCouponInitiated(true);
    }


    console.log(coupon, 'sddsds')
    return (
        <>
            <LoaderDialog isLoaderDialog={isUpdatingPendingApprovalCoupon} />
            <AreYouSureDialog onClose={approveAndPublishCoupon} text={'האם עברת וקראת את כל הפרטים וברצונך לפרסם ?'} okText={'OK'} cancelText={'CANCEL'} isVisible={isApprovingCouponInitiated} />
            <AreYouSureDialog onClose={ignoreCoupon} text={'האם עברת על כל פרטים ואתה בטוח?'} okText={'OK'} cancelText={'CANCEL'} isVisible={isDenyingCouponInitiated} />
            <TableRow>
                <TableCell>{coupon.business.zenatName}</TableCell>
                <TableCell>{coupon.title.ar}</TableCell>
                <TableCell>{coupon.business.phoneNumber}</TableCell>
                <TableCell>{coupon.type}</TableCell>
                <TableCell>{coupon.title.ar}</TableCell>
                <TableCell>{coupon.times || 1}</TableCell>
                <TableCell>{coupon.options[0]?.usingExpirationMonths || '-'}</TableCell>
                <TableCell>{coupon.title.ar}</TableCell>
                <TableCell>
                    <IconButton onClick={openManageCouponDialog}>
                        <ExitToAppIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton onClick={initiateApproveCoupon} style={{ backgroundColor: '#59AD6A' }}>
                        <CheckCircleSharpIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton onClick={initiateDenyingCoupon} style={{ backgroundColor: '#e02114' }}>
                        <BlockSharpIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    );
};
