import { useEffect, useState } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import './App.css';
import './common/interceptor/auth.interceptor';
import Home from './pages/homepage/home';
import 'leaflet/dist/leaflet.css';

import './App.css';
import { Tools } from './pages/tools/tools';

import { Sidebar } from './components/sidebar/sidebar.component';
import { LoginScreen } from './pages/login/login.screen';
import { LoginService } from './pages/login/login.service';
import { get } from 'lodash-es';
import { Loader } from './components/loader/loader.component';
import MotzaremCategoriesPanel from './pages/motzarim-categories/motzarim-categories';
import ServicesCategoriesPanel from './pages/services-categories/services-categories';
import BusinessTable from './pages/manage-businesses/manage-businesses';
import { ManageHashavshevet } from './pages/manage-hashavshevet/manage-hashavshevet.page';
import ManageCoupons from './pages/manage-coupons/manage-coupons';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import EmvTools from './pages/emv-tools/emv-tools';
import ManageBlockedUsersComp from './pages/manage-blocked-users/manage-blocked-users';
import ManagePhoneVersions from './pages/manage-phone-versions/manage-phone-versions';
import ManagePhotos from './pages/manage-photos/manage-photos';
import { ManageBalances } from './pages/manage-balances/manage-balances.screen';
import { MasavGenerator } from './pages/masav-generator/masav-generator.screen';
import { ManageBookingsScreen } from './pages/manage-bookings/manage-bookings.screen';
import { MapRadiusScreen } from './pages/map-radius';
import { PartnersScreen } from './pages/partners';
import QuicklyInvoiceBusinessTable from './pages/manage-businesses-quickly-invoice/manage-businesses-quickly-invoice';

export const App = () => {
    const loginService = new LoginService();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <div className={`app-grid ${sidebarVisible ? 'sidebar-visible' : ''}`}>
                    <button className="hamburger" onClick={toggleSidebar}>
                        &#9776;
                    </button>
                    <Sidebar visible={sidebarVisible} />
                    <div className={`main-content ${sidebarVisible ? 'sidebar-visible' : ''}`}>
                        <Outlet />
                    </div>
                </div>
            ),
            children: [
                {
                    path: '/',
                    element: <Home />,
                },
                {
                    path: 'tools',
                    element: <Tools />,
                },
                {
                    path: 'emv-tools',
                    element: <EmvTools />,
                },
                {
                    path: 'manage-photos',
                    element: <ManagePhotos />,
                },
                {
                    path: 'map-radius',
                    element: <MapRadiusScreen />,
                },
                {
                    path: 'partners',
                    element: <PartnersScreen />,
                },
                {
                    path: 'manage-businesses',
                    element: <BusinessTable />,
                },
                {
                    path: 'manage-businesses-quickly-invoice',
                    element: <QuicklyInvoiceBusinessTable />,
                },
                {
                    path: 'manage-motzarim-categories',
                    element: <MotzaremCategoriesPanel />,
                },
                {
                    path: 'manage-services-categories',
                    element: <ServicesCategoriesPanel />,
                },
                {
                    path: 'manage-balances',
                    element: <ManageBalances />,
                },
                {
                    path: 'manage-bookings',
                    element: <ManageBookingsScreen />,
                },
                {
                    path: 'masav-generator',
                    element: <MasavGenerator />,
                },
                {
                    path: 'manage-hashavshevet',
                    element: <ManageHashavshevet />,
                },
                {
                    path: 'manage-coupons',
                    element: <ManageCoupons />,
                },
                {
                    path: 'manage-blocked-users',
                    element: <ManageBlockedUsersComp />,
                },
                {
                    path: 'manage-phone-versions',
                    element: <ManagePhoneVersions />,
                },
            ],
        },
        {
            path: 'login',
            element: <LoginScreen />,
            index: true,
        },
    ]);

    const checkAuth = async () => {
        try {
            if (!localStorage.getItem('jwt')) {
                return router.navigate('/login');
            }
            const data = await loginService.checkAuth();
            if (get(data, 'user')) {
                router.navigate('/');
            } else {
                router.navigate('/login');
            }
        } catch (error) {
            router.navigate('/login');
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log('herex');
        checkAuth();
    }, []);

    if (loading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    return (
        <div className="App">
            <RouterProvider router={router} />
            <ToastContainer />
        </div>
    );
};
