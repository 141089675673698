import axios from 'axios';
import { environment } from '../config';

const api_base_url = `${environment.service_panel}/general`; // Replace with your actual API base URL

const MainCategoryServices = {
  getAll: () => axios.get(`${api_base_url}`),
  getById: (id) => axios.get(`${api_base_url}/${id}`),
  create: (data) => axios.post(`${api_base_url}`, data),
  update: (id, data) => axios.patch(`${api_base_url}/${id}`, data),
  delete: (id) => axios.delete(`${api_base_url}/${id}`),
};

export default MainCategoryServices;
