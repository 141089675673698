import { useEffect } from "react";
import { toast } from 'react-toastify';

const useHandleUpdateMachineResponses = (response, error, setSelectedMachine, setSelectedMachineId): void => {
    useEffect(() => {
        if (error) {
            console.error('Error updating machine: ', error);
        }
    }, [error]);

    useEffect(() => {
        if (response?.data?.data?.machine?._id) {
            toast.success('נתוני מחשב עודכנו בהצלחה!');
            setSelectedMachine(null);
            setSelectedMachineId(null);
        }
    }, [response, setSelectedMachine]);
}

export default useHandleUpdateMachineResponses;