import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const XlsxToJsonComponent = () => {
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  const handleUpload = () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const processedRows = rows.slice(1).map((row) => ({
        invoiceno: row[0],
        date: row[1],
        pricebeforetax: row[2],
        taxes: row[3],
        pricewithtax: row[4],
        name: row[5],
        phone: row[6],
      }));
      downloadJsonFile(processedRows);
    };
    reader.readAsBinaryString(file);
  };

  const downloadJsonFile = (jsonData) => {
    const dataStr = JSON.stringify(jsonData, null, 2);
    const dataBlob = new Blob([dataStr], { type: 'application/json;charset=utf-8' });
    const dataUrl = URL.createObjectURL(dataBlob);
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = 'converted_invoices.json';
    link.click();
  };

  return (
    <div>
      <h1>Upload and Convert XLSX to JSON</h1>
      <input type="file" onChange={handleChange} accept=".xlsx" />
      <button onClick={handleUpload} disabled={!file}>
        Upload and Convert
      </button>
    </div>
  );
};

export default XlsxToJsonComponent;
