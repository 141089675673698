import axios from 'axios';
import { toast } from 'react-toastify';

axios.interceptors.request.use(
    request => {
        request.headers['authorization'] = JSON.stringify({accessToken: localStorage.getItem('jwt')});
        request.headers['lang'] = 'he';
        return request;
    }, 
    error => Promise.reject(error)
);
axios.interceptors.response.use(
    response => response,
    error => {
        console.log(!!error.response.data.msg, 'dsdsds')
        if (!!error.response.data.msg) {
            toast.error(error.response.data.msg);
        }
        return Promise.reject(error);
    }
);