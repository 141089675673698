import axios from 'axios';
import { environment } from '../config';

const API_BASE_URL = `${environment.service_panel}/motzarem-categories`;

const MotzaremCategoriesService = {
  getAllCategories: async () => {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  },

  addCategory: async (category) => {
    const response = await axios.post(API_BASE_URL, category);
    return response.data;
  },

  getCategoryById: async (id) => {
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    return response.data;
  },

  updateCategory: async (id, category) => {
    const response = await axios.post(`${API_BASE_URL}/${id}`, category);
    return response.data;
  },

  deleteCategory: async (id) => {
    await axios.delete(`${API_BASE_URL}/${id}`);
  },

  // Add getSubCategories function
  getSubCategories: async (mainCategoryId) => {
    const response = await axios.get(`${API_BASE_URL}/sub-categories/${mainCategoryId}`);
    return response.data;
  },

  // Add getSubSubCategories function
  getSubSubCategories: async (subCategoryId) => {
    const response = await axios.get(`${API_BASE_URL}/sub-sub-categories/${subCategoryId}`);
    return response.data;
  },
};

export default MotzaremCategoriesService;
