import  axios from 'axios';
import { environment } from '../../common/config';

export class LoginService {
    async sendAuthCode(phoneNumber, websiteAccess) {
        try {
            const website = localStorage.getItem('websiteId');
            const business = localStorage.getItem('business');

            let query: any = { 
                phoneNumber, 
                mobile: true,
                support: true,
                websiteAccess
            };

            if( website && business ) {
                query.website = website;
                query.business = business;
            }
            const { data } = await axios.post(`${environment.service_backendV2}/login`, query);
            return data;
        } catch(error) {
            throw error;
        }
    }

    async verifyAuthCode(phoneNumber, authCode) {
        try {
            const { data } = await axios.post(`${environment.service_backendV2}/login/verify`, { phoneNumber, authCode, mobile: true, support: true });
            return data;
        } catch(error) {
            throw error;
        }
    }

    async checkAuth() {
        try {
            const { data } = await axios.get(`${environment.service_backendV2}/login/token?access=true&support=true`);
            return data;
        } catch(error) {
            throw error;
        }
    }
}