import axios from 'axios';
import { useEffect, useState } from 'react';
import { environment } from 'src/common/config';

export const usePendingCoupons = (): {  pendingCoupons: any[], setPendingCoupons: (pendingCoupons) => void } => {
    const [pendingCoupons, setPendingCoupons] = useState<any[]>([]);
    
    const fetchPendingCoupons = async () => {
        try {
            const { data } = await axios.get(`${environment.service_panel}/coupon/pendingapproval`);
            setPendingCoupons(data?.data?.coupons || []);
        } catch (error) {
            console.log(error);
        }
    };
    
    useEffect(() => {
        fetchPendingCoupons();
    }, []);

    return {pendingCoupons, setPendingCoupons};
};
