import { useEffect } from "react";
import { environment } from "../config";
import useApi from "./use-api.hook";

export const useWebsites = (businessId) => {


    const {
        response: getWebsitesResponse,
        error: getWebsitesError,
        loading: getWebsitesLoading,
        execute: getWebsitesExecute,
    } = useApi({
        url: `${environment.service_panel}/website/business/${businessId}`,
        method: 'GET',
    });

    useEffect(() => {
        if( businessId ) {
            getWebsitesExecute();
        }
    }, [businessId])

    return { websites: getWebsitesResponse?.data || [], getWebsitesLoading };
}