import { WorkBook, read, utils, write } from 'xlsx';
import { saveAs } from 'file-saver';
import { parse, format } from 'date-fns';

interface ProcessedData {
  status: boolean;
  message: string;
}

export const processSeriesesTwoFiles = (file1: File, file2: File): Promise<ProcessedData> => {
  return new Promise((resolve, _reject) => {
    const reader1 = new FileReader();
    const reader2 = new FileReader();

    reader1.onload = () => {
      const wb1: WorkBook = read(reader1.result, { type: 'binary' });
      const ws1 = wb1.Sheets[wb1.SheetNames[0]];

      reader2.onload = () => {
        const wb2: WorkBook = read(reader2.result, { type: 'binary' });
        const ws2 = wb2.Sheets[wb2.SheetNames[0]];

        const data1: any[][] = utils.sheet_to_json(ws1, { header: 1 });
        const data2: any[][] = utils.sheet_to_json(ws2, { header: 1 });

        // Add headers for new columns D and E in file1
        data1[0].push('נוצלו');
        data1[0].push('ת. פתיחה');

        for (let i = 1; i < data1.length; i++) {
            // Check if there's a value in Column C of data1 (at index 2)
            if (!data1[i][2]) {
              continue; // Skip the current iteration if the cell is empty
            }
          
            for (let j = 1; j < data2.length; j++) {
              // Check if there's a value in Column I of data2 (at index 8) and Column D of data2 (at index 3)
              if (!data2[j][8] || !data2[j][3]) {
                continue; // Skip the current iteration if any of the cells is empty
              }
          
              if (data1[i][2] === data2[j][8] && data1[i][1] === data2[j][3]) {
                data1[i].push(data2[j][4]);
          
                // Parse and format the date using date-fns
                const dateValue = parse(data2[j][2], 'dd/MM/yyyy HH:mm:ss', new Date());
                const formattedDate = isNaN(dateValue.getTime()) ? '' : format(dateValue, 'dd/MM/yyyy');
                data1[i].push(formattedDate);
          
                break;
              }
            }
          }
          

        const processedWs = utils.json_to_sheet(data1, { skipHeader: true });
        const processedWb = utils.book_new();
        utils.book_append_sheet(processedWb, processedWs, 'Processed Data');

        const processedFile = new Blob([write(processedWb, { type: 'array', bookType: 'xlsx' })], {
          type: 'application/octet-stream',
        });

        saveAs(processedFile, 'serieses_zenatFormat.xlsx');
        resolve({ status: true, message: 'עיבוד הקבצים הסתיים בהצלחה.' });
      };

      reader2.readAsBinaryString(file2);
    };

    reader1.readAsBinaryString(file1);
  });
};
