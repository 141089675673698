import React, { useState } from 'react';
import Tesseract from 'tesseract.js';

export const ChecksOcr = () => {
  const [images, setImages] = useState([] as any);
  const [progress, setProgress] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState(null);

  const handleChange = (e) => {
    const imageFiles = Array.from(e.target.files)
      .sort((a: any, b: any) => {
        const aNumber = parseInt(a.name.split('-')[0]);
        const bNumber = parseInt(b.name.split('-')[0]);
        return aNumber - bNumber;
      })
      .map((file: any) => URL.createObjectURL(file));
    setImages(imageFiles);
  };

  const preprocessImage = async (imageSrc) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0, img.width, img.height);

        // Convert the image to grayscale
        const imageData = ctx.getImageData(0, 0, img.width, img.height);

        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
          data[i] = data[i + 1] = data[i + 2] = avg;
        }

        for (let i = 0; i < data.length; i += 4) {
            data[i] = data[i + 1] = data[i + 2] = 255 - data[i];
        }

        const exposureScale = 1.2; // Adjust this value to control exposure level
        for (let i = 0; i < data.length; i += 4) {
            const newVal = data[i] * exposureScale;
            data[i] = data[i + 1] = data[i + 2] = Math.min(255, newVal);
        }
          
        ctx.putImageData(imageData, 0, 0);

        document.body.appendChild(canvas)
        // Resolve the preprocessed image as a Data URL
        resolve(canvas.toDataURL());
      };
    });
  };

  const extractData = async () => {
    if (images.length === 0) {
      console.error('No images selected');
      return;
    }

    let arrayOfArraysOfObjects = [];
    let completedImages = 0;
    setElapsedTime(0);
    const startTime: any = new Date();


    for (const [index, image] of images.entries()) {
      try {
        const preprocessedImage: any = await preprocessImage(image);
        const result = await Tesseract.recognize(preprocessedImage, 'eng', {
          logger: (m) => {
            if (m.status === 'recognizing text') {
              const currentImageProgress = m.progress * 100;
              const overallProgress = ((completedImages + currentImageProgress / 100) / images.length) * 100;
              setProgress(overallProgress);

              const remainingProgress = 1 - m.progress;
              const remainingTimeForCurrentImage = 20 * remainingProgress;
              const remainingTimeForOtherImages = 20 * (images.length - (index + 1));
              const totalEstimatedTime = remainingTimeForCurrentImage + remainingTimeForOtherImages;
              setEstimatedTime(totalEstimatedTime);
            }
            console.log(m);
          },
          // psm: 6, // Adjust psm value
          // oem: 3, // Adjust oem value
        });
        console.log('Tesseract result:', result);
        completedImages++;

        const rows = result.data.text.trim().split('\n');
        const tableData = rows.map((row) => row.split(/\s{2,}/));
        // setProgress(0); // Reset progress after recognition is completed
        arrayOfArraysOfObjects = arrayOfArraysOfObjects.concat(arrayOfObjects(tableData));
      } catch (error) {
        console.error('Error processing the image:', error);
      }
    };
    const endTime: any = new Date();
    const duration = (endTime - startTime) / 1000;
    console.log(arrayOfArraysOfObjects, 'Extracted data');
    setElapsedTime(duration);
    setProgress(0); // Reset progress after
    downloadJSON(arrayOfArraysOfObjects, 'ocr-Checks-results.json');
  };

  const arrayOfObjects = array => {
    return array.map(item => {
      const [id] = item[0].split(' ');
        
      return {
        id: extractNumbers(id),
      };
    });
  }
  
  const extractNumbers = (str) => {
    return str.replace(/\D+/g, '');
  }

  const downloadJSON = (data, filename) => {
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);
  
    return [
      hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : null,
      minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : null,
      seconds > 0 ? `${seconds} second${seconds > 1 ? 's' : ''}` : null,
    ]
      .filter((part) => part)
      .join(', ');
  };  

  return (
    <div>
      <input
        type="file"
        accept="image/png"
        onChange={handleChange}
        multiple
      />
      <button onClick={extractData}>עיבוד תמונות</button>
      {progress > 0 && <span>{progress.toFixed(2)}%</span>}
      {progress > 0 && estimatedTime && (
        <p>
          Estimated time remaining: <strong>{formatDuration(estimatedTime)}</strong>
        </p>
      )}
      {elapsedTime && (
        <p>
          Time taken: <strong>{formatDuration(elapsedTime)}</strong>
        </p>
      )}
    </div>
  );
};
