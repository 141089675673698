import './manage-businesses.css';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { environment } from 'src/common/config';

import AddBusinessComp from './add-business.component/add-business.component';
import { ManageBusinessDialog } from './dialogs/manage-business/manage-business.dialog';
import { useBusinesses } from 'src/common/hooks/use-businesses.hook';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    // Add custom styles here
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    // Add custom styles here
}));

const formatAddress = address => {
    return address ? address.city : '';
};

const BusinessTable = () => {
    // State for businesses data, search, and pagination
    const { businesses, fetchData } = useBusinesses();

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isManageBusinessDialog, setIsManageBusinessDialog] = useState<boolean>(false);

    const [currentBusiness, setCurrentBusiness] = useState(null);

    const [businessName, setBusinessName] = useState();

    // State for adding new business
    const [openAddDialog, setOpenAddDialog] = useState(false);

    // Function to handle close of add dialog
    const handleAddBusinessDialogClose = () => {
        setOpenAddDialog(false);
    };

    const onDeleteBusiness = chosenBusiness => async () => {
        const confirmation = window.confirm(`האם אתה בטוח שברצונך למחוק את העסק? - ${chosenBusiness?.name}`);
        if (!confirmation) {
            return;
        }
        try {
            await axios.delete(`${environment.service_panel}/business/${chosenBusiness._id}`);
            alert('העסק נמחק בהצלחה');
            fetchData();
        } catch (error) {
            console.error(error);
        }
    };

    // Update page and rowsPerPage for pagination
    const handleChangePage = (event, newPage): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event): void => {
        setRowsPerPage(parseInt(event.target.value, 25));
        setPage(0);
    };

    // Filter and sort businesses based on search query
    const filteredBusinesses = useMemo(() => {
        return businesses
            .filter(
                business =>
                    (business.name && business.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (business.zenatName && business.zenatName.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (business.address &&
                        business.address.city &&
                        business.address.city.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (business.type && business.type.toLowerCase().indexOf(search.toLowerCase()) !== -1),
            )
            .sort((a, b) => a.name?.localeCompare?.(b.name));
    }, [businesses, search]);

    const openManageBusinessDialog = business => (): void => {
        setCurrentBusiness(business);
        setIsManageBusinessDialog(true);
    };

    const onCloseManageBusinessDialog = (): void => {
        setCurrentBusiness(null);
        setIsManageBusinessDialog(false);
    };

    return (
        <Paper>
            <h1>ניהול עסקים</h1>
            <div className="table-header">
                <AddBusinessComp />
                <CustomTextField label="Search" value={search} onChange={e => setSearch(e.target.value)} />
            </div>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>שם עסק חשבונאי</CustomTableCell>
                            <CustomTableCell>שם עסק מסחרי</CustomTableCell>
                            <CustomTableCell>כתובת</CustomTableCell>
                            <CustomTableCell>סוג</CustomTableCell>
                            <CustomTableCell>סטטוס</CustomTableCell>
                            <CustomTableCell>ח.פ.</CustomTableCell>
                            <CustomTableCell>טלפון</CustomTableCell>
                            <CustomTableCell>עריכה</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredBusinesses
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(business => (
                                <TableRow key={business._id}>
                                    <CustomTableCell>{business.name}</CustomTableCell>
                                    <CustomTableCell>{business.zenatName}</CustomTableCell>
                                    <CustomTableCell>{formatAddress(business.address)}</CustomTableCell>
                                    <CustomTableCell>
                                        {business.businessType === 'INDIVIDUAL_FREE_TAX'
                                            ? 'עוסק פטור'
                                            : business.businessType === 'INDIVIDUAL'
                                            ? 'עוסק מורשה / בע"מ'
                                            : business.businessType}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {business.businessVisibility === 'COMING_SOON'
                                            ? 'יופיע בקרוב'
                                            : business.businessVisibility === 'ACTIVE'
                                            ? 'מופיע באפליקציה'
                                            : business.businessVisibility === 'DISABLED'
                                            ? 'לא מופיע'
                                            : business.businessVisibility}
                                    </CustomTableCell>
                                    <CustomTableCell>{business.businessId}</CustomTableCell>
                                    <CustomTableCell>{business.contact.phoneNumber}</CustomTableCell>
                                    <TableCell>
                                        <IconButton onClick={openManageBusinessDialog(business)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={onDeleteBusiness(business)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={filteredBusinesses.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        dir="rtl"
                    />
                </Grid>
            </Grid>

            <Dialog open={openAddDialog} onClose={handleAddBusinessDialogClose} fullWidth maxWidth="md">
                <DialogContent>
                    {/* Your form fields for adding a new business go here */}
                    <TextField label="Business Name" onChange={(e: any) => setBusinessName(e.target.value)} />
                    {/* Add more form fields as needed */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddBusinessDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddBusinessDialogClose} color="primary" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {isManageBusinessDialog ? 'true' : ''}
            {isManageBusinessDialog && (
                <ManageBusinessDialog currentBusiness={currentBusiness} onClose={onCloseManageBusinessDialog} />
            )}
        </Paper>
    );
};

export default BusinessTable;
