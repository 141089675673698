import { Dialog, DialogContent } from "@mui/material"
import { useEffect, useState } from "react";
import { Loader } from "src/components/loader/loader.component";


interface ILoaderDialogProps {
    isLoaderDialog: boolean;
}

export const LoaderDialog = (props: ILoaderDialogProps) => {
    const [isLoaderDialog, setIsLoaderDialog] = useState<boolean>(props.isLoaderDialog);

    useEffect(() => {
        setIsLoaderDialog(props.isLoaderDialog);
    }, [props.isLoaderDialog])

    const handleDialogClose = () => {
        setIsLoaderDialog(false);
    };

    if( !isLoaderDialog ) {
        return null;   
    }

    return (
        <Dialog open onClose={handleDialogClose} fullWidth maxWidth="sm">
            <DialogContent style={{ height: 300 }}>
                <Loader />
            </DialogContent>
        </Dialog>
    )
}