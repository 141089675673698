import { Button } from '@mui/material';
import { environment } from 'src/common/config';
import useApi from 'src/common/hooks/use-api.hook';
import useHandleUpdateMachineResponses from 'src/pages/manage-businesses/hooks/handle-update-machine-responses.hook';
import get from 'lodash-es/get';
import { UpdateMachineFormInputRow } from './input-row.component';
import { UPDATE_MACHINE_FORM_INPUTS } from './update-machine-form.config';
import { useState } from 'react';
import { EmvType } from 'src/common/enums';

export const UpdateMachineFormComponent = props => {
    const { selectedMachine, setSelectedMachine, setSelectedMachineId } = props;
    const [hasUpdatedFields, setHasUpdatedFields] = useState(false);

    const {
        response: updateMachineResponse,
        error: updateMachineError,
        loading: updateMachineLoading,
        execute: updateMachineExecute,
    } = useApi({
        url: `${environment.service_panel}/machine/${selectedMachine?._id}`,
        method: 'PUT',
    });

    useHandleUpdateMachineResponses(
        updateMachineResponse,
        updateMachineError,
        setSelectedMachine,
        setSelectedMachineId,
    );

    const onChangeEmvType = event => {
        setHasUpdatedFields(true);
        setSelectedMachine(state => ({
            ...state,
            emvType: event.target.value,
        }));
    };

    const onChangeMachineDetail = type => event => {
        setHasUpdatedFields(true);
        setSelectedMachine(state => ({
            ...state,
            [type]: event.target.value,
        }));
    };

    const updateMachineDetails = () => {
        updateMachineExecute({
            data: {
                ...selectedMachine,
                LaneId: selectedMachine?.LaneId?.toString(),
                ChainId: selectedMachine?.ChainId?.toString(),
                StoreId: selectedMachine?.StoreId?.toString(),
            },
        });
    };

    return (
        <div className="selected-machine">
            <div className="machine-details-editor">
                <div className="input-text mt-3">
                    <h4>שם קופה משוייכת למחשב זה: {selectedMachine?.cashier?.name}</h4>
                </div>
                <div className="select-with-label">
                    <select onChange={onChangeEmvType}>
                        <option selected disabled={true}>
                            נא בחר אופציה
                        </option>
                        <option selected={selectedMachine.emvType === EmvType.VERIFONE} value={EmvType.VERIFONE}>
                            {EmvType.VERIFONE}
                        </option>
                        <option selected={selectedMachine.emvType === EmvType.PELECARD} value={EmvType.PELECARD}>
                            {EmvType.PELECARD}
                        </option>
                    </select>
                </div>
                {UPDATE_MACHINE_FORM_INPUTS.map(
                    formInput =>
                        formInput.emvTypes.includes(selectedMachine.emvType || EmvType.NONE) && (
                            <UpdateMachineFormInputRow
                                key={`formInput-${formInput.key}`}
                                onChange={onChangeMachineDetail(formInput.key)}
                                disabled={formInput.disabled}
                                label={formInput.label}
                                note={formInput.note}
                                value={get(selectedMachine, formInput.key)?.toString()}
                            />
                        ),
                )}
            </div>
            <div className="error-text">{get(updateMachineError, 'response.data.msg')}</div>
            <Button disabled={!hasUpdatedFields} onClick={updateMachineDetails} variant="contained">
                עדכן נתוני מחשב
            </Button>
        </div>
    );
};
