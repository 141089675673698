import axios from "axios";
import { environment } from "src/common/config";
import { UpdatePendingApprovalCouponStatusDto } from "./dto";

class pendingCouponRowService {
    private static instance: pendingCouponRowService;

    constructor() {}

    static getInstance(): pendingCouponRowService {
        if (!pendingCouponRowService.instance) {
            pendingCouponRowService.instance = new pendingCouponRowService();
        }
        return pendingCouponRowService.instance;
    }

    async denyPendingApprovalCoupon(couponId: string): Promise<boolean> {
        const { data } = await axios.post(`${environment.service_panel}/coupon/pendingapproval/deny`, {
            couponId,
        } as UpdatePendingApprovalCouponStatusDto);

        return data.data.success;
    }

    async approvePendingApprovalCoupon(couponId: string): Promise<boolean> {
        const { data } = await axios.post(`${environment.service_panel}/coupon/pendingapproval/approve`, {
            couponId,
        } as UpdatePendingApprovalCouponStatusDto);

        return data.data.success;
    }
}

export const PendingCouponRowService = pendingCouponRowService.getInstance();
