export const AnydeskProDownload = () => {
    const openInNewTab = (url: string) => {
        // Using window.open() to open a new tab
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      };

    return (
        <>
            <h2 className="mt-2">Steps to break anydesk limitations</h2>
            <h2 className="mt-2">Worked on Anydesk 2023</h2>
            <div className="box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h4>1. Open Task Manager and close two proccess called Anydesk </h4>
                <h4>2. Go to C:\ProgramData\AnyDesk and delete two files "system.conf" + "service.conf"  </h4>
                <h4>Download and run the Batch file, Enjoy</h4>
                <h4>Note: Anydesk address will be changed , any remote device its password will be asked again  </h4>
                <button onClick={() => openInNewTab('https://drive.google.com/file/d/1VUhfbmKTs0VODc6soOe7Ed9HN8O_J8rf/view?usp=share_link')}>Download Batch File</button>
            </div>
        </>
    );
};
