import { Button, Dialog, DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';
import { ManageBalancesService } from '../../manage-balances.service';
import { BasketType } from 'src/common/enums';
import { toast } from 'react-toastify';

interface IManageBasketActionRowDialogProps {
    onClose: (confirmed: boolean) => void;
    // text: string;
    // okText: string;
    // cancelText: string;
    isVisible: boolean;
    basketAction: any;
    basket: any;
}

export const ManageBasketActionRowDialog = (props: IManageBasketActionRowDialogProps) => {
    const { onClose: onCancel, isVisible, basketAction, basket } = props;

    console.log(basket, basketAction)
    
    const onCancelCommisionFromBoughtCoupon = async () => {
        console.log(
            {
                basketAction,
            },
            'BASKET_ACTION',
        );
        try {
            const data = await ManageBalancesService.cancelCommisionFromBasketAction(
                basketAction.boughtCoupon._id,
                basketAction.customer._id,
                basketAction.website,
                basketAction.business,
            );
            if (data) {
                onCancel(true);
            }
        } catch (error) {
            toast.error('ERROR, check console')
            console.log(`onCancelCommisionFromBoughtCoupon Error`, error);
        }
    };

    const onCancelDealAndCommision = async () => {
        try {
            const data = await ManageBalancesService.cancelDealAndCommission(
                basketAction.deal,
                basketAction.boughtCoupon._id,
                basketAction.customer._id,
                basketAction.website,
                basketAction.business,
            );
            if (data) {
                onCancel(true);
            }
        } catch (error) {
            toast.error('ERROR, check console')
            console.log(`onCancelDealAndCommision Error`, error);
        }
    };

    const onClose = (): void => {
        onCancel(false);
    };

    const isBasketTypeCC =
        basket?.type === BasketType.BUY_NOW_BOOK_NOW_CC ||
        basket?.type === BasketType.BUY_NOW_BOOK_LATER_CC ||
        BasketType.BOOK_NOW_ONLINE_SALES_THIS_MONTH_CC ||
        BasketType.BOOK_LATER_ONLINE_SALES_THIS_MONTH_CC;

    const isBasketTypePayAtVenue =
        basket?.type === BasketType.BUY_NOW_BOOK_NOW_VENUE ||
        basket?.type === BasketType.BUY_NOW_OFFLINE_SALES_THIS_MONTH_VENUE;

    if (!isVisible || !basket || !basketAction) {
        return null;
    }

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="sm">
            <DialogContent>
                {/* <div>{props.text}</div> */}
                <div className="mt-2 flex justify-between">
                    <Button onClick={onCancelCommisionFromBoughtCoupon} variant="contained">
                        ביטול עמלת ZENAT משובר זה
                    </Button>
                    {isBasketTypeCC && (
                        <Button onClick={onCancelDealAndCommision} variant="contained">
                            זיכוי וביטול עסקה
                        </Button>
                    )}
                    {isBasketTypePayAtVenue && (
                        <Button onClick={onCancelDealAndCommision} variant="contained">
                            ביטול עסקה
                        </Button>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};
