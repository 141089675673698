import { useEffect } from "react";
import { environment } from "src/common/config";
import useApi from "src/common/hooks/use-api.hook";

export const useBaskets = (selectedMonthAndYear: string, websiteId: string, businessId: string, selectedBasket) => {

    const {
        response: getBasketsResponse,
        error: getBasketsError,
        loading: getBasketsLoading,
        execute: getBasketsExecute,
    } = useApi({
        url: `${environment.service_panel}/basket/${websiteId}/${businessId}?selectedMonthAndYear=${selectedMonthAndYear}`,
        method: 'GET',
    });

    useEffect(() => {
        if( businessId && websiteId && selectedMonthAndYear ) {
            getBasketsExecute();
        }
    }, [businessId, websiteId, selectedMonthAndYear, selectedBasket])

    return { baskets: getBasketsResponse?.data?.data?.baskets || [], getBasketsLoading, getBasketsExecute };

}