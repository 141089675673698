import { ProcessBookingsXlsx } from "./components/process-bookings-xlsx.component"

export const BookingsImportSection = () => {

    return (
        <>
            <h2 className="mt-2">העברת יומנים</h2>
            <div className="box">
                <h3>Copty Paste all worker PDF Data To new XLSX file in A Column then upload here </h3>
                <h3>Proccess XLSX bookings file</h3>
                <ProcessBookingsXlsx />
            </div>
        </>
    )
}