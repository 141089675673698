import './manage-hashavshevet.page.css';

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { Buffer } from 'buffer';
import iconv from 'iconv-lite';
import { compact, identity, pickBy } from 'lodash-es';
import { useState } from 'react';

function processMoveinPrmContent(moveinPrmContent) {
    const prmLines = moveinPrmContent.split('\n');
    const slicePositions = [];

    prmLines.forEach(line => {
        const [start, end] = line.trim().split(' ');
        if (parseInt(start) !== 0 || parseInt(end) !== 0) {
            slicePositions.push([parseInt(start), parseInt(end)]);
        }
    });

    return slicePositions;
}

function processMoveinFiles(moveinDocContent, moveinPrmContent) {
    const docRows = moveinDocContent.split('\n');
    const slicePositions = processMoveinPrmContent(moveinPrmContent);
    const processedRows = [];

    docRows.forEach(row => {
        const rowData = {};

        slicePositions.forEach(([start, end], index) => {
            rowData[`key${index + 1}`] = row.slice(start - 1, end).trim();
        });

        processedRows.push(pickBy(rowData, identity));
    });

    return processedRows;
}

const content = {
    Mas: {
        key2: 'מספר אסמכתא',
        key3: 'תאריך הפקה',
        key4: 'תאריך ערך',
        key5: 'פריטים',
        key6: 'לקוח',
        key8: 'קופה',
        key9: 'קופת מע״מ',
        key10: 'סכום כולל',
        key11: 'סכום',
        key12: 'מע״מ',
    },
    Other: {
        key2: 'מספר אסמכתא',
        key3: 'תאריך הפקה',
        key4: 'תאריך ערך',
        key5: 'שם לקוח',
        key6: 'קופה',
        key8: 'לקוח',
        key10: 'סכום',
        key11: 'סכום',
    },
    "10001": "קופת הכנסות",
    "40002": "קופת מזומן",
    "42002": "קופת אשראי",
    "40003": "קופת העברות + ביט",
    "40001": "קופת צ'קים"
}

export const ManageHashavshevet = () => {
    const [moveinDocContent, setMoveinDocContent] = useState('');
    const [moveinPrmContent, setMoveinPrmContent] = useState('');
    const [processedData, setProcessedData] = useState(null);
    const [filterValue, setFilterValue] = useState('10001');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split("/");
        return new Date(year, month - 1, day);
    };

    const parseDateWithHyphen = (dateString) => {
        const [year, month, day] = dateString.split("-");
        return new Date(year, month - 1, day);
    };

    const dateInRange = (date, start, end) => {
        const d = parseDate(date);
        const s = parseDateWithHyphen(start);
        const e = parseDateWithHyphen(end);
      
        const isValidDate = (date) => !isNaN(Date.parse(date));
        if( !isValidDate(start) || !isValidDate(end) ) {
            return true;
        }
        return isValidDate(start) && isValidDate(end) && (!start || d >= s) && (!end || d <= e);
    };

    const handleDocFileChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();
        if (!file) {
            return;
        }
        reader.onload = (e: any) => {
            const decodedContent = iconv.decode(Buffer.from(e.target.result), 'windows-1255');
            setMoveinDocContent(decodedContent);
        };

        reader.readAsArrayBuffer(file);
    };

    const handlePrmFileChange = event => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();

        reader.onload = (e: any) => {
            setMoveinPrmContent(e.target.result);
        };

        reader.readAsText(file);
    };

    const handleProcessFiles = () => {
        const processedData = processMoveinFiles(moveinDocContent, moveinPrmContent);
        setProcessedData(processedData);
    };

    const filteredRows = processedData?.filter(row => row.key6 === filterValue && dateInRange(row.key3, startDate, endDate));

    const key10 = filteredRows?.reduce((acc, row) => acc + parseFloat(row.key10), 0).toFixed(2);
    const key12 = key10 / 1.17 * 0.17;
    const footerTotals = {
        key10: filteredRows?.reduce((acc, row) => acc + parseFloat(row.key10), 0).toFixed(2),
        key11: filteredRows?.reduce((acc, row) => acc + parseFloat(row.key11), -1).toFixed(2),
        key12: filteredRows?.reduce((acc, row) => acc + parseFloat(row.key12), 1).toFixed(2),
    };

    return (
        <div className='manage-hashavshevet'>
            <h2>Movein.DOC:</h2>
            <input type="file" accept=".doc" onChange={handleDocFileChange} />
            <h2>Movein.PRM:</h2>
            <input type="file" accept=".prm" onChange={handlePrmFileChange} />
            <div>
                <button onClick={handleProcessFiles}>Process Files</button>
            </div>
            {!!processedData?.length && (
                <div className='hashavshavet-table'>
                    <div style={{ marginBottom: 10 }}>
                        <select value={filterValue} onChange={e => setFilterValue(e.target.value)}>
                            {compact(Array.from(new Set(processedData?.map(row => row.key6)))).map((value, index) => (
                                <option key={index} value={`${value}`}>{content[value]}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <TextField
                            label="Start Date"
                            type="date"
                            value={startDate}
                            onChange={e => setStartDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            label="End Date"
                            type="date"
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {filteredRows?.[0] && Object.keys(filteredRows?.[0]).map((key, index) => (
                                        <TableCell key={index} style={{ position: 'sticky', background: '#999', color: 'white', top: 0 }}>
                                            {(filterValue === '10001') ? content.Mas[key] : content.Other[key]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRows?.map((row, index) => (
                                    <TableRow key={index}>
                                        {Object.values(row).map((value, idx) => (
                                            <TableCell
                                                dir={Object.keys(row)[idx] === 'key5' ? "rtl" : "ltr"}
                                                key={idx}>{value}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    {filteredRows?.[0] && Object.keys(filteredRows?.[0]).map((key, index) => (
                                        <TableCell key={index} style={{ position: 'sticky', background: '#999', color: 'white', bottom: 0 }}>
                                            {(key === 'key10' && (filterValue === '10001' || filterValue !== '10001')) ? footerTotals.key10 : ''}
                                            {(key === 'key11' && (filterValue === '10001' || filterValue !== '10001')) ? footerTotals.key11 : ''}
                                            {(key === 'key12' && filterValue === '10001') ? footerTotals.key12 : ''}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </div>
    );
}
