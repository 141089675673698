import './manage-blocked-users.css';

import BlockSharpIcon from '@mui/icons-material/BlockSharp';
import EditIcon from '@mui/icons-material/Edit';
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { getCustomerOrWorkerName, humanizePhoneNumber } from 'src/common/utils';

import { ManageUserDialog } from './dialogs/manage-user/manage-user.dialog';
import { ManageBlockedUsersService } from './manage-blocked-users.service';
import { AreYouSureDialog } from 'src/dialogs';
import { toast } from 'react-toastify';

const ManageBlockedUsersComp = () => {
    const manageBlockedUsersService = new ManageBlockedUsersService();

    // State for users data, search, and pagination
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [isUnblockingUserInitiated, setIsUnblockingUserInitiated] = useState<boolean>(false);
    const [isBlockingUserInitiated, setIsBlockingUserInitiated] = useState<boolean>(false);
    const [phoneNumberToBeBlocked, setPhoneNumberToBeBlocked] = useState<string>('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isManageUserDialog, setIsManageUserDialog] = useState<boolean>(false);

    const [currentUser, setCurrentUser] = useState(null);

    const initBlockedUsers = async () => {
        try {
            const blockedUsers = await manageBlockedUsersService.getBlockedUsers();
            setUsers(blockedUsers || []);
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch users data from your API or server
    useEffect(() => {
        initBlockedUsers();
    }, []);

    // Update page and rowsPerPage for pagination
    const handleChangePage = (event, newPage): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event): void => {
        setRowsPerPage(parseInt(event.target.value, 25));
        setPage(0);
    };

    // Filter and sort users based on search query
    const filteredUsers = useMemo(() => {
        return users
            .filter(user => {
                const name = user?.firstName?.toLowerCase() || '';
                const firstName = user?.details?.firstName?.toLowerCase() || '';
                const phoneNumber = user?.phoneNumber?.toLowerCase() || '';
                const searchLower = search.toLowerCase();

                return (
                    name.includes(searchLower) || firstName.includes(searchLower) || phoneNumber.includes(searchLower)
                );
            })
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }, [users, search]);

    const openManageUserDialog = user => (): void => {
        setCurrentUser(user);
        setIsManageUserDialog(true);
    };

    const onCloseManageUserDialog = (): void => {
        setCurrentUser(null);
        setIsManageUserDialog(false);
    };

    const blockThisNumber = async (confirmed: boolean) => {
        if( !confirmed ) {
            setIsBlockingUserInitiated(false);
            return;
        }
        try {
            if (phoneNumberToBeBlocked.length < 10) {
                toast.error('Please enter a valid phone number');
            } 
            let processedPhoneNumber = phoneNumberToBeBlocked;
            processedPhoneNumber = "+972" + processedPhoneNumber.substring(1, processedPhoneNumber.length);
            const blockedUser = await manageBlockedUsersService.blockUser(processedPhoneNumber);
            setUsers([...users, blockedUser]);
            
        } catch(error) {
            console.log(error);
        } finally {
            setIsBlockingUserInitiated(false);
        }
    };

    const onChange = (event): void => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) {
            // Validate if the value is numeric and does not exceed 10 characters
            setPhoneNumberToBeBlocked(value);
        }
    }

    const initaiteUnblockingUser = (user) => (): void => {
        setIsUnblockingUserInitiated(true);
        setCurrentUser(user);
    }

    const initaiteBlockingUser = (): void => {
        setIsBlockingUserInitiated(true);
    }

    const unblockUser = async (confirmed: boolean) => {
        if( !confirmed ) {
            setIsUnblockingUserInitiated(false);
            return;
        }
        try {
            await manageBlockedUsersService.unblockUser(currentUser.phoneNumber);
            setUsers(users.filter(user => user._id !== currentUser._id));
        } catch(error) {
            console.log(error);
        } finally {
            setIsUnblockingUserInitiated(false);
        }
    }

    return (
        <Paper>
            <AreYouSureDialog onClose={blockThisNumber} text={`Are you sure you want to block ${phoneNumberToBeBlocked}`} okText={'YES'} cancelText={'CANCEL'} isVisible={isBlockingUserInitiated} />
            <AreYouSureDialog onClose={unblockUser} text={`Are you sure you want to unblock ${getCustomerOrWorkerName(currentUser)}`} okText={'YES'} cancelText={'CANCEL'} isVisible={isUnblockingUserInitiated} />
            <h1>חסימת משתמשים</h1>
            <div className="table-header">
                <TextField
                    label="Search"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                <TextField
                    label="Enter phone number"
                    value={phoneNumberToBeBlocked}
                    type="tel"
                    onChange={onChange}
                    style={{ marginRight: '20px' }}
                    required
                    inputProps={{ minLength: 10, maxLength: 10, pattern: '[0-9]*' }}
                />
                <IconButton onClick={initaiteBlockingUser}>
                    <EditIcon />
                </IconButton>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>שם</TableCell>
                            <TableCell>טלפון</TableCell>
                            <TableCell>סוג</TableCell>
                            <TableCell>עריכה</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
                            <TableRow key={user._id}>
                                <TableCell>{getCustomerOrWorkerName(user)}</TableCell>
                                <TableCell>{humanizePhoneNumber(user.phoneNumber)}</TableCell>
                                <TableCell>hello</TableCell>
                                <TableCell>
                                    <IconButton onClick={openManageUserDialog(user)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={initaiteUnblockingUser(user)}>
                                        <BlockSharpIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        dir="rtl"
                    />
                </Grid>
            </Grid>

            {isManageUserDialog && <ManageUserDialog currentUser={currentUser} onClose={onCloseManageUserDialog} />}
        </Paper>
    );
};

export default ManageBlockedUsersComp;
